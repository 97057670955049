module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="mt-3">\n  <div class="row align-items-end">\n    <div class="col">\n      <h1 class="h2 my-0">取引台帳 Report</h1>\n    </div>\n    <div class="col-auto">\n      <div class="form-group row mb-0 justify-content-end">\n        <label class="col-auto col-form-label">Type</label>\n        <div class="col-auto">\n          <select class="form-control" name="type">\n            <option value="sales">Sales</option>\n            <option value="rent">Leasing</option>\n          </select>\n        </div>\n      </div>\n    </div>\n    <div class="col-auto">\n      <div class="form-group row mb-0 justify-content-end">\n        <label class="col-auto col-form-label">Financial Year</label>\n        <div class="col-auto">\n          <select class="form-control" name="fy">\n            ';
 for (const y of years) { ;
__p += '\n            <option value="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( y )) == null ? '' : __t) +
'</option>\n            ';
 } ;
__p += '\n          </select>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n\n<hr>\n\n<div id="results"></div>';

}
return __p
};
