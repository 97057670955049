import * as Formatter from '@/js/app/formatter';
import * as TextHelper from '@/js/app/text-helper';
import moment from 'moment';
import SimpleModalView from '../../generic/modal-simple';
import template from '../templates/modal_page_views.html';

export default class ModalPageViewsView extends SimpleModalView {
    preinitialize() {
        super.preinitialize({
            modalTitle: 'Property Page Views',
            size: 'xl',
        });

        this.events = {
            ...this.events,
            'click .modal-body a': this.handleLinkClick,
        };
    }

    initialize() {
        super.initialize();

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', this.startSpinner);

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', this.stopSpinner);

        // When collection syncs; render modal body
        this.listenTo(this.collection, 'sync', this.renderModalBody);
    }

    renderModalBody() {
        console.debug('ModalPageViewsView#render');

        this.modalBody.innerHTML = template({
            page_views: this.collection.toJSON(),
            Formatter,
            TextHelper,
            moment,
        });

        return this;
    }

    handleLinkClick() {
        // Hide modal when link clicked
        this.hide();
    }
}
