import { Collection } from 'backbone';
import config from '../../config';
import PageViewModel from '../models/page-view';

export default class PageViewsCollection extends Collection {
    preinitialize(options) {
        this.addressbook_id = options.addressbook_id;
        this.model = PageViewModel;
    }

    url() {
        return (
            config.api.url +
            'people/' +
            this.addressbook_id +
            '/property_page_views'
        );
    }

    parse(response) {
        this.total_rows = response.total_rows;

        return response.results;
    }
}
