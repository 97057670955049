import { Model } from 'backbone';
import config from '../../config';

export default class PageViewModel extends Model {
    preinitialize(options) {
        this.addressbook_id = options.addressbook_id;
    }

    urlRoot() {
        return (
            config.api.url +
            'people/' +
            this.addressbook_id +
            '/property_page_views'
        );
    }
}
