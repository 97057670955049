import * as Formatter from '@/js/app/formatter';
import * as TextHelper from '@/js/app/text-helper';
import { View } from 'backbone';
import template from '../templates/assignment-results-row.html';
import PeopleModel from '../../people/models/people';

class ClientAssignmentResultRowView extends View {
    preinitialize(options) {
        this.tagName = 'tr';
        this.className = 'assignment-row';

        this.events = {
            'click [data-unlink]': this.deallocate,
        };

        this.data = options.data;

        this.model = new PeopleModel({
            id: this.data.id,
        });
    }

    render() {
        console.debug('ClientAssignmentResultRowView#render');

        this.el.innerHTML = template({
            result: this.data,
            Formatter,
            TextHelper,
        });

        return this;
    }

    async deallocate() {
        if (window.confirm('Unassign this client?')) {
            const data = {
                [`status_${this.data.division_key}`]: 'unassigned',
            };

            await this.model.save(data, { patch: true, wait: true });

            this.trigger('unassign', this);

            this.remove();
        }
    }
}

export default ClientAssignmentResultRowView;
