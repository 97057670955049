import ModalView from '@/js/app/generic/modal';
import Spinner from '@/js/app/ui/views/spinner';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import template from '../templates/edit_subscriptions.html';

export default class ModalSubscriptionsView extends ModalView {
    preinitialize(options) {
        super.preinitialize();

        this.spinner = new Spinner();

        this.peopleModel = options.peopleModel;

        this.events = {
            ...this.events,
            'click button[data-action="cancel"]': this.handleCancelClick,
            'click button[data-action="save"]': this.handleSaveClick,
            'shown.bs.modal': this.setup,
        };
    }

    initialize() {
        super.initialize();

        // When collection starts request; start spinner
        this.listenTo(this.model, 'request', this.startSpinner);
        this.listenTo(this.model, 'request', this.disableFormElements);

        // When collection finishes request; stop spinner
        this.listenTo(this.model, 'sync error', this.stopSpinner);
        this.listenTo(this.model, 'sync error', this.enableFormElements);

        this.listenTo(this.model, 'change', this.handleModelChange);
        this.listenTo(
            this.peopleModel,
            'change:email',
            this.handlePeopleModelEmailChange,
        );
    }

    render() {
        console.debug('ModalSubscriptionsView#render');

        // Render parent view, then set modal content to template
        super.render({
            size: 'sm',
        }).modalContent.innerHTML = template();

        this.modalBody = this.el.querySelector('.modal-body');

        this.hydrateView();

        this.toggleUnsubscribedLists(this.model.toJSON());

        return this;
    }

    startSpinner() {
        console.debug('ModalSubscriptionsView#startSpinner');

        if (this.modalBody instanceof Element) {
            this.spinner.spin(this.modalBody);
        } else {
            console.warn(
                'Unable to start spinner as modal has not yet rendered',
            );
        }
    }

    stopSpinner() {
        console.debug('ModalSubscriptionsView#stopSpinner');

        this.spinner.stop();
    }

    disableFormElements() {
        this.el
            .querySelectorAll('input,select,textarea,button')
            .forEach((el) => (el.disabled = true));
    }

    enableFormElements() {
        this.el
            .querySelectorAll('input,select,textarea,button')
            .forEach((el) => (el.disabled = false));
    }

    setup() {
        console.debug('ModalSubscriptionsView#setup');

        this.#enablePromptBeforeUnload();
    }

    teardown() {
        console.debug('ModalSubscriptionsView#teardown');

        super.teardown();

        this.#disablePromptBeforeUnload();

        // Remove was-validated class from form
        const form = this.el.querySelector('form');
        form.classList.remove('was-validated');
    }

    hydrateView() {
        this.updateForm(this.model.toJSON());
    }

    #showFormValidationMessage() {
        this.el
            .querySelector('[data-outlet="form-invalid"]')
            .classList.remove('d-none');
    }

    #hideFormValidationMessage() {
        this.el
            .querySelector('[data-outlet="form-invalid"]')
            .classList.add('d-none');
    }

    #disablePromptBeforeUnload() {
        // Remove prompt before unload
        window.removeEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload--;
    }

    #enablePromptBeforeUnload() {
        // Add prompt before unload
        window.addEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload++;
    }

    /**
     * @param {Event} $e
     */
    promptBeforeUnload(e) {
        // Prevent default (modern browsers)
        e.preventDefault();

        // Set return value (legacy browsers)
        e.returnValue = true;
    }

    handleModelChange() {
        console.debug('ModalSubscriptionsView#handleModelChange');

        const changes = this.model.changedAttributes();

        console.debug(
            'ModalSubscriptionsView#handleModelChange: Changes',
            changes,
        );

        this.updateForm(changes);
    }

    updateForm(changes) {
        console.debug('ModalSubscriptionsView#updateForm');

        // Populate form fields
        populateForm(this.el, changes);
    }

    /**
     * Handler to run when "Cancel" button clicked
     * @param {JQuery.Event} $e
     */
    handleCancelClick($e) {
        console.debug('ModalSubscriptionsView#handleCancelClick');

        $e.stopPropagation();

        this.hydrateView();

        this.hide();
    }

    /**
     * Handler to run when "Save" button clicked
     * @param {JQuery.Event} $e
     */
    handleSaveClick($e) {
        console.debug('ModalSubscriptionsView#handleSaveClick');

        $e.stopPropagation();
        $e.preventDefault();

        this.save();
    }

    /**
     * @param {Element} fieldEl
     */
    #resetCustomValidation(fieldEl) {
        console.debug('ModalSubscriptionsView#resetValidation');

        // Reset invalid feedback slot
        const errorFeedback =
            fieldEl.parentElement.querySelector('.invalid-feedback');
        if (errorFeedback) {
            errorFeedback.innerText = '';
        }

        // Reset custom validity
        fieldEl.setCustomValidity('');
    }

    save() {
        const form = this.el.querySelector('form');

        // Add was-validated class to form
        form.classList.add('was-validated');

        // Get invalid fields
        const invalidFields = this.el.querySelectorAll('form :invalid');

        // Reset custom validation on all invalid fields (otherwise won't attempt to perform server side validation)
        invalidFields.forEach(this.#resetCustomValidation);

        this.#hideFormValidationMessage();

        if (form.checkValidity() === false) {
            this.#showFormValidationMessage();

            // Set field's tab to invalid
            invalidFields.forEach(this.setFieldTabInvalid);

            return;
        }

        const formData = formToObject(form);

        const payload = this.model.changedAttributes(formData);

        // If no changes; hide modal
        if (_.isEmpty(payload)) {
            this.hide();
        } else {
            // Save new value
            this.model
                .save(payload, { patch: true, wait: true })
                .then(() => {
                    this.hide();
                })
                .catch(({ responseJSON }) => {
                    // If type of error is App\Exceptions\Validation
                    if (responseJSON.type === 'App\\Exceptions\\Validation') {
                        for (const field of responseJSON.fields) {
                            const fieldEl = this.el.querySelector(
                                `[name="${field.name}"]`,
                            );

                            // Set validation error in invalid feedback slot
                            const errorFeedback =
                                fieldEl.parentElement.querySelector(
                                    '.invalid-feedback',
                                );
                            if (errorFeedback) {
                                errorFeedback.innerText = field.message;
                            }

                            // Set validation error on fields
                            fieldEl.setCustomValidity(field.message);

                            this.setFieldTabInvalid(fieldEl);
                        }

                        this.#showFormValidationMessage();
                    }
                });
        }
    }

    /**
     * Handle people model email address change
     * @param {Backbone.Model} model People model
     * @param {*} value
     * @param {object} options
     */
    handlePeopleModelEmailChange(model, value, options) {
        console.debug('ModalSubscriptionsView#handlePeopleModelEmailChange');

        if (!options.initialLoad) {
            // Trigger fetch of model
            this.model.fetch();
        }
    }

    /**
     * @param {object} edms
     */
    toggleUnsubscribedLists(edms) {
        console.debug('ModalSubscriptionsView#toggleUnsubscribedLists');

        // Loop over lists
        for (const [list, status] of Object.entries(edms)) {
            // If list status is -1, user explicitly unsubscribed (we cannot resubscribe them)
            if (status === -1) {
                const listSection = this.el.querySelector(
                    `[data-part="${list}"]`,
                );
                if (listSection) {
                    listSection.querySelector(`[name="${list}"]`).disabled =
                        true;
                    listSection.querySelector(
                        '[data-part="unsubscribed"]',
                    ).style.display = 'inline';
                }
            }
        }
    }
}
