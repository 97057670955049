import BloodhoundCompanyDataset from '@/js/app/bloodhound/datasources/company';
import ChangelogsCollection from '@/js/app/changelog/collections/changelogs';
import ModalChangelogsView from '@/js/app/changelog/views/modal-changelogs';
import * as Formatter from '@/js/app/formatter';
import { getCompanyLink } from '@/js/app/formatter';
// import Session from '@/js/app/session';
import StaffCollection from '@/js/app/staff/collections/staff';
import * as TextHelper from '@/js/app/text-helper';
import typeaheadCompanyDatasource from '@/js/app/typeahead/datasets/company';
import { TypeaheadCompanyDefaults } from '@/js/app/typeahead/defaults';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import { View } from 'backbone';
import moment from 'moment';
import template from '../templates/edit.html';
import PeopleAutomatchPropertiesModalView from './modal-automatch-properties';

export default class PeopleEditView extends View {
    #mode = 'read';

    preinitialize(options) {
        this.tagName = 'section';
        this.className = 'card';

        this.events = {
            'click button[data-action="changelog"]': this.handleChangelogClick,
            'change [name="email"]': this.handleEmailChange,
            'change [name="telephone"]': this.handleTelephoneChange,
            'change [name="telephone_other"]': this.handleOtherTelChange,
            'change [name="wechat_id"]': this.handleWeChatChange,
            'change [name="whatsapp"]': this.handleWhatsappChange,
            'change input,select,textarea': this.handleFormChange,
            'click button[data-action="automatch"]': this.handleAutomatchClick,
            'click button[data-action="edit"]': this.handleEditClick,
            'click button[data-action="cancel"]': this.handleCancelClick,
            'click button[data-action="save"]': this.handleSaveClick,
        };

        // Create subviews with collections
        this.subviews = {
            changelogsModal: new ModalChangelogsView({
                collection: new ChangelogsCollection(),
            }),
            company: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                urlGenerator: getCompanyLink,
            }),
            salesStaff: new UiIndividualSelectorView({
                id: 'field-staff_sales',
                collection: new StaffCollection(),
                attributes: {
                    name: 'staff_id_sales',
                },
            }),
            investmentSalesStaff: new UiIndividualSelectorView({
                id: 'field-staff_investment_sales',
                collection: new StaffCollection(),
                attributes: {
                    name: 'staff_id_investment_sales',
                },
            }),
            leasingStaff: new UiIndividualSelectorView({
                id: 'field-staff_leasing',
                collection: new StaffCollection(),
                attributes: {
                    name: 'staff_id_leasing',
                },
            }),
            pmStaff: new UiIndividualSelectorView({
                id: 'field-staff_pm',
                collection: new StaffCollection(),
                attributes: {
                    name: 'staff_id_pm',
                },
            }),
            leasingAutomatchModal: new PeopleAutomatchPropertiesModalView({
                model: options.model,
                type: 'leasing',
            }),
            salesAutomatchModal: new PeopleAutomatchPropertiesModalView({
                model: options.model,
                type: 'sales',
            }),
        };
    }

    initialize() {
        this.listenTo(this.model, 'change', this.handleModelChange);

        // Listen to object changes in model
        this.listenTo(this.model, 'change:company', this.handleCompanyChange);

        this.listenTo(
            this.model,
            'change:staff_sales',
            this.handleStaffSalesChange,
        );
        this.listenTo(this.model, 'change:staff_id_sales', (model, value) =>
            this.#updateStaffIdSales(value),
        );
        this.listenTo(
            this.model,
            'change:assigned_date_sales',
            this.handleAssignedDateSalesChange,
        );
        this.listenTo(
            this.model,
            'change:staff_investment_sales',
            this.handleStaffInvestmentSalesChange,
        );
        this.listenTo(
            this.model,
            'change:staff_id_investment_sales',
            (model, value) => this.#updateStaffIdInvestmentSales(value),
        );
        this.listenTo(
            this.model,
            'change:assigned_date_investment_sales',
            this.handleAssignedDateInvestmentSalesChange,
        );
        this.listenTo(
            this.model,
            'change:staff_leasing',
            this.handleStaffLeasingChange,
        );
        this.listenTo(this.model, 'change:staff_id_leasing', (model, value) =>
            this.#updateStaffIdLeasing(value),
        );
        this.listenTo(
            this.model,
            'change:assigned_date_leasing',
            this.handleAssignedDateLeasingChange,
        );
        this.listenTo(this.model, 'change:staff_pm', this.handleStaffPmChange);
        this.listenTo(this.model, 'change:staff_id_pm', (model, value) =>
            this.#updateStaffIdPm(value),
        );
        this.listenTo(
            this.model,
            'change:assigned_date_pm',
            this.handleAssignedDatePmChange,
        );
        this.listenTo(
            this.model,
            'change:is_tenant',
            this.handleIsTenantChange,
        );
        this.listenTo(this.model, 'change:is_buyer', this.handleIsBuyerChange);
        this.listenTo(
            this.model,
            'change:updated_at',
            this.handleUpdatedAtChange,
        );

        // Fetch staff
        this.subviews.salesStaff.collection.fetch({
            data: {
                division_id: 2,
                is_virtual: 0,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });
        this.subviews.investmentSalesStaff.collection.fetch({
            data: {
                division_id: 11,
                is_virtual: 0,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });
        this.subviews.leasingStaff.collection.fetch({
            data: {
                division_id: 1,
                is_virtual: 0,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });
        this.subviews.pmStaff.collection.fetch({
            data: {
                division_id: 3,
                is_virtual: 0,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });
    }

    render() {
        console.debug('PeopleEditView#render');

        this.el.innerHTML = template({
            nationality: TextHelper._source.country,
            country: TextHelper._source.country,
        });

        // Attach and render subviews
        this.$el.find('#cntr-company').html(this.subviews.company.render().el);
        this.$el
            .find('#cntr-staff_sales')
            .html(this.subviews.salesStaff.render().el);
        this.$el
            .find('#cntr-staff_investment_sales')
            .html(this.subviews.investmentSalesStaff.render().el);
        this.$el
            .find('#cntr-staff_leasing')
            .html(this.subviews.leasingStaff.render().el);
        this.$el.find('#cntr-staff_pm').html(this.subviews.pmStaff.render().el);

        this.#hydrateView();

        this.#initializeReadEditMode();

        return this;
    }

    #hydrateView() {
        this.#updateForm(this.model.toJSON());
        this.#updateCompany(this.model.get('company'));
        this.#updateAssignedDate(
            'sales',
            this.model.get('assigned_date_sales'),
        );
        this.#updateAssignedDate(
            'investmentSales',
            this.model.get('assigned_date_investment_sales'),
        );
        this.#updateAssignedDate(
            'leasing',
            this.model.get('assigned_date_leasing'),
        );
        this.#updateAssignedDate('pm', this.model.get('assigned_date_pm'));
        this.#updateLastUpdatedAt(this.model.get('updated_at'));
    }

    #initializeReadEditMode() {
        if (this.#mode === 'read') {
            this.#showEditButton();

            // Disable fields
            this.el
                .querySelectorAll('input,select,textarea')
                .forEach((el) => (el.disabled = true));
        } else if (this.#mode === 'edit') {
            this.#showSaveCancelButtons();

            // Enable fields
            this.el
                .querySelectorAll('input,select,textarea')
                .forEach((el) => (el.disabled = false));

            // Toggle available options for each assigned staff field
            // this.#toggleAssignedStaffOptions(
            //     this.el.querySelector('#field-staff_sales'),
            // );
            // this.#toggleAssignedStaffOptions(
            //     this.el.querySelector('#field-staff_investment_sales'),
            // );
            // this.#toggleAssignedStaffOptions(
            //     this.el.querySelector('#field-staff_leasing'),
            // );
            // this.#toggleAssignedStaffOptions(
            //     this.el.querySelector('#field-staff_pm'),
            // );
        }
    }

    #showSaveCancelButtons() {
        // Hide edit button
        this.el.querySelector('[data-action="edit"]').classList.add('d-none');

        // Show cancel and save buttons
        this.el
            .querySelectorAll('[data-action="cancel"],[data-action="save"]')
            .forEach((el) => el.classList.remove('d-none'));
    }

    #showEditButton() {
        // Show edit button
        this.el
            .querySelector('[data-action="edit"]')
            .classList.remove('d-none');

        // Hide cancel and save buttons
        this.el
            .querySelectorAll('[data-action="cancel"],[data-action="save"]')
            .forEach((el) => el.classList.add('d-none'));
    }

    #showFormValidationMessage() {
        this.el
            .querySelector('[data-outlet="form-invalid"]')
            .classList.remove('d-none');
    }

    #hideFormValidationMessage() {
        this.el
            .querySelector('[data-outlet="form-invalid"]')
            .classList.add('d-none');
    }

    #disablePromptBeforeUnload() {
        // Remove prompt before unload
        window.removeEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload--;
    }

    #enablePromptBeforeUnload() {
        // Add prompt before unload
        window.addEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload++;
    }

    /**
     * @param {Event} $e
     */
    promptBeforeUnload(e) {
        // Prevent default (modern browsers)
        e.preventDefault();

        // Set return value (legacy browsers)
        e.returnValue = true;
    }

    switchEditMode() {
        console.debug('PeopleEditView#switchEditMode');

        this.#mode = 'edit';

        this.#initializeReadEditMode();

        // Switch subviews into edit mode
        this.subviews.company.switchEditMode();

        this.#enablePromptBeforeUnload();
    }

    switchReadMode() {
        console.debug('PeopleEditView#switchReadMode');

        this.#mode = 'read';

        this.#initializeReadEditMode();

        // Switch subviews into read mode
        this.subviews.company.switchReadMode();

        this.#disablePromptBeforeUnload();

        // Remove was-validated class from form
        const form = this.el.querySelector('form');
        form.classList.remove('was-validated');
    }

    /**
     * Handle model change
     * @param {Backbone.Model} model
     */
    handleModelChange(model) {
        console.debug('PeopleEditView#handleModelChange');

        const changes = model.changedAttributes();

        console.debug('PeopleEditView#handleModelChange - Changes', changes);

        this.#updateForm(changes);
    }

    #updateForm(changes) {
        console.debug('PeopleEditView#updateForm');

        // Populate form fields
        populateForm(this.el, changes, true);
    }

    /**
     * Handler to run when "Edit" button clicked
     * @param {JQuery.Event} $e
     */
    handleEditClick($e) {
        console.debug('PeopleEditView#handleEditClick');

        $e.stopPropagation();

        this.switchEditMode();
    }

    /**
     * Handler to run when "Cancel" button clicked
     * @param {JQuery.Event} $e
     */
    handleCancelClick($e) {
        console.debug('PeopleEditView#handleCancelClick');

        $e.stopPropagation();

        this.#hideFormValidationMessage();

        this.#hydrateView();

        this.switchReadMode();
    }

    /**
     * Handler to run when "Save" button clicked
     * @param {JQuery.Event} $e
     */
    handleSaveClick($e) {
        console.debug('PeopleEditView#handleSaveClick');

        $e.stopPropagation();
        $e.preventDefault();

        this.save();
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleFormChange($e) {
        console.debug('PeopleEditView#handleFormChange');

        this.#resetCustomValidation($e.currentTarget);
    }

    /**
     * Handler to run when email address changes in model
     * @param {JQuery.Event} $e
     */
    handleEmailChange($e) {
        console.debug('PeopleEditView#handleEmailChange');

        this.#updateEmailLink($e.currentTarget.value);
    }

    #updateEmailLink(value) {
        console.debug('PeopleEditView#updateEmailLink');

        const el = this.el.querySelector('#link-email');
        el.href = 'mailto:' + value;
        if (_.isEmpty(value)) {
            el.classList.add('d-none');
        } else {
            el.classList.remove('d-none');
        }
    }

    /**
     * Handler to run when telephone field changes
     * @param {JQuery.Event} $e
     */
    handleTelephoneChange($e) {
        console.debug('PeopleEditView#handleTelephoneChange');

        this.#updateTelephoneLink($e.currentTarget.value);
    }

    #updateTelephoneLink(value) {
        console.debug('PeopleEditView#updateTelephoneLink');

        const el = this.el.querySelector('#link-tel');
        el.href = 'tel:' + value;
        if (_.isEmpty(value)) {
            el.classList.add('d-none');
        } else {
            el.classList.remove('d-none');
        }
    }

    /**
     * Handler to run when other telephone field changes
     * @param {JQuery.Event} $e
     */
    handleOtherTelChange($e) {
        console.debug('PeopleEditView#handleOtherTelChange');

        this.#updateOtherTelLink($e.currentTarget.value);
    }

    #updateOtherTelLink(value) {
        console.debug('PeopleEditView#updateOtherTelLink');

        const el = this.el.querySelector('#link-other_tel');
        el.href = 'tel:' + value;
        if (_.isEmpty(value)) {
            el.classList.add('d-none');
        } else {
            el.classList.remove('d-none');
        }
    }

    /**
     * Handler to run when WeChat ID field changes
     * @param {JQuery.Event} $e
     */
    handleWeChatChange($e) {
        console.debug('PeopleEditView#handleWeChatChange');

        this.#updateWeChatLink($e.currentTarget.value);
    }

    #updateWeChatLink(value) {
        console.debug('PeopleEditView#updateWeChatLink');

        const el = this.el.querySelector('#link-wechat');
        el.href = 'weixin://contacts/profile/' + value;
        if (_.isEmpty(value)) {
            el.classList.add('d-none');
        } else {
            el.classList.remove('d-none');
        }
    }

    /**
     * Handler to run when WhatsApp ID field changes
     * @param {JQuery.Event} $e
     */
    handleWhatsappChange($e) {
        console.debug('PeopleEditView#handleWhatsappChange');

        this.#updateWhatsappLink($e.currentTarget.value);
    }

    #updateWhatsappLink(value) {
        console.debug('PeopleEditView#updateWhatsappLink');

        const el = this.el.querySelector('#link-whatsapp');
        el.href = 'https://wa.me/' + value;
        if (_.isEmpty(value)) {
            el.classList.add('d-none');
        } else {
            el.classList.remove('d-none');
        }
    }

    /**
     * Handler to run when company object changes in model
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleCompanyChange(model, value) {
        console.debug('PeopleEditView#handleUpdateCompany');

        this.#updateCompany(value);
    }

    #updateCompany(value) {
        console.debug('PeopleEditView#updateCompany');

        this.subviews.company.setDatum(value);
    }

    /**
     * Handler to run when staff object (sales) changes in model
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleStaffSalesChange(model, value) {
        // Add to collection in case staff isn't returned from fetch (inactive or deleted)
        this.subviews.salesStaff.collection.add(value);
    }

    #updateStaffIdSales(value) {
        this.subviews.salesStaff.setSelected(value);
    }

    /**
     * Handler to run when assigned date (sales) changes in model
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleAssignedDateSalesChange(model, value) {
        console.debug('PeopleEditView#handleAssignedDateSalesChange');

        this.#updateAssignedDate('sales', value);
    }

    /**
     * Handler to run when staff object (investment sales) changes in model
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleStaffInvestmentSalesChange(model, value) {
        // Add to collection in case staff isn't returned from fetch (inactive or deleted)
        this.subviews.investmentSalesStaff.collection.add(value);
    }

    #updateStaffIdInvestmentSales(value) {
        this.subviews.investmentSalesStaff.setSelected(value);
    }

    /**
     * Handler to run when assigned date (investment sales) changes in model
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleAssignedDateInvestmentSalesChange(model, value) {
        console.debug('PeopleEditView#handleAssignedDateInvestmentSalesChange');

        this.#updateAssignedDate('investmentSales', value);
    }

    /**
     * Handler to run when staff object (leasing) changes in model
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleStaffLeasingChange(model, value) {
        // Add to collection in case staff isn't returned from fetch (inactive or deleted)
        this.subviews.leasingStaff.collection.add(value);
    }

    #updateStaffIdLeasing(value) {
        this.subviews.leasingStaff.setSelected(value);
    }

    /**
     * Handler to run when assigned date (leasing) changes in model
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleAssignedDateLeasingChange(model, value) {
        console.debug('PeopleEditView#handleAssignedDateLeasingChange');

        this.#updateAssignedDate('leasing', value);
    }

    /**
     * Handler to run when staff object (PM) changes in model
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleStaffPmChange(model, value) {
        // Add to collection in case staff isn't returned from fetch (inactive or deleted)
        this.subviews.pmStaff.collection.add(value);
    }

    #updateStaffIdPm(value) {
        this.subviews.pmStaff.setSelected(value);
    }

    /**
     * Handler to run when assigned date (PM) changes in model
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleAssignedDatePmChange(model, value) {
        console.debug('PeopleEditView#handleAssignedDateLeasingChange');

        this.#updateAssignedDate('pm', value);
    }

    /**
     * Toggle assigned staff element options
     * @param {Element} staffEl
     * @param {*} value
     */
    /*
    #toggleAssignedStaffOptions(staffEl) {
        console.debug('PeopleEditView#updateAssignedStaffElement');

        if (!Session.isAllowed('phnx:people.assigned:u')) {
            // Disable all options
            staffEl
                .querySelectorAll('option')
                .forEach((el) => (el.disabled = true));

            // If logged in staff currently selected
            if (staffEl.value === Session.data.staff_id) {
                // Enable blank option (to unassign)
                staffEl.querySelector('option[value=""]').disabled = false;

                // Enable logged in staff
                staffEl.querySelector(
                    `option[value="${Session.data.staff_id}"]`,
                ).disabled = false;
            }
        }
    }
    */

    #updateAssignedDate(division, value) {
        console.debug('PeopleEditView#updateAssignedDate');

        const el = this.el.querySelector(`#${division}StaffAssignedDate`);
        if (el) {
            el.innerText = value
                ? moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss')
                : '';
        }
    }

    handleIsTenantChange(model, value) {
        console.debug('PeopleEditView#handleIsTenantChange');

        this.#toggleLeasingCriteria(value);
    }

    #toggleLeasingCriteria(value) {
        console.debug('PeopleEditView#toggleLeasingCriteria');

        const el = this.el.querySelector('#leasingCard');

        if (el) {
            // Show/hide leasing card
            if (value) {
                el.classList.remove('d-none');
            } else {
                el.classList.add('d-none');
            }
        }
    }

    handleIsBuyerChange(model, value) {
        console.debug('PeopleEditView#handleIsBuyerChange');

        this.#toggleSalesCriteria(value);
    }

    #toggleSalesCriteria(value) {
        console.debug('PeopleEditView#toggleSalesCriteria');

        const el = this.el.querySelector('#salesCard');

        if (el) {
            // Show/hide sales card
            if (value) {
                el.classList.remove('d-none');
            } else {
                el.classList.add('d-none');
            }
        }
    }

    handleUpdatedAtChange(model, value) {
        console.debug('PeopleEditView#handleUpdatedAtChange');

        this.#updateLastUpdatedAt(value);
    }

    #updateLastUpdatedAt(value) {
        console.debug('PeopleEditView#updateLastUpdatedAt');

        // Update timestamp
        const el = this.el.querySelector('[data-outlet="updated_at"]');
        if (el) {
            el.innerText = Formatter.timestamp(value);
        }
    }

    handleAutomatchClick(e) {
        console.debug('PeopleEditView#handleAutomatchClick');

        e.stopPropagation();

        const buttonDatasetCriteria = e.currentTarget.dataset.criteria;
        if (buttonDatasetCriteria === 'leasing') {
            this.subviews.leasingAutomatchModal.render().renderModalBody();
        } else if (buttonDatasetCriteria === 'sales') {
            this.subviews.salesAutomatchModal.render().renderModalBody();
        }
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleChangelogClick($e) {
        console.debug('PeopleEditView#handleChangelogClick');

        $e.stopPropagation();

        // Show modal
        this.subviews.changelogsModal.show();

        // Fetch collection
        this.subviews.changelogsModal.collection.fetch({
            data: {
                resource_type: 'person',
                resource_id: this.model.get('id'),
            },
        });
    }

    /**
     * @param {Element} fieldEl
     */
    #resetCustomValidation(fieldEl) {
        console.debug('PeopleEditView#resetValidation');

        // Reset invalid feedback slot
        const errorFeedback =
            fieldEl.parentElement.querySelector('.invalid-feedback');
        if (errorFeedback) {
            errorFeedback.innerText = '';
        }

        // Reset custom validity
        fieldEl.setCustomValidity('');
    }

    save() {
        const form = this.el.querySelector('form');

        // Add was-validated class to form
        form.classList.add('was-validated');

        // Get invalid fields
        const invalidFields = this.el.querySelectorAll('form :invalid');

        // Reset custom validation on all invalid fields (otherwise won't attempt to perform server side validation)
        invalidFields.forEach(this.#resetCustomValidation);

        this.#hideFormValidationMessage();

        if (form.checkValidity() === false) {
            this.#showFormValidationMessage();

            // Set field's tab to invalid
            invalidFields.forEach(this.setFieldTabInvalid);

            return;
        }

        const formData = formToObject(form);

        // Pickup values of typeaheads
        formData.company_id = this.subviews.company.datum
            ? this.subviews.company.datum.id
            : null;

        const payload = this.model.changedAttributes(formData);

        // If no changes; immediately switch to read mode
        if (_.isEmpty(payload)) {
            this.switchReadMode();
        } else {
            // Save new value
            this.model
                .save(payload, { patch: true, wait: true })
                .then(() => {
                    this.switchReadMode();
                })
                .catch(({ responseJSON }) => {
                    // If type of error is App\Exceptions\Validation
                    if (responseJSON.type === 'App\\Exceptions\\Validation') {
                        for (const field of responseJSON.fields) {
                            const fieldEl = this.el.querySelector(
                                `[name="${field.name}"]`,
                            );

                            // Set validation error in invalid feedback slot
                            const errorFeedback =
                                fieldEl.parentElement.querySelector(
                                    '.invalid-feedback',
                                );
                            if (errorFeedback) {
                                errorFeedback.innerText = field.message;
                            }

                            // Set validation error on fields
                            fieldEl.setCustomValidity(field.message);

                            this.setFieldTabInvalid(fieldEl);
                        }

                        this.#showFormValidationMessage();
                    }
                });
        }
    }
}
