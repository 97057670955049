import * as Formatter from '@/js/app/formatter';
import SimpleModalView from '@/js/app/generic/modal-simple';
import moment from 'moment';
import template from '../templates/modal_activity.html';

export default class ModalActivityView extends SimpleModalView {
    preinitialize() {
        super.preinitialize({
            modalTitle: 'Activity',
            size: 'lg',
        });
    }

    initialize() {
        super.initialize();

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', this.startSpinner);

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', this.stopSpinner);

        // When collection syncs; render modal body
        this.listenTo(this.collection, 'sync', this.renderModalBody);
    }

    renderModalBody() {
        console.debug('ModalActivityView#renderModalBody');

        const activities = this.processActivities(this.collection.toJSON());

        this.modalBody.innerHTML = template({
            activities,
        });
    }

    /** @todo Move into reusable function (same as dashboard/views/clients-activity.js) */
    processActivities(activities) {
        return activities.map((activity) => {
            // Convert UTC timestamp to local time
            activity.log.timestamp = moment.utc(activity.log.timestamp).local();

            // Set activity icon and message
            switch (activity.log.type) {
                case 'created':
                    activity.icon = `<i class="fa fa-lg fa-user-plus" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageCreated(activity);
                    break;
                case 'login':
                    activity.icon = `<i class="fa fa-lg fa-sign-in" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageLogin(activity);
                    break;
                case 'inquiry':
                    activity.icon = `<i class="fa fa-lg fa-send-o" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageInquiry(activity);
                    break;
                case 'property_alert_create':
                    activity.icon = `<i class="fa fa-lg fa-plus-square-o" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessagePropertyAlertCreate(
                            activity,
                        );
                    break;
                case 'property_alert_update':
                    activity.icon = `<i class="fa fa-lg fa-refresh" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessagePropertyAlertUpdate(
                            activity,
                        );
                    break;
                case 'property_alert_delete':
                    activity.icon = `<i class="fa fa-lg fa-minus-square-o" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessagePropertyAlertDelete(
                            activity,
                        );
                    break;
                case 'property_alert_open':
                    activity.icon = `<i class="fa fa-lg fa-envelope-open-o" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessagePropertyAlertOpen(activity);
                    break;
                case 'property_alert_click':
                    activity.icon = `<i class="fa fa-lg fa-hand-pointer-o" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessagePropertyAlertClick(
                            activity,
                        );
                    break;
                case 'client_assigned':
                    activity.icon = `<i class="fa fa-lg fa-info-circle" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageClientAssigned(activity);
                    break;
                case 'client_unassigned':
                    activity.icon = `<i class="fa fa-lg fa-info-circle" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageClientUnassigned(activity);
                    break;
                case 'client_relinked':
                    activity.icon = `<i class="fa fa-lg fa-link" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageClientRelinked(activity);
                    break;
                default:
                    activity.icon = `<i class="fa fa-lg fa-info-circle" aria-hidden="true"></i>`;
                    activity.message = activity.type;
            }

            return activity;
        });
    }
}
