import { View } from 'backbone';
import template from '../templates/page-detail.html';
import InquiryDetailView from './detail';

/**
 * Mark: This looks like it serves no purpose, but I want to pattern out an
 * example where we have page views and functional views, like we are doing
 * with Vue and other reactive UI frameworks.
 */

export default class InquiryDetailPageView extends View {
    preinitialize(options) {
        this.className = 'container mt-3';

        // Create subviews
        this.subviews = {
            detail: new InquiryDetailView({
                inquiryId: options.inquiryId,
            }),
        };
    }

    initialize() {
        this.render();

        this.subviews.detail.fetchInquiry();
    }

    render() {
        console.debug('InquiryDetailPageView#render');

        this.el.innerHTML = template();

        this.subviews.detail.setElement(this.$el.find('[data-slot="detail"]'));

        return this;
    }
}
