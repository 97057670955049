import { View } from 'backbone';
import Preferences from '@/js/app/preferences';
import Session from '@/js/app/session';
import template from '../templates/index.html';
import populateForm from '@/js/libs/populate-form';

export default class PreferenceEditView extends View {
    preinitialize() {
        this.className = 'container';

        this.events = {
            'change input,select': this.updatePreferences,
        };
    }

    initialize() {
        this.render();
    }

    render() {
        console.debug('PreferenceView#render');

        this.el.innerHTML = template({
            Session,
        });

        populateForm(this.el, Preferences);

        return this;
    }

    updatePreferences($e) {
        console.debug('PreferenceView#updatePreferences');

        const currentTarget = $e.currentTarget;
        const fieldName = currentTarget.name;
        let value =
            currentTarget.type === 'checkbox'
                ? currentTarget.checked
                    ? 1
                    : 0
                : currentTarget.value;
        value = isNaN(Number(value)) ? value : Number(value);

        Preferences[fieldName] = value;
        Preferences.save();
    }
}
