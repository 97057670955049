import * as Formatter from '@/js/app/formatter';
import propertyGetTitleService from '@/js/app/property/services/get-title';
import * as TextHelper from '@/js/app/text-helper';
import { View } from 'backbone';
import moment from 'moment';
import template from '../templates/edit_inquiries.html';

export default class PeopleEditInquiriesView extends View {
    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('PeopleEditInquiriesView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            inquiries: this.collection.toJSON(),
            propertyGetTitleService,
            Formatter,
            TextHelper,
            moment,
        });

        return this;
    }
}
