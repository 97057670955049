import { View } from 'backbone';
import UiDynamicSelectView from '@/js/app/ui/views/dynamic-select';
import template from '../templates/list_selector.html';
import PropertyListNewView from './new';

export default class PropertyListSelectorView extends View {
    preinitialize(options) {
        this.events = {
            'click button': this.addList,
        };

        this.addressbook_id = options.addressbook_id || null;
        this.visibility = options.visibility || null;

        // Create dynamic select subview
        this.subviews = {
            select: new UiDynamicSelectView(
                _.extend(options, {
                    mapper: function (model) {
                        return {
                            label: model.get('name'),
                            value: model.id,
                        };
                    },
                }),
            ),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'render');

        // When collection updates, select new list
        this.listenTo(this.collection, 'add', this.selectNewList);
    }

    render() {
        console.debug('PropertyListSelectorView#render');

        this.el.innerHTML = template();

        this.$el.find('select').replaceWith(this.subviews.select.render().el);

        return this;
    }

    addList() {
        console.debug('PropertyListSelectorView#addList');

        // Create new view, with new list model
        new PropertyListNewView({
            model: new this.collection.model({
                addressbook_id: this.addressbook_id,
                visibility: this.visibility,
            }),
            collection: this.collection,
        }).render();
    }

    selectNewList(model) {
        console.debug('PropertyListSelectorView#selectNewList');

        // Set selected value to newly added list
        this.subviews.select.selected = model.id;
    }
}
