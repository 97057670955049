import { View } from 'backbone';
import template from '../templates/summary.html';

export default class ReportAccountingSummaryView extends View {
    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('ReportAccountingSummaryView#render');

        const summary = {
            general_total: 0,
            bf_total: 0,
            sf_total: 0,
        };

        // Summarize results
        this.collection.toJSON().forEach((result) => {
            switch (result.fee_type) {
                case 'general':
                    summary.general_total += result.amount;
                    break;
                case 'seller':
                    summary.sf_total += result.amount;
                    break;
                case 'buyer':
                    summary.bf_total += result.amount;
                    break;
            }
        });

        this.el.innerHTML = template(summary);

        return this;
    }
}
