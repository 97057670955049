import { View } from 'backbone';
import template from '../templates/typeahead.html';

/**
 * @typedef {Object} Dataset
 * @property {Bloodhound} source
 * @property {boolean} async
 * @property {string} name
 * @property {number} limit
 * @property {Function} displayKey
 * @property {Object} templates
 * @property {string|Function} templates.notFound
 * @property {string|Function} templates.pending
 * @property {string|Function} templates.header
 * @property {string|Function} templates.footer
 * @property {string|Function} templates.suggestion
 */

/**
 * @typedef {Object} TypeaheadConstructorOptions
 * @property {Datum} datum
 * @property {string} placeholder
 * @property {boolean} required
 * @property {string} value
 * @property {object} options
 * @property {Dataset[]} datasets
 * @property {Function} urlGenerator
 * @property {boolean} showDelete
 * @property {'read'|'edit'} mode
 */

export default class Typeahead extends View {
    /**
     * @param {TypeaheadConstructorOptions} options
     */
    preinitialize(options) {
        this.events = {
            'typeahead:select .typeahead': this.handleSelect,
            'click [data-action="delete"]': this.handleClearClick,
        };

        this.datum = options.datum;

        this._placeholder = options.placeholder;

        this._required = options.required;

        this._value = options.value;

        this._options = options.options;

        if (options.datasets === undefined) {
            throw new Error('Required parameter "datasets" was undefined');
        } else if (
            Array.isArray(options.datasets) &&
            options.datasets.length === 0
        ) {
            throw new Error('At least one dataset must be provided');
        }
        this._datasets = options.datasets;

        this._urlGenerator = options.urlGenerator;

        this._showDelete =
            options.showDelete === undefined ? true : options.showDelete;

        this._mode = options.mode ? options.mode : 'read';
    }

    initialize() {
        _.bindAll(this, 'render');
    }

    render() {
        console.debug('UiTypeaheadView#render');

        this.el.innerHTML = template({
            placeholder: this._placeholder,
            required: this._required,
        });

        // Setup typeahead field
        this.$typeaheadEl = this.$el.find('.typeahead');
        this.$typeaheadEl.typeahead(this._options, this._datasets);

        // Set value
        this.setValue(this._value);

        this.#initializeReadEditMode();

        return this;
    }

    switchReadMode() {
        this._mode = 'read';

        this.#initializeReadEditMode();
    }

    switchEditMode() {
        this._mode = 'edit';

        this.#initializeReadEditMode();
    }

    #initializeReadEditMode() {
        if (this._mode === 'read') {
            this.#disableInput();

            if (this._showDelete) {
                this.#hideDeleteLink();
            }
        } else if (this._mode === 'edit') {
            const currentValue = this.$typeaheadEl.val();
            if (currentValue) {
                this.#disableInput();

                if (this._showDelete) {
                    this.#showDeleteLink();
                }
            } else {
                this.#enableInput();

                if (this._showDelete) {
                    this.#hideDeleteLink();
                }
            }
        }
    }

    /**
     * @param {string} value
     */
    setValue(value) {
        console.debug('UiTypeaheadView#setValue');

        // Set value of typeahead field
        this.$typeaheadEl.typeahead('val', value);

        // Set value of display field
        this.el.querySelector('input[type="text"][readonly]').value = value;

        if (value) {
            this.#hideTypeaheadField();
            this.#showDisplayField();
        } else {
            this.#hideDisplayField();
            this.#showTypeaheadField();
        }
    }

    /**
     * @param {object|null} datum
     * @param {string} datasetName
     */
    setDatum(datum, datasetName = undefined) {
        console.debug('UiTypeaheadView#selectDatum');

        if (datum) {
            // Determine dataset for datum
            let dataset;
            if (this._datasets.length === 1) {
                dataset = this._datasets[0];
            } else if (datasetName) {
                dataset = this._datasets.find(
                    (dataset) => dataset.name === datasetName,
                );
            } else {
                throw new Error(
                    'Unable to set datum. Parameter datasetName was not provided and typeahead has more than 1 dataset',
                );
            }

            // Update value to result of calling the dataset's displayKey callback with the datum
            this.setValue(dataset.displayKey(datum));

            // Update link URL
            if (typeof this._urlGenerator === 'function') {
                this.setLinkURL(this._urlGenerator(datum));
            }
        } else {
            this.setValue('');
            this.setLinkURL('');
        }

        // Set datum in view
        this.datum = datum;

        this.#initializeReadEditMode();
    }

    /**
     * @param {JQuery.Event} $e
     * @param {Object<string,*>} datum
     * @param {string} datasetName
     */
    handleSelect($e, datum, datasetName) {
        console.debug('UiTypeaheadView#select');

        // Trigger "select" with original event and datum as parameters
        this.trigger('select', $e, this.datum);

        this.setDatum(datum, datasetName);
    }

    /**
     * @param {string} url
     */
    setLinkURL(url) {
        this.el.querySelector('[data-outlet="link"]').href = url;
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleClearClick($e) {
        console.debug('UiTypeaheadView#clear');

        $e.preventDefault();

        this.clear();

        // Trigger "clear" with original event as parameter
        this.trigger('clear', $e);
    }

    clear() {
        // Clear datum in view
        this.datum = null;

        this.#hideDisplayField();
        this.#showTypeaheadField();

        if (this._showDelete) {
            this.#hideDeleteLink();
        }

        // Clear link
        this.setLinkURL('');

        // Clear and enable typeahead
        this.$typeaheadEl.typeahead('val', '').prop('disabled', false).focus();
    }

    #showTypeaheadField() {
        this.el
            .querySelector('[data-part="typeahead"]')
            .classList.remove('d-none');
    }

    #hideTypeaheadField() {
        this.el
            .querySelector('[data-part="typeahead"]')
            .classList.add('d-none');
    }

    #showDisplayField() {
        this.el
            .querySelector('[data-part="display"]')
            .classList.remove('d-none');
    }

    #hideDisplayField() {
        this.el.querySelector('[data-part="display"]').classList.add('d-none');
    }

    #enableInput() {
        this.$typeaheadEl.prop('disabled', false);
    }

    #disableInput() {
        this.$typeaheadEl.prop('disabled', true);
    }

    #showDeleteLink() {
        this.$el
            .find('[data-action="delete"]')
            .closest('.input-group-append')
            .removeClass('d-none');
    }

    #hideDeleteLink() {
        this.$el
            .find('[data-action="delete"]')
            .closest('.input-group-append')
            .addClass('d-none');
    }
}
