import { View } from 'backbone';
import Chart from 'chart';
import moment from 'moment';
import config from '@/js/app/config';
import InquiriesReportResultModel from '../models/result';
import template from '../templates/results.html';

export default class ReportInquiriesResultsView extends View {
    preinitialize() {
        this.model = new InquiriesReportResultModel();
    }

    initialize() {
        // When model changes; render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('ReportInquiriesResultsView#render');

        const modelData = this.model.toJSON();

        // Render template
        this.el.innerHTML = template({ ...modelData, moment });

        this.renderChart(modelData);

        return this;
    }

    renderChart(modelData) {
        console.debug('ReportInquiriesResultsView#renderChart');

        // Create object for chart data
        const chartData = {
            labels: modelData.months.map((month) =>
                moment(month).format('MMM'),
            ),
            datasets: [
                {
                    type: 'line',
                    label: 'Target',
                    lineTension: 0,
                    data: modelData.targets,
                    fill: false,
                    borderColor: 'rgb(237,67,55)',
                    backgroundColor: 'rgb(255,255,255)',
                },
                {
                    type: 'line',
                    label: 'Previous Total',
                    lineTension: 0,
                    data: modelData.prev_totals,
                    fill: false,
                    borderColor: 'rgb(255, 165, 0)',
                    backgroundColor: 'rgb(255,255,255)',
                },
            ],
        };

        const colors = config.brand_colors.hsv(modelData.types.length, 'short');

        const barStackedId = 'bar-stacked';

        modelData.types.forEach((type, index) => {
            const color = Object.create(colors[index]);

            chartData.datasets.push({
                label: type.name,
                data: type.count,
                borderColor: color.toRgbString(),
                backgroundColor: color.toRgbString(),
                hoverBackgroundColor: color.brighten().toRgbString(),
                yAxisID: barStackedId,
            });
        });

        // If chart doesn't exists; create chart
        if (!this.chart) {
            const ctx = this.el.querySelector('canvas.chart');
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: chartData,
                options: {
                    easing: 'easeOutQuint',
                    legend: {
                        position: 'bottom',
                    },
                    scales: {
                        xAxes: [{ stacked: true }],
                        yAxes: [
                            {
                                stacked: false,
                            },
                            {
                                id: barStackedId,
                                stacked: true,
                                display: false,
                            },
                        ],
                    },
                },
            });
        }
        // Else update chart
        else {
            this.chart.data = chartData;
            this.chart.update();
        }
    }
}
