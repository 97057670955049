module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (_.isEmpty(alerts)) { ;
__p += '\n<p class="text-center">Client has not created any property alerts.</p>\n';
 } else { ;
__p += '\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>Created</th>\n            <th>Criteria</th>\n            <th>Status</th>\n            <th></th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 alerts.forEach((a) => { ;
__p += '\n        <tr>\n            <td class="align-middle">' +
((__t = ( moment.utc(a.created_at).local().format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'</td>\n            <td class="align-middle">' +
((__t = ( a.search_text.join('<br>') )) == null ? '' : __t) +
'</td>\n            <td class="align-middle">' +
((__t = ( a.status )) == null ? '' : __t) +
'</td>\n            <td class="align-middle">\n                <a href="#property/alerts/edit/' +
((__t = ( a.id )) == null ? '' : __t) +
'" class="btn btn-secondary btn-sm">Edit</a>\n            </td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>\n';
 } ;


}
return __p
};
