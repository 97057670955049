import { history, Router } from 'backbone';
import AccessDeniedView from './authentication/views/access-denied';
import BuildingsSearchView from './building/views/search';
import ClientAllView from './client/views/all';
import ClientAssignmentView from './client/views/assignment';
import ClientLatestView from './client/views/latest';
import ClientMineView from './client/views/mine';
import ClientSearchPageView from './client/pages/search';
import CompanyEditView from './company/views/edit';
import CompanySearchView from './company/views/search';
import DashboardBlankView from './dashboard/views/blank';
import DashboardDivisionView from './dashboard/views/division';
import DashboardKamisamaView from './dashboard/views/kamisama';
import DealModel from './deal/models/deal';
import DealEditPageView from './deal/pages/edit';
import DealNewPageView from './deal/pages/new';
import DealSearchView from './deal/views/search';
import DealSummaryView from './deal/views/summary';
import InquiryDetailPageView from './inquiry/views/page-detail';
import InquirySearchView from './inquiry/views/search';
import LoginView from './authentication/views/login';
import OAuth2Client from './oauth2-client';
import PeopleEditPageView from './people/pages/edit';
import PreferenceEditView from './preference/views/edit';
import Preferences from './preferences';
import PropertyAlertEditView from './property-alert/views/edit';
import PropertyAlertEmailIndexView from './property-alert-email/views/index';
import PropertyAlertEmailListView from './property-alert-email/views/email-list';
import PropertyListAddView from './property-list/views/add';
import PropertyListEditView from './property-list/views/edit';
import PropertyListSearchView from './property-list/views/search';
import propertyRestoreSelectedService from './property/services/restore-selected';
import PropertyCreateView from './property/views/create';
import PropertyEditView from './property/views/edit';
import PropertySearchView from './property/views/search';
import PropertySendView from './property/views/send';
import ReportAccountingCriteriaView from './report/accounting/views/criteria';
import ReportCommissionCriteriaView from './report/commission/views/criteria';
import ReportInquiriesIndexView from './report/inquiries/views';
import ReportMotozukeIndexView from './report/motozuke/views/index';
import ReportDaichoIndexView from './report/daicho/views/index';
import ReportRevenueCriteriaView from './report/revenue/views/criteria';
import ReportStaffCriteriaView from './report/staff/views/criteria';
import ReportYtdIndexView from './report/ytd/views/index';
import Session from './session';
import StaffEditView from './staff/views/edit';
import StaffSearchView from './staff/views/search';

// Counter for views on a given page that want to warn on unload
window.warnOnUnload = 0;

// Create reference to original loadUrl() function
const originalLoadUrlFunction = history.loadUrl;

// Set base title and separator
const titleBase = document.title;
const titleSeparator = ' | ';

// Override loadUrl function
history.loadUrl = function () {
    if (this.fragment === 'login' && arguments[0] === 'login') {
        window.warnOnUnload = 0;
        return originalLoadUrlFunction.apply(this, arguments);
    }

    if (
        window.warnOnUnload > 0 &&
        !confirm('Are you sure you want to leave this page?')
    ) {
        window.location.hash = '#' + history.fragment;
        return false;
    } else {
        window.onbeforeunload = '';
        window.warnOnUnload = 0;
        return originalLoadUrlFunction.apply(this, arguments);
    }
};

export default class AppRouter extends Router {
    preinitialize() {
        this.contentContainer = $('#content');

        this.routes = {
            '': this.routeDashboard,
            'buildings/lists/add': this.routeBuildingsListsAdd,
            'buildings/search': this.routeBuildingsSearch,
            'buildings/send': this.routeBuildingsSend,
            'buildings/send/id/:id': this.routeBuildingsSend,
            'clients/search': this.routeClientsSearch,
            'clients/mine': this.routeClientsMine,
            'clients/latest': this.routeClientsLatest,
            'clients/all': this.routeClientsAll,
            'clients/assignment': this.routeClientsAssignment,
            'property/alerts/edit/:id': this.routePropertyAlertsEdit,
            'property-alert-emails': this.routePropertyAlertEmails,
            'property-alert-emails/:id': this.routePropertyAlertEmailList,
            'property/edit': this.routePropertyCreate,
            'property/edit/:ls_id': this.routePropertyEdit,
            'property/edit/:ls_id/:id': this.routePropertyEdit,
            'property/lists': this.routePropertyLists,
            'property/lists/add': this.routePropertyListsAdd,
            'property/lists/add/:p_id': this.routePropertyListsAdd,
            'property/lists/edit/:id': this.routePropertyListsEdit,
            'property/lists/:visibility': this.routePropertyLists,
            'property/search': this.routePropertySearch,
            'property/search/:query': this.routePropertySearch,
            'property/send': this.routePropertySend,
            'property/send/id/:id': this.routePropertySend,
            'people/edit/:id': this.routePeopleEdit,
            'company/search': this.routeCompanySearch,
            'company/edit/:id': this.routeCompanyEdit,
            inquiries: this.routeInquirySearch,
            'inquiries/:id': this.routeInquiryDetail,
            enquiries: this.routeInquirySearch, // Legacy URL
            'enquiries/:id': this.routeInquiryDetail, // Legacy URL
            deals: this.routeDealSearch,
            'deals/new_sales': this.routeDealNewSales,
            'deals/new_investment': this.routeDealNewInvestment,
            'deals/new_leasing': this.routeDealNewLeasing,
            'deals/summary': this.routeDealSummary,
            'deals/edit/:id': this.routeDealEdit,
            'contracts/edit/:id': this.routeContractEdit, // Legacy URL for old links
            preferences: this.routePreferences,
            login: this.routeLogin,
            logout: this.routeLogout,
            'reports/accounting': this.routeReportsAccounting,
            'reports/commission': this.routeReportsCommission,
            'reports/commission/:staff_id/:date_from/:date_to':
                this.routeReportsCommission,
            'reports/motozuke': this.routeReportsMotozuke,
            'reports/daicho': this.routeReportsDaicho,
            'reports/revenue': this.routeReportsRevenue,
            'reports/revenue/:date_from/:date_to': this.routeReportsRevenue,
            'reports/revenue/:date_from/:date_to/:division_id':
                this.routeReportsRevenue,
            'reports/staff': this.routeReportsStaff,
            'reports/staff/:staff_id/:date_from/:date_to':
                this.routeReportsStaff,
            'reports/ytd': this.routeReportsYtd,
            'reports/ytd/:financialYear/:divisionId': this.routeReportsYtd,
            'reports/inquiries': this.routeReportsInquiries,
            'staff/search': this.routeStaffSearch,
            'staff/edit/:id': this.routeStaffEdit,
        };

        this.noTokenRoutes = [this.routeLogin.name];

        this.routePermissions = {
            // 'routeDashboard': '',
            routeBuildingsListsAdd: 'phnx:properties:r',
            routeBuildingsSearch: 'phnx:properties:r',
            routeBuildingsSend: 'phnx:properties:r',
            routeClientsSearch: 'phnx:people',
            routeClientsMine: 'phnx:people',
            routeClientsLatest: 'phnx:people',
            routeClientsAll: 'phnx:people',
            routePropertyAlertsEdit: 'phnx:property_alerts:u',
            routePropertyCreate: 'phnx:properties:r',
            routePropertyEdit: 'phnx:properties:r',
            routePropertyLists: 'phnx:properties:r',
            routePropertyListsAdd: 'phnx:properties:r',
            routePropertyListsEdit: 'phnx:properties:r',
            routePropertySearch: 'phnx:properties:r',
            routePropertySend: 'phnx:properties:r',
            routePeopleEdit: 'phnx:people',
            routeCompanySearch: 'phnx:people',
            routeCompanyEdit: 'phnx:people',
            routeInquirySearch: 'phnx:inquiries:r',
            routeInquiryDetail: 'phnx:inquiries:r',
            routeDealSearch: 'phnx:deals',
            routeDealNewSales: 'phnx:deals',
            routeDealNewLeasing: 'phnx:deals',
            routeDealSummary: 'phnx:deals',
            routeDealEdit: 'phnx:deals',
            routeContractEdit: 'phnx:deals', // Legacy URL for old links
            // 'routePreferences': '',
            routeReportsAccounting: 'phnx:accounting',
            routeReportsCommission: 'phnx:deals',
            routeReportsMotozuke: 'phnx:reports:motozuke',
            routeReportsRevenue: [
                'phnx:revenue/division',
                'phnx:revenue/company',
                'phnx:revenue/all',
            ],
            routeReportsStaff: 'phnx:deals',
            routeReportsYtd: [
                'phnx:revenue/division',
                'phnx:revenue/company',
                'phnx:revenue/all',
            ],
            routeReportsInquiries: '',
            routeStaffSearch: 'phnx:staff',
            routeStaffEdit: 'phnx:staff',
        };
    }

    execute(callback, args) {
        // If not login route
        if (!this.noTokenRoutes.includes(callback.name)) {
            // If user does NOT have token
            if (!OAuth2Client.hasToken()) {
                sessionStorage.setItem('redirect', history.fragment);

                // Redirect to login
                this.navigate('login', { trigger: true });

                return false;
            }

            // If user has no phnx:ui or routePermitted scope, render access denied
            if (
                // !Session.isAllowed('phnx:ui') ||
                this.routePermissions[callback.name] &&
                !Session.isAllowed(this.routePermissions[callback.name])
            ) {
                // Render access_denied template
                this.accessDenied = new AccessDeniedView({
                    el: document.getElementById('content'),
                });
                this.accessDenied.render();

                return false;
            }
        }

        console.debug('Router#execute: Executing "' + callback.name + '"');

        // Scroll to top
        window.scrollTo(0, 0);

        if (callback) callback.apply(this, args);
    }

    routeDashboard() {
        // Update title
        document.title = 'Dashboard' + titleSeparator + titleBase;

        // Create dashboard based on session
        let view;
        if (Session.data.dashboard === 'kamisama') {
            view = new DashboardKamisamaView();
        } else if (Session.data.dashboard === 'default') {
            view = new DashboardDivisionView();
        } else {
            view = new DashboardBlankView();
        }

        this.contentContainer.html(view.el);
    }

    routeBuildingsListsAdd(p_id) {
        // Update title
        document.title = 'Add Buildings to List' + titleSeparator + titleBase;

        if (p_id) {
            propertyRestoreSelectedService([Number(p_id)]);
        }

        // Create view
        const view = new PropertyListAddView({
            type: 'building',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeBuildingsSearch() {
        // Update title
        document.title = 'Search Buildings' + titleSeparator + titleBase;

        const view = new BuildingsSearchView();

        this.contentContainer.html(view.el);
    }

    routeBuildingsSend(id) {
        // Update title
        document.title = 'Send Buildings' + titleSeparator + titleBase;

        if (id) {
            // Set selected
            propertyRestoreSelectedService([Number(id)]);
        }

        // Create new view
        const view = new PropertySendView({
            type: 'building',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeClientsSearch() {
        // Update title
        document.title = 'Search Clients' + titleSeparator + titleBase;

        // Create view
        const view = new ClientSearchPageView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeClientsMine() {
        // Update title
        document.title = 'My Clients' + titleSeparator + titleBase;

        // Create view
        const view = new ClientMineView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeClientsLatest() {
        // Update title
        document.title = 'Latest Clients' + titleSeparator + titleBase;

        // Create view
        const view = new ClientLatestView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeClientsAll() {
        // Update title
        document.title = 'All Clients' + titleSeparator + titleBase;

        // Create view
        const view = new ClientAllView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeClientsAssignment() {
        // Update title
        document.title = 'Client Assignment' + titleSeparator + titleBase;

        // Create view
        const view = new ClientAssignmentView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routePropertyAlertsEdit(id) {
        // Update title
        document.title = 'Edit Property Alert' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new PropertyAlertEditView({
            propertyAlertId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routePropertyAlertEmails() {
        // Create new view, with new model
        const view = new PropertyAlertEmailIndexView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routePropertyAlertEmailList(id) {
        // Create new view, with new model
        const view = new PropertyAlertEmailListView({
            alertId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routePropertyCreate() {
        // Update title
        document.title = 'Add Property' + titleSeparator + titleBase;

        let newPropertyData = sessionStorage.getItem('new-property-data');

        if (newPropertyData) {
            newPropertyData = JSON.parse(newPropertyData);
        }

        const view = new PropertyCreateView({
            new_property_data: newPropertyData,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routePropertyEdit(ls_id = false, id = false) {
        // Update title
        document.title = 'Edit Property' + titleSeparator + titleBase;

        const view = new PropertyEditView({
            ls_id,
            id,
            property_type: Preferences.property_type || 'rent',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routePropertyLists(visibility) {
        // Update title
        document.title = 'Search Property Lists' + titleSeparator + titleBase;

        // Create view
        const view = new PropertyListSearchView({
            visibility,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routePropertyListsAdd(p_id) {
        // Update title
        document.title = 'Add Property List' + titleSeparator + titleBase;

        if (p_id) {
            propertyRestoreSelectedService([Number(p_id)]);
        }

        // Create view
        const view = new PropertyListAddView({
            type: 'unit',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routePropertyListsEdit(id) {
        // Update title
        document.title = 'Edit Property List' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new PropertyListEditView({
            propertyListId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routePropertySearch(query) {
        // Update title
        document.title = 'Search Properties' + titleSeparator + titleBase;

        const view = new PropertySearchView({
            property_type: 'string' === typeof query ? query : false,
        });

        this.contentContainer.html(view.el);
    }

    routePropertySend(id) {
        // Update title
        document.title = 'Send Properties' + titleSeparator + titleBase;

        if (id) {
            // Set selected
            propertyRestoreSelectedService([Number(id)]);
        }

        // Create new view
        const view = new PropertySendView({
            type: 'unit',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routePeopleEdit(id) {
        // Update title
        document.title = 'Edit Person' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new PeopleEditPageView({
            personId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);

        // Trigger fetch of models
        view.fetchModels();
    }

    routeCompanySearch() {
        // Update title
        document.title = 'Search Companies' + titleSeparator + titleBase;

        // Create view
        const view = new CompanySearchView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeCompanyEdit(id) {
        // Update title
        document.title = 'Edit Company' + titleSeparator + titleBase;

        // Create new view
        const view = new CompanyEditView({
            companyId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeInquirySearch() {
        // Update title
        document.title = 'Search Enquiries' + titleSeparator + titleBase;

        // Create view
        const view = new InquirySearchView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeInquiryDetail(id) {
        // Update title
        document.title = 'Inquiry' + titleSeparator + titleBase;

        // Create view
        const view = new InquiryDetailPageView({
            inquiryId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeDealSearch() {
        // Update title
        document.title = 'Search Deals' + titleSeparator + titleBase;

        // Create view
        const view = new DealSearchView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeDealNewSales() {
        // Update title
        document.title = 'New Deal' + titleSeparator + titleBase;

        // Create new view with deal type
        const view = new DealNewPageView({
            type: 'sales',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeDealNewInvestment() {
        // Update title
        document.title = 'New Deal' + titleSeparator + titleBase;

        // Create new view with deal type
        const view = new DealNewPageView({
            type: 'investment',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeDealNewLeasing() {
        // Update title
        document.title = 'New Deal' + titleSeparator + titleBase;

        // Create new view with deal type
        const view = new DealNewPageView({
            type: 'rent',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeDealSummary() {
        // Update title
        document.title = 'Deals Summary' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new DealSummaryView({
            model: new DealModel({
                type: 'sales',
            }),
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeDealEdit(id) {
        // Update title
        document.title = 'Edit Deal' + titleSeparator + titleBase;

        // Create deal edit page view
        const view = new DealEditPageView({
            dealId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeContractEdit(id) {
        // Redirect to deals edit
        this.navigate('deals/edit/' + id, { trigger: true });
    }

    routePreferences() {
        // Update title
        document.title = 'Preferences' + titleSeparator + titleBase;

        const view = new PreferenceEditView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeLogin() {
        // Update title
        document.title = 'Login' + titleSeparator + titleBase;

        if (OAuth2Client.hasToken() && Session.isInitialized()) {
            history.navigate('', { trigger: true });
        } else {
            const view = new LoginView();

            // Attach view to DOM
            this.contentContainer.html(view.el);
        }
    }

    routeLogout() {
        // Update title
        document.title = 'Logout' + titleSeparator + titleBase;

        // End session
        Session.end();

        // Redirect to login
        this.navigate('login', { trigger: true });
    }

    routeReportsAccounting() {
        // Update title
        document.title = 'Accounting Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportAccountingCriteriaView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeReportsCommission(staff_id, date_from, date_to) {
        // Update title
        document.title = 'Commission Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportCommissionCriteriaView({
            criteria: {
                staff_id: staff_id,
                date_from: date_from,
                date_to: date_to,
            },
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeReportsMotozuke() {
        // Update title
        document.title = 'Motozuke Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportMotozukeIndexView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeReportsDaicho() {
        // Update title
        document.title = 'Daicho Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportDaichoIndexView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeReportsRevenue(date_from = null, date_to = null, division_id = null) {
        // Update title
        document.title = 'Revenue Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportRevenueCriteriaView(
            _.omit(
                {
                    date_from: date_from,
                    date_to: date_to,
                    division_id: division_id,
                },
                _.isNull,
            ),
        );

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeReportsStaff(staff_id, date_from, date_to) {
        // Update title
        document.title = 'Staff Deal Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportStaffCriteriaView({
            criteria: {
                staff_id: staff_id,
                date_from: date_from,
                date_to: date_to,
            },
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeReportsYtd(financialYear = null, divisionId = null) {
        // Update title
        document.title = 'Year-to-date Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportYtdIndexView(
            _.omit(
                {
                    financialYear,
                    divisionId,
                },
                _.isNull,
            ),
        );

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeReportsInquiries() {
        // Update title
        document.title = 'Enquiries Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportInquiriesIndexView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeStaffSearch() {
        // Update title
        document.title = 'Search Staff' + titleSeparator + titleBase;

        // Create view
        const view = new StaffSearchView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeStaffEdit(id) {
        // Update title
        document.title = 'Edit Staff' + titleSeparator + titleBase;

        // Create new view
        const view = new StaffEditView({
            staffId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }
}
