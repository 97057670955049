import BloodhoundCompanyDataset from '@/js/app/bloodhound/datasources/company';
import BloodhoundIndividualDataset from '@/js/app/bloodhound/datasources/individual';
import BloodhoundStaffDataset from '@/js/app/bloodhound/datasources/staff';
import ChangelogsCollection from '@/js/app/changelog/collections/changelogs';
import ModalChangelogsView from '@/js/app/changelog/views/modal-changelogs';
import config from '@/js/app/config';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import BuildingModel from '@/js/app/property/models/building';
import UnitModel from '@/js/app/property/models/unit';
import propertyParseIdService from '@/js/app/property/services/parse-id';
import Session from '@/js/app/session';
import typeaheadCompanyDatasource from '@/js/app/typeahead/datasets/company';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasets/individual';
import TypeaheadDefault, {
    TypeaheadCompanyDefaults,
    TypeaheadStaffDefaults,
} from '@/js/app/typeahead/defaults';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import { history, View } from 'backbone';
import DealFileCollection from '../collections/files';
import RevenueCollection from '../collections/revenue';
import template from '../templates/edit.html';
import ModalFilesView from './modal-files';
import DealRevenueView from './revenue';

function getIndividualOrCompanyLink(datum) {
    switch (datum._type) {
        case 'individual':
            return Formatter.getIndividualLink(datum);
        case 'company':
            return Formatter.getCompanyLink(datum);
        case '':
            return '';
    }
}

export default class DealEditView extends View {
    #mode = 'read';

    preinitialize(options) {
        this.tagName = 'section';

        this.events = {
            'change #field-property_id': this.populatePropertyData,
            'click button[data-action="copy"]': this.copy,
            'click button[data-action="delete"]': this.delete,
            'click button[data-action="generate-pdf"]': this.generatePdf,
            'click button[data-action="changelog"]': this.changelog,
            'typeahead:select #cntr-貸主_本人 input[type="text"]':
                this.selectSeller,
            'click #cntr-貸主_本人 [data-action="delete"]': this.clearSeller,
            'typeahead:select #cntr-貸主_本人2 input[type="text"]':
                this.selectSeller2,
            'click #cntr-貸主_本人2 [data-action="delete"]': this.clearSeller2,
            'typeahead:select #cntr-staff input[type="text"]': this.selectStaff,
            'click #cntr-staff [data-action="delete"]': this.clearStaff,
            'typeahead:select #cntr-借主_本人 input[type="text"]':
                this.selectBuyer,
            'click #cntr-借主_本人 [data-action="delete"]': this.clearBuyer,
            'typeahead:select #cntr-借主_本人2 input[type="text"]':
                this.selectBuyer2,
            'click #cntr-借主_本人2 [data-action="delete"]': this.clearBuyer2,
            'typeahead:select #cntr-借主_入居者 input[type="text"]':
                this.selectTenant,
            'click #cntr-借主_入居者 [data-action="delete"]': this.clearTenant,
            'typeahead:select #cntr-takkengyosha_shogo input[type="text"]':
                this.selectRealEstateCompany,
            'click #cntr-takkengyosha_shogo [data-action="delete"]':
                this.clearRealEstateCompany,
            'typeahead:select #cntr-kanriitakusaki_senyubu input[type="text"]':
                this.selectManagementCompanyProprietaryArea,
            'click #cntr-kanriitakusaki_senyubu [data-action="delete"]':
                this.clearManagementCompanyProprietaryArea,
            'typeahead:select #cntr-kanriitakusaki_kyoyobu input[type="text"]':
                this.selectManagementCompanyCommonArea,
            'click #cntr-kanriitakusaki_kyoyobu [data-action="delete"]':
                this.clearManagementCompanyCommonArea,
            'change input[name],select[name],textarea[name]':
                this.handleFormChange,
            'click button[data-action="files"]': this.handleFilesClick,
            'click button[data-action="edit"]': this.handleEditClick,
            'click button[data-action="cancel"]': this.handleCancelClick,
            'click button[data-action="save"]': this.handleSaveClick,
        };

        // Create subview containers
        this.subviews = {
            changelogsModal: new ModalChangelogsView({
                collection: new ChangelogsCollection(),
            }),
            revenue: new DealRevenueView({
                collection: new RevenueCollection(),
            }),
            filesModal: new ModalFilesView({
                title: 'Files',
                collection: new DealFileCollection(null, {
                    deal_id: options.model.id,
                }),
            }),
            staff: new UiTypeaheadView({
                required: true,
                options: TypeaheadStaffDefaults,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundStaffDataset),
                ],
            }),
            seller: new UiTypeaheadView({
                required: true,
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualOrCompanyLink,
            }),
            seller2: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualOrCompanyLink,
            }),
            buyer: new UiTypeaheadView({
                required: true,
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualOrCompanyLink,
            }),
            buyer2: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: getIndividualOrCompanyLink,
            }),
            tenant: new UiTypeaheadView({
                required: true,
                options: TypeaheadDefault,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
                urlGenerator: Formatter.getIndividualLink,
            }),
            takkengyosha_shogo: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                urlGenerator: Formatter.getCompanyLink,
            }),
            kanriitakusaki_senyubu: new UiTypeaheadView({
                required: true,
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                urlGenerator: Formatter.getCompanyLink,
            }),
            kanriitakusaki_kyoyobu: new UiTypeaheadView({
                required: true,
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                urlGenerator: Formatter.getCompanyLink,
            }),
        };
    }

    initialize() {
        _.bindAll(this, 'render', 'changelog');

        this.listenTo(this.model, 'change', this.handleModelChange);

        // Listen to object changes in model
        this.listenTo(this.model, 'change:staff', this.handleStaffChange);

        // When legal_approved changes: toggle mutability
        this.listenTo(
            this.model,
            'change:legal_approved',
            this.toggleMutability,
        );

        // When legal approved by / approved at changes: update outlets
        this.listenTo(
            this.model,
            'change:legal_approved_by',
            this.updateLegalApprovedBy,
        );
        this.listenTo(
            this.model,
            'change:legal_approved_at',
            this.updateLegalApprovedAt,
        );

        // When accounting approved by / approved at changes: update outlets
        this.listenTo(
            this.model,
            'change:accounting_approved_by',
            this.updateAccountingApprovedBy,
        );
        this.listenTo(
            this.model,
            'change:accounting_approved_at',
            this.updateAccountingApprovedAt,
        );

        // When 建物の種類 changes: toggle mansion section
        this.listenTo(
            this.model,
            'change:建物の種類',
            this.toggleMansionSection,
        );

        // When building ID or unit ID changes: update link
        this.listenTo(
            this.model,
            'change:building_id change:unit_id',
            this.updatePropertyURL,
        );
    }

    fetch() {
        if (this.model.id) {
            // Trigger fetch, and call "render" on success
            this.model
                .fetch({
                    data: {
                        include: ['staff'],
                    },
                    silent: true, // OK to use silent when populating
                })
                .then(this.render);

            // Trigger fetch on revenue list
            this.subviews.revenue.collection.fetch({
                data: {
                    deal_id: this.model.id,
                    include: ['division', 'staff'],
                },
            });
        }
    }

    render() {
        console.debug('DealEditView#render');

        this.el.innerHTML = template({
            id: this.model.id,
            version: this.model.get('v'),
            dealType: this.model.get('type'),
            isFilesAllowed: Session.isAllowed('phnx:deals.files:r'),
            isDeleteAllowed: Session.isAllowed('phnx:deals:d'),
            isAccountingAllowed: Session.isAllowed('phnx:accounting'),
        });

        // Attach and render subviews
        this.$el.find('#cntr-staff').html(this.subviews.staff.render().el);
        this.$el.find('#cntr-貸主_本人').html(this.subviews.seller.render().el);
        this.$el
            .find('#cntr-貸主_本人2')
            .html(this.subviews.seller2.render().el);
        this.$el.find('#cntr-借主_本人').html(this.subviews.buyer.render().el);
        this.$el
            .find('#cntr-借主_本人2')
            .html(this.subviews.buyer2.render().el);
        this.$el
            .find('#cntr-借主_入居者')
            .html(this.subviews.tenant.render().el);
        this.$el
            .find('#cntr-takkengyosha_shogo')
            .html(this.subviews.takkengyosha_shogo.render().el);
        this.$el
            .find('#cntr-kanriitakusaki_senyubu')
            .html(this.subviews.kanriitakusaki_senyubu.render().el);
        this.$el
            .find('#cntr-kanriitakusaki_kyoyobu')
            .html(this.subviews.kanriitakusaki_kyoyobu.render().el);

        this.$el.find('#container-revenue').append(this.subviews.revenue.el);

        this.#hydrateView();

        // Toggle mansion section
        this.toggleMansionSection(this.model);

        this.#initializeReadEditMode();

        return this;
    }

    #hydrateView() {
        this.#updateForm(this.model.toJSON());
        this.#updateStaff(this.model.get('staff'));

        this.#updateSeller();
        this.#updateSeller2();
        this.#updateBuyer();
        this.#updateBuyer2();
        this.#updateTenant();
        this.#updateTakkenGyoshaShogo();
        this.#updateKanriItakuSakiSenyubu();
        this.#updateKanriItakuSakiKyoyobu();

        this.#updateProperty();
    }

    /**
     * @param {JQuery.Event} $e
     */
    selectStaff($e, datum) {
        console.debug('DealEditView#selectStaff');

        // Set staff ID in model
        this.model.set('staff_id', datum.id);
    }

    /**
     * @param {JQuery.Event} $e
     */
    clearStaff($e) {
        console.debug('DealEditView#clearStaff');

        $e.preventDefault();

        // If model is legal approved, return
        if (this.model.get('legal_approved')) {
            return;
        }

        // Remove staff ID in model
        this.model.set('staff_id', null);
    }

    /**
     * @param {JQuery.Event} $e
     */
    selectSeller($e, datum) {
        console.debug('DealEditView#selectSeller');

        this.setNameOutlet('貸主_本人', $e.currentTarget.value);
        this.setAddressOutlet(
            '貸主_本人_住所',
            datum.postcode,
            datum.address_string,
        );
        this.setTelephoneOutlet('貸主_本人_TEL', datum.telephone);
    }

    /**
     * @param {JQuery.Event} $e
     */
    clearSeller($e) {
        console.debug('DealEditView#clearSeller');

        $e.preventDefault();

        this.setNameOutlet('貸主_本人', '');
        this.setAddressOutlet('貸主_本人_住所', '', '');
        this.setTelephoneOutlet('貸主_本人_TEL', '');
    }

    /**
     * @param {JQuery.Event} $e
     */
    selectSeller2($e, datum) {
        console.debug('DealEditView#selectSeller2');

        this.setNameOutlet('貸主_本人2', $e.currentTarget.value);
        this.setAddressOutlet(
            '貸主_本人2_住所',
            datum.postcode,
            datum.address_string,
        );
        this.setTelephoneOutlet('貸主_本人2_TEL', datum.telephone);
    }

    /**
     * @param {JQuery.Event} $e
     */
    clearSeller2($e) {
        console.debug('DealEditView#clearSeller2');

        $e.preventDefault();

        this.setNameOutlet('貸主_本人2', '');
        this.setAddressOutlet('貸主_本人2_住所', '', '');
        this.setTelephoneOutlet('貸主_本人2_TEL', '');
    }

    /**
     * @param {JQuery.Event} $e
     */
    selectBuyer($e, datum) {
        console.debug('DealEditView#selectBuyer');

        this.setNameOutlet('借主_本人', $e.currentTarget.value);
        this.setAddressOutlet(
            '借主_本人_住所',
            datum.postcode,
            datum.address_string,
        );
        this.setTelephoneOutlet('借主_本人_TEL', datum.telephone);
    }

    /**
     * @param {JQuery.Event} $e
     */
    clearBuyer($e) {
        console.debug('DealEditView#clearBuyer');

        $e.preventDefault();

        this.setNameOutlet('借主_本人', '');
        this.setAddressOutlet('借主_本人_住所', '', '');
        this.setTelephoneOutlet('借主_本人_TEL', '');
    }

    /**
     * @param {JQuery.Event} $e
     */
    selectBuyer2($e, datum) {
        console.debug('DealEditView#selectBuyer2');

        this.setNameOutlet('借主_本人2', $e.currentTarget.value);
        this.setAddressOutlet(
            '借主_本人2_住所',
            datum.postcode,
            datum.address_string,
        );
        this.setTelephoneOutlet('借主_本人2_TEL', datum.telephone);
    }

    /**
     * @param {JQuery.Event} $e
     */
    clearBuyer2($e) {
        console.debug('DealEditView#clearBuyer2');

        $e.preventDefault();

        this.setNameOutlet('借主_本人2', '');
        this.setAddressOutlet('借主_本人2_住所', '', '');
        this.setTelephoneOutlet('借主_本人2_TEL', '');
    }

    /**
     * @param {JQuery.Event} $e
     */
    selectTenant($e, datum) {
        console.debug('DealEditView#selectTenant');

        this.setNameOutlet('借主_入居者', $e.currentTarget.value);
        this.setAddressOutlet(
            '借主_入居者_住所',
            datum.postcode,
            datum.address_string,
        );
        this.setTelephoneOutlet('借主_入居者_TEL', datum.telephone);
    }

    /**
     * @param {JQuery.Event} $e
     */
    clearTenant($e) {
        console.debug('DealEditView#clearTenant');

        $e.preventDefault();

        this.setNameOutlet('借主_入居者', '');
        this.setAddressOutlet('借主_入居者_住所', '', '');
        this.setTelephoneOutlet('借主_入居者_TEL', '');
    }

    /**
     * @param {JQuery.Event} $e
     */
    selectRealEstateCompany($e, datum) {
        console.debug('DealEditView#selectRealEstateCompany');
        this.setNameOutlet('takkengyosha_shogo', $e.currentTarget.value);
        this.setAddressOutlet(
            'takkengyosha_shozaichi',
            datum.postcode,
            datum.address_string,
        );
        this.setTelephoneOutlet('takkengyosha_tel', datum.telephone);
    }

    /**
     * @param {JQuery.Event} $e
     */
    clearRealEstateCompany($e) {
        console.debug('DealEditView#clearRealEstateCompany');

        $e.preventDefault();

        this.setNameOutlet('takkengyosha_shogo', '');
        this.setAddressOutlet('takkengyosha_shozaichi', '', '');
        this.setTelephoneOutlet('takkengyosha_tel', '');
    }

    /**
     * @param {JQuery.Event} $e
     */
    selectManagementCompanyProprietaryArea($e, datum) {
        console.debug('DealEditView#selectManagementCompanyProprietaryArea');

        this.setNameOutlet('kanriitakusaki_senyubu', $e.currentTarget.value);
        this.setAddressOutlet(
            'kanriitakusaki_senyubu_jusho',
            datum.postcode,
            datum.address_string,
        );
        this.setTelephoneOutlet('kanriitakusaki_senyubu_tel', datum.telephone);
    }

    /**
     * @param {JQuery.Event} $e
     */
    clearManagementCompanyProprietaryArea($e) {
        console.debug('DealEditView#clearManagementCompanyProprietaryArea');

        $e.preventDefault();

        this.setNameOutlet('kanriitakusaki_senyubu', '');
        this.setAddressOutlet('kanriitakusaki_senyubu_jusho', '', '');
        this.setTelephoneOutlet('kanriitakusaki_senyubu_tel', '');
    }

    /**
     * @param {JQuery.Event} $e
     */
    selectManagementCompanyCommonArea($e, datum) {
        console.debug('DealEditView#selectManagementCompanyCommonArea');

        this.setNameOutlet('kanriitakusaki_kyoyobu', $e.currentTarget.value);
        this.setAddressOutlet(
            'kanriitakusaki_kyoyobu_jusho',
            datum.postcode,
            datum.address_string,
        );
        this.setTelephoneOutlet('kanriitakusaki_kyoyobu_tel', datum.telephone);
    }

    /**
     * @param {JQuery.Event} $e
     */
    clearManagementCompanyCommonArea($e) {
        console.debug('DealEditView#clearManagementCompanyCommonArea');

        $e.preventDefault();
        this.setNameOutlet('kanriitakusaki_kyoyobu', '');
        this.setAddressOutlet('kanriitakusaki_kyoyobu_jusho', '', '');
        this.setTelephoneOutlet('kanriitakusaki_kyoyobu_tel', '');
    }

    /**
     * @param {string} fieldName
     * @param {string} name
     */
    setNameOutlet(fieldName, name) {
        this.el.querySelector(`input[name="${fieldName}"]`).value = name;
    }

    /**
     * @param {string} fieldName
     * @param {string} postcode
     * @param {string} address
     */
    setAddressOutlet(fieldName, postcode, address) {
        this.el.querySelector(`input[name="${fieldName}"]`).value =
            (postcode || '') + ' ' + (address || '');
    }

    /**
     * @param {string} fieldName
     * @param {string} telephone
     */
    setTelephoneOutlet(fieldName, telephone) {
        this.el.querySelector(`input[name="${fieldName}"]`).value =
            telephone || '';
    }

    /**
     * @param {JQuery.Event} $e
     */
    populatePropertyData($e) {
        console.debug('DealEditView#populatePropertyData');

        $e.preventDefault();

        const propertyId = propertyParseIdService($e.currentTarget.value);

        // If no property ID, return
        if (!propertyId) {
            return;
        }

        // Create appropriate model, based on structure_type
        let model;
        const includes = ['location'];
        if (
            propertyId.structure_type === 'B' ||
            propertyId.structure_type === 'L'
        ) {
            model = new BuildingModel({
                id: propertyId.id,
            });
        } else if (
            propertyId.structure_type === 'A' ||
            propertyId.structure_type === 'H'
        ) {
            model = new UnitModel({
                id: propertyId.id,
            });

            // Include structure
            includes.push('structure');
        }

        const all_fields = this.$el.find(
            'input[data-populate-by="property_id"]',
        );
        const structure_name_field = this.$el.find('input[name="物件名"]');
        const structure_type_field = this.$el.find('input[name="建物の種類"]');
        const address_field = this.$el.find(
            'input[name="物件所在地_部屋番号"]',
        );
        const room_no_field = this.$el.find('input[name="物件名_部屋番号"]');
        const rent_field = this.$el.find('input[name="賃貸条件_賃料_月額"]');
        const deposit_field = this.$el.find('input[name="賃貸条件_敷金"]');
        const completion_year_field = this.$el.find(
            'input[name="建物_建築年月日"]',
        );
        const size_field = this.$el.find('input[name="建物_床面積_合計"]');
        const total_floors_field = this.$el.find(
            'input[name="建物_階数_地上"]',
        );
        const total_floors_basement_field = this.$el.find(
            'input[name="建物_階数_地下"]',
        );

        const madori_field = this.$el.find('input[name="建物_間取り"]');

        structure_name_field.removeClass('error').empty();
        all_fields
            .not(
                'input[name="賃貸条件_賃料_月額"], input[name="賃貸条件_敷金"]',
            )
            .val('');

        model
            .fetch({
                data: {
                    include: includes,
                },
            })
            .then((p) => {
                const data = {
                    property_id: $e.currentTarget.value,
                };

                // Add building and unit ID based on structure type
                if (
                    p.structure_type === 'building' ||
                    p.structure_type === 'land'
                ) {
                    data.building_id = p.id;
                } else if (
                    p.structure_type === 'unit' ||
                    p.structure_type === 'house'
                ) {
                    data.building_id = p.building_id;
                    data.unit_id = p.id;
                }

                // Structure type
                structure_type_field
                    .filter('[data-structure-type="' + p.structure_type + '"]')
                    .prop('checked', true);
                data[structure_type_field.prop('name')] = structure_type_field
                    .filter(':checked')
                    .val();

                if (
                    p.structure_type === 'building' ||
                    p.structure_type === 'land'
                ) {
                    // Structure name
                    structure_name_field.val(p.structure_name_en || '');
                    data[structure_name_field.prop('name')] =
                        p.structure_name_en || '';

                    // Address
                    address_field.val(
                        p.location.prefecture +
                            p.location.city +
                            p.location.neighborhood +
                            p.location.address,
                    );
                    data[address_field.prop('name')] =
                        p.location.prefecture +
                        p.location.city +
                        p.location.neighborhood +
                        p.location.address;

                    // Total floors
                    if (total_floors_field.length > 0) {
                        total_floors_field.val(p.total_floors);
                        data[total_floors_field.prop('name')] = p.total_floors;
                    }
                    if (total_floors_basement_field.length > 0) {
                        total_floors_basement_field.val(
                            p.total_floors_basement,
                        );
                        data[total_floors_basement_field.prop('name')] =
                            p.total_floors_basement;
                    }
                } else if (
                    p.structure_type === 'unit' ||
                    p.structure_type === 'house'
                ) {
                    // Structure name
                    structure_name_field.val(
                        p.structure.structure_name_en || '',
                    );
                    data[structure_name_field.prop('name')] =
                        p.structure.structure_name_en || '';

                    // Address
                    address_field.val(
                        p.structure.location.prefecture +
                            p.structure.location.city +
                            p.structure.location.neighborhood +
                            p.structure.location.address,
                    );
                    data[address_field.prop('name')] =
                        p.structure.location.prefecture +
                        p.structure.location.city +
                        p.structure.location.neighborhood +
                        p.structure.location.address;

                    // Total floors
                    if (total_floors_field.length > 0) {
                        total_floors_field.val(p.structure.total_floors);
                        data[total_floors_field.prop('name')] =
                            p.structure.total_floors;
                    }
                    if (total_floors_basement_field.length > 0) {
                        total_floors_basement_field.val(
                            p.structure.total_floors_basement,
                        );
                        data[total_floors_basement_field.prop('name')] =
                            p.structure.total_floors_basement;
                    }

                    // Room number
                    room_no_field.val(p.room_no);
                    data[room_no_field.prop('name')] = p.room_no;

                    // Madori
                    madori_field.val(
                        `${p.bedroom_no} ${p.floorplan_type || 'LDK'}`,
                    );
                    data[madori_field.prop('name')] =
                        `${p.bedroom_no} ${p.floorplan_type || 'LDK'}`;
                }

                // Rent
                if (rent_field.length && 0 == rent_field.val().length) {
                    rent_field.val(p.rent);
                    data[rent_field.prop('name')] = p.rent;
                }
                // Deposit
                if (deposit_field.val() && 0 == deposit_field.val().length) {
                    deposit_field.val(p.deposit_by_months * p.rent);
                    data[deposit_field.prop('name')] =
                        p.deposit_by_months * p.rent;
                }
                // Year built
                if (
                    completion_year_field.val() &&
                    0 == completion_year_field.val().length
                ) {
                    completion_year_field.val(p.completion_year);
                    data[completion_year_field.prop('name')] =
                        p.completion_year;
                }
                // Structure unit size
                if (size_field.val() && 0 == size_field.val().length) {
                    size_field.val(p.size);
                    data[size_field.prop('name')] = p.size;
                }

                // Bulk update model
                this.model.set(data);
            });
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleEditClick($e) {
        console.debug('DealEditView#handleEditClick');

        $e.stopPropagation();

        this.switchEditMode();
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleCancelClick($e) {
        console.debug('DealEditView#handleCancelClick');

        $e.stopPropagation();

        this.#hideFormValidationMessage();

        this.#hydrateView();

        this.switchReadMode();
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleSaveClick($e) {
        console.debug('DealEditView#handleSaveClick');

        $e.stopPropagation();

        this.save();
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleFormChange($e) {
        console.debug('PeopleEditView#handleFormChange');

        this.#resetCustomValidation($e.currentTarget);
    }

    /**
     * @param {Backbone.Model} model
     * @param {*} value
     */
    handleStaffChange(model, value) {
        console.debug('DealEditView#handleStaffChange');

        this.#updateStaff(value);
    }

    #updateStaff(value) {
        console.debug('DealEditView#updateStaff');

        this.subviews.staff.setDatum(value);
    }

    #updateSeller() {
        console.debug('DealEditView#updateSeller');

        // Set typeahead with value and link URL
        this.subviews.seller.setValue(this.model.get('貸主_本人'));
        this.subviews.seller.setLinkURL(
            getIndividualOrCompanyLink({
                id: this.model.get('貸主_本人_id'),
                _type: this.model.get('貸主_本人_type'),
            }),
        );
    }

    #updateSeller2() {
        console.debug('DealEditView#updateSeller2');

        // Set typeahead with value and link URL
        this.subviews.seller2.setValue(this.model.get('貸主_本人2'));
        this.subviews.seller2.setLinkURL(
            getIndividualOrCompanyLink({
                id: this.model.get('貸主_本人2_id'),
                _type: this.model.get('貸主_本人2_type'),
            }),
        );
    }

    #updateBuyer() {
        console.debug('DealEditView#updateBuyer');

        // Set typeahead with value and link URL
        this.subviews.buyer.setValue(this.model.get('借主_本人'));
        this.subviews.buyer.setLinkURL(
            getIndividualOrCompanyLink({
                id: this.model.get('借主_本人_id'),
                _type: this.model.get('借主_本人_type'),
            }),
        );
    }

    #updateBuyer2() {
        console.debug('DealEditView#updateBuyer2');

        // Set typeahead with value and link URL
        this.subviews.buyer2.setValue(this.model.get('借主_本人2'));
        this.subviews.buyer2.setLinkURL(
            getIndividualOrCompanyLink({
                id: this.model.get('借主_本人2_id'),
                _type: this.model.get('借主_本人2_type'),
            }),
        );
    }

    #updateTenant() {
        console.debug('DealEditView#updateTenant');

        // Set typeahead with value and link URL
        this.subviews.tenant.setValue(this.model.get('借主_入居者'));
        this.subviews.tenant.setLinkURL(
            Formatter.getIndividualLink({
                id: this.model.get('借主_入居者_id'),
            }),
        );
    }

    #updateTakkenGyoshaShogo() {
        console.debug('DealEditView#updateTakkenGyoshaShogo');

        // Set typeahead with value and link URL
        this.subviews.takkengyosha_shogo.setValue(
            this.model.get('takkengyosha_shogo'),
        );
        this.subviews.takkengyosha_shogo.setLinkURL(
            Formatter.getCompanyLink({
                id: this.model.get('takkengyosha_shogo_id'),
            }),
        );
    }

    #updateKanriItakuSakiSenyubu() {
        console.debug('DealEditView#updateKanriItakuSakiSenyubu');

        // Set typeahead with value and link URL
        this.subviews.kanriitakusaki_senyubu.setValue(
            this.model.get('kanriitakusaki_senyubu'),
        );
        this.subviews.kanriitakusaki_senyubu.setLinkURL(
            Formatter.getCompanyLink({
                id: this.model.get('kanriitakusaki_senyubu_id'),
            }),
        );
    }

    #updateKanriItakuSakiKyoyobu() {
        console.debug('DealEditView#updateKanriItakuSakiKyoyobu');

        // Set typeahead with value and link URL
        this.subviews.kanriitakusaki_kyoyobu.setValue(
            this.model.get('kanriitakusaki_kyoyobu'),
        );
        this.subviews.kanriitakusaki_kyoyobu.setLinkURL(
            Formatter.getCompanyLink({
                id: this.model.get('kanriitakusaki_kyoyobu_id'),
            }),
        );
    }

    #updateProperty() {
        console.debug('DealEditView#updateProperty');

        // Populate property ID (this is needed because field does not have a "name" attribute)
        this.$el.find(`#field-property_id`).val(this.model.get('property_id'));
        this.updatePropertyURL(this.model);
    }

    /**
     * @param {Backbone.Model} model
     */
    updatePropertyURL(model) {
        console.debug('DealEditView#updatePropertyURL');

        const propertyLink = this.el.querySelector(
            '[data-outlet="property_link"]',
        );
        const buildingId = model.get('building_id');

        let newUrl = '';
        if (buildingId) {
            // Build new URL
            newUrl = '#property/edit/' + model.get('building_id') + '.0';

            const unitId = model.get('unit_id');
            if (unitId) {
                newUrl += '/' + unitId;
            }

            // Show property link
            propertyLink.classList.remove('d-none');
        } else {
            // Hide property link
            propertyLink.classList.add('d-none');
        }

        // Set new URL on property link
        propertyLink.href = newUrl;
    }

    switchEditMode() {
        console.debug('DealEditView#switchEditMode');

        this.#mode = 'edit';

        this.#initializeReadEditMode();

        // Switch subviews into edit mode
        this.subviews.staff.switchEditMode();
        this.subviews.seller.switchEditMode();
        this.subviews.seller2.switchEditMode();
        this.subviews.buyer.switchEditMode();
        this.subviews.buyer2.switchEditMode();
        this.subviews.tenant.switchEditMode();
        this.subviews.takkengyosha_shogo.switchEditMode();
        this.subviews.kanriitakusaki_senyubu.switchEditMode();
        this.subviews.kanriitakusaki_kyoyobu.switchEditMode();

        this.#enablePromptBeforeUnload();
    }

    switchReadMode() {
        console.debug('DealEditView#switchReadMode');

        this.#mode = 'read';

        this.#initializeReadEditMode();

        // Switch subviews into read mode
        this.subviews.staff.switchReadMode();
        this.subviews.seller.switchReadMode();
        this.subviews.seller2.switchReadMode();
        this.subviews.buyer.switchReadMode();
        this.subviews.buyer2.switchReadMode();
        this.subviews.tenant.switchReadMode();
        this.subviews.takkengyosha_shogo.switchReadMode();
        this.subviews.kanriitakusaki_senyubu.switchReadMode();
        this.subviews.kanriitakusaki_kyoyobu.switchReadMode();

        this.#disablePromptBeforeUnload();

        // Remove was-validated class from form
        const form = this.el.querySelector('form');
        form.classList.remove('was-validated');
    }

    /**
     * Handle model change
     * @param {Backbone.Model} model
     */
    handleModelChange(model) {
        console.debug('DealEditView#handleModelChange');

        const changes = model.changedAttributes();

        console.debug('DealEditView#handleModelChange: Changes', changes);

        this.#updateForm(changes);
    }

    /**
     * @param {object} changes
     */
    #updateForm(changes) {
        console.debug('DealEditView#updateForm');

        // Populate form fields
        populateForm(this.el, changes, true);
    }

    /**
     * @param {Event} $e
     */
    promptBeforeUnload(e) {
        // Prevent default (modern browsers)
        e.preventDefault();

        // Set return value (legacy browsers)
        e.returnValue = true;
    }

    #enableFormFields() {
        // Enable fields in view
        this.el
            .querySelectorAll(
                'input:not([data-backoffice-use]),select,textarea',
            )
            .forEach((el) => (el.disabled = false));

        // Switch subviews into edit mode
        this.subviews.staff.switchEditMode();
        this.subviews.seller.switchEditMode();
        this.subviews.seller2.switchEditMode();
        this.subviews.buyer.switchEditMode();
        this.subviews.buyer2.switchEditMode();
        this.subviews.tenant.switchEditMode();
        this.subviews.takkengyosha_shogo.switchEditMode();
        this.subviews.kanriitakusaki_senyubu.switchEditMode();
        this.subviews.kanriitakusaki_kyoyobu.switchEditMode();
    }

    #disableFormFields() {
        // Disable fields in view
        this.el
            .querySelectorAll(
                'input:not([data-backoffice-use]),select,textarea',
            )
            .forEach((el) => (el.disabled = true));

        // Switch subviews into read mode
        this.subviews.staff.switchReadMode();
        this.subviews.seller.switchReadMode();
        this.subviews.seller2.switchReadMode();
        this.subviews.buyer.switchReadMode();
        this.subviews.buyer2.switchReadMode();
        this.subviews.tenant.switchReadMode();
        this.subviews.takkengyosha_shogo.switchReadMode();
        this.subviews.kanriitakusaki_senyubu.switchReadMode();
        this.subviews.kanriitakusaki_kyoyobu.switchReadMode();
    }

    #disableBackofficeFields() {
        this.el
            .querySelectorAll('input[data-backoffice-use]')
            .forEach((el) => (el.disabled = true));
    }

    #initializeReadEditMode() {
        if (this.#mode === 'read') {
            this.#showEditButton();
            this.#disableFormFields();
            this.#disableBackofficeFields();
        } else if (this.#mode === 'edit') {
            this.#showSaveCancelButtons();

            // If not legal approved and not accounting approved; enable form fields
            if (
                !this.model.get('legal_approved') &&
                !this.model.get('accounting_approved')
            ) {
                this.#enableFormFields();
            }

            this.toggleLegalApprovalMutability();
            this.toggleAccountingApprovalMutability();
        }
    }

    #showSaveCancelButtons() {
        // Hide edit button
        this.el.querySelector('[data-action="edit"]').classList.add('d-none');

        // Show cancel and save buttons
        this.el
            .querySelectorAll('[data-action="cancel"],[data-action="save"]')
            .forEach((el) => el.classList.remove('d-none'));
    }

    #showEditButton() {
        // Show edit button
        this.el
            .querySelector('[data-action="edit"]')
            .classList.remove('d-none');

        // Hide cancel and save buttons
        this.el
            .querySelectorAll('[data-action="cancel"],[data-action="save"]')
            .forEach((el) => el.classList.add('d-none'));
    }

    #disablePromptBeforeUnload() {
        // Remove prompt before unload
        window.removeEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload--;
    }

    #enablePromptBeforeUnload() {
        // Add prompt before unload
        window.addEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload++;
    }

    #showFormValidationMessage() {
        this.el
            .querySelector('[data-outlet="form-invalid"]')
            .classList.remove('d-none');
    }

    #hideFormValidationMessage() {
        this.el
            .querySelector('[data-outlet="form-invalid"]')
            .classList.add('d-none');
    }

    updateLegalApprovedBy(model, value) {
        this.el.querySelector('[data-outlet="legal_approved_by"]').innerText =
            value;
    }

    updateLegalApprovedAt(model, value) {
        this.el.querySelector('[data-outlet="legal_approved_at"]').innerText =
            value;
    }

    updateAccountingApprovedBy(model, value) {
        this.el.querySelector(
            '[data-outlet="accounting_approved_by"]',
        ).innerText = value;
    }

    updateAccountingApprovedAt(model, value) {
        this.el.querySelector(
            '[data-outlet="accounting_approved_at"]',
        ).innerText = value;
    }

    toggleMutability(model) {
        console.debug('DealEditView#toggleMutability');

        // If legal_approved; lock form fields
        if (model.get('legal_approved')) {
            this.#disableFormFields();
        }
        // Else; unlock form fields
        else {
            this.#enableFormFields();
        }
    }

    toggleLegalApprovalMutability() {
        console.debug('DealEditView#toggleLegalApprovalMutability');

        // Disable legal_approved if no "phnx:deals.legal_approve:u" permission
        this.el.querySelector('[name="legal_approved"]').disabled =
            !Session.isAllowed('phnx:deals.legal_approve:u');
    }

    toggleAccountingApprovalMutability() {
        console.debug('DealEditView#toggleAccountingApprovalMutability');

        // Disable accounting_approved if no "phnx:deals.acct_approve:u" permission
        this.el.querySelector('[name="accounting_approved"]').disabled =
            !Session.isAllowed('phnx:deals.acct_approve:u');
    }

    /**
     * @param {Backbone.Model} model
     */
    toggleMansionSection(model) {
        console.debug('DealEditView#toggleMansionSection');

        // If building type is mansion
        if (model.get('建物の種類') === 'マンション') {
            // Show section
            this.$el.find('#section-mansion').show();
        } else {
            // Hide section
            this.$el.find('#section-mansion').hide();
        }
    }

    /**
     * @param {Element} fieldEl
     */
    #resetCustomValidation(fieldEl) {
        console.debug('DealEditView#resetValidation');

        // Reset invalid feedback slot
        const errorFeedback =
            fieldEl.parentElement.querySelector('.invalid-feedback');
        if (errorFeedback) {
            errorFeedback.innerText = '';
        }

        // Reset custom validity
        fieldEl.setCustomValidity('');
    }

    save() {
        console.debug('DealEditView#save');

        const form = this.el.querySelector('form');

        // Add was-validated class to form
        form.classList.add('was-validated');

        // Get invalid fields
        const invalidFields = this.el.querySelectorAll('form :invalid');

        // Reset custom validation on all invalid fields (otherwise won't attempt to perform server side validation)
        invalidFields.forEach(this.#resetCustomValidation);

        this.#hideFormValidationMessage();

        if (form.checkValidity() === false) {
            this.#showFormValidationMessage();

            return;
        }

        const formData = formToObject(form);

        // Pickup values of typeaheads
        formData['staff_id'] = this.subviews.staff.datum
            ? this.subviews.staff.datum.id
            : null;
        formData['貸主_本人_id'] = this.subviews.seller.datum
            ? this.subviews.seller.datum.id
            : null;
        formData['貸主_本人_type'] = this.subviews.seller.datum
            ? this.subviews.seller.datum._type
            : null;
        formData['貸主_本人2_id'] = this.subviews.seller2.datum
            ? this.subviews.seller2.datum.id
            : null;
        formData['貸主_本人2_type'] = this.subviews.seller2.datum
            ? this.subviews.seller2.datum._type
            : null;
        formData['借主_本人_id'] = this.subviews.buyer.datum
            ? this.subviews.buyer.datum.id
            : null;
        formData['借主_本人_type'] = this.subviews.buyer.datum
            ? this.subviews.buyer.datum._type
            : null;
        formData['借主_本人2_id'] = this.subviews.buyer2.datum
            ? this.subviews.buyer2.datum.id
            : null;
        formData['借主_本人2_type'] = this.subviews.buyer2.datum
            ? this.subviews.buyer2.datum._type
            : null;
        formData['借主_入居者_id'] = this.subviews.tenant.datum
            ? this.subviews.tenant.datum.id
            : null;
        formData['takkengyosha_shogo_id'] = this.subviews.takkengyosha_shogo
            .datum
            ? this.subviews.takkengyosha_shogo.datum.id
            : null;
        formData['kanriitakusaki_senyubu_id'] = this.subviews
            .kanriitakusaki_senyubu.datum
            ? this.subviews.kanriitakusaki_senyubu.datum.id
            : null;
        formData['kanriitakusaki_kyoyobu_id'] = this.subviews
            .kanriitakusaki_kyoyobu.datum
            ? this.subviews.kanriitakusaki_kyoyobu.datum.id
            : null;

        const payload = this.model.changedAttributes(formData);

        // If no changes; immediately switch to read mode
        if (_.isEmpty(payload)) {
            this.switchReadMode();
        } else {
            // Save new value
            this.model
                .save(payload, { patch: true, wait: true })
                .then((response) => {
                    if (response.id) {
                        this.#disablePromptBeforeUnload();

                        // Navigate to edit deal
                        history.navigate('deals/edit/' + response.id, {
                            trigger: true,
                        });
                    } else {
                        this.switchReadMode();
                    }
                })
                .catch(({ responseJSON }) => {
                    // If type of error is App\Exceptions\Validation
                    if (responseJSON.type === 'App\\Exceptions\\Validation') {
                        for (const field of responseJSON.fields) {
                            const fieldEl = this.el.querySelector(
                                `[name="${field.name}"]`,
                            );

                            // Set validation error in invalid feedback slot
                            const errorFeedback =
                                fieldEl.parentElement.querySelector(
                                    '.invalid-feedback',
                                );
                            if (errorFeedback) {
                                errorFeedback.innerText = field.message;
                            }

                            // Set validation error on fields
                            fieldEl.setCustomValidity(field.message);
                        }

                        this.#showFormValidationMessage();
                    }
                });
        }
    }

    copy() {
        console.debug('DealEditView#copy');

        // Display confirmation box to user
        if (confirm('Are you sure you want to copy this deal?')) {
            OAuth2Client.fetchJSON(`${config.api.url}deals/${this.model.id}`, {
                method: 'COPY',
            }).then((responseBody) => {
                // Redirect to edit deal
                history.navigate('deals/edit/' + responseBody.id, {
                    trigger: true,
                });
            });
        }
    }

    delete() {
        console.debug('DealEditView#delete');

        // Display confirmation box to user. If accepted; destroy model and navigate to deal search
        if (
            confirm(
                'Are you sure you want to delete this deal? This cannot be undone!',
            )
        ) {
            this.model.destroy({
                success: function () {
                    history.navigate('deals', { trigger: true });
                },
                error: function (model, response) {
                    alert(response.responseText);
                },
            });
        }
    }

    generatePdf() {
        /**
         * @todo Consider replacing with a simple call to OAuth2Client.download(), to avoid creating blob URLs that cannot be cleaned up
         */
        OAuth2Client.fetch(
            `${config.api.url}generate/deal/${this.model.id}`,
            {},
        )
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.blob();
                }
                // Else; return rejected promise
                else {
                    return response
                        .json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((blob) => {
                // Create blob URL
                const url = window.URL.createObjectURL(blob);
                // Create temporary <a> element
                const tmpAnchor = document.createElement('a');
                document.body.appendChild(tmpAnchor);
                // Set URL
                tmpAnchor.href = url;
                // Set target
                tmpAnchor.target = '_blank';
                // Trigger click
                tmpAnchor.click();
                // Remove temporary <a> element
                document.body.removeChild(tmpAnchor);
            });
    }

    changelog() {
        console.debug('DealEditView#changelog');

        // Show modal
        this.subviews.changelogsModal.show();

        // Fetch collection
        this.subviews.changelogsModal.collection.fetch({
            data: {
                resource_type: 'deal',
                resource_id: this.model.get('id'),
            },
        });
    }

    handleFilesClick() {
        console.debug('DealEditView#handleFilesClick');

        this.subviews.filesModal.show();

        // Trigger fetch on collection
        this.subviews.filesModal.collection.fetch({
            data: {
                order: 'last_modified_at',
                include: ['last_modified_by'],
            },
        });
    }
}
