import SimpleModalView from '../../generic/modal-simple';
import template from '../templates/email-events.html';

export default class EmailEventsModalView extends SimpleModalView {
    preinitialize() {
        super.preinitialize({
            modalTitle: 'Email Events',
            size: 'xl',
        });
    }

    render() {
        console.debug('EmailEventsModalView#renderModalBody');

        super.render().modalBody.innerHTML = template({
            events: this.events,
            total_rows: this.total_rows,
            limit: this.limit,
            offset: this.offset,
        });
    }
}
