import * as Formatter from '@/js/app/formatter';
import PropertyListModel from '@/js/app/property-list/models/property-list';
import PropertyListNewView from '@/js/app/property-list/views/new';
import { View } from 'backbone';
import moment from 'moment';
import template from '../templates/edit_propertylists.html';

export default class PeopleEditPropertyListsView extends View {
    preinitialize(options) {
        this.events = {
            'click [data-action="new"]': this.new,
        };

        this.person = options.person;
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'new');

        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('PeopleEditPropertyListsView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            property_lists: this.collection.toJSON(),
            person_id: this.person.id,
            Formatter,
            moment,
        });

        return this;
    }

    new() {
        console.debug('PeopleEditPropertyListsView#new');

        // Create new view, with new list model
        new PropertyListNewView({
            visibility: 'client',
            model: new PropertyListModel({
                visibility: 'shared',
                addressbook_id: this.person.id,
            }),
            collection: this.collection,
        }).render();
    }
}
