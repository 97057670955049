import { View } from 'backbone';
import ClientSearchView from '../views/search';
import template from '../templates/page-search.html';

export default class ClientSearchPageView extends View {
    preinitialize() {
        this.className = 'container';

        this.subviews = {
            clientSearch: new ClientSearchView(),
        };
    }

    initialize() {
        this.render();
    }

    render() {
        console.debug('ClientSearchPageView#render');

        this.el.innerHTML = template();

        this.$el
            .find('#cntr-search')
            .html(this.subviews.clientSearch.render().el);

        return this;
    }
}
