import numeral from 'numeral';

/**
 * Populate form with data
 * @param {Element} el
 * @param {object} data
 * @param {boolean} trigger
 */
export default function populateForm(el, data, trigger = false) {
    console.debug('PopulateForm');

    // For each key and value of data, process pair
    for (let [key, value] of Object.entries(data)) {
        processPair(el, key, value, trigger);
    }
}

/**
 * Process key/value pair for element
 * @param {Element} el
 * @param {string} key
 * @param {*} value
 * @param {boolean} trigger
 */
function processPair(el, key, value, trigger) {
    // If value is an array
    if (Array.isArray(value)) {
        // Get all fields with name = key
        const fields = el.querySelectorAll(`[name="${key}"]`);

        // Clear checked state for all fields
        fields.forEach((field) => (field.checked = false));

        // For each value in array
        value.forEach((subValue, index) => {
            // If val is an array or an object, process pair
            if (Array.isArray(subValue) || subValue instanceof Object) {
                processPair(el, `${key}.${index}`, subValue);
            } else {
                // Initialize field as element with name = key
                const field = el.querySelector(
                    `[name="${key}"][value="${subValue}"]`,
                );

                // If field exists, set as checked
                if (field) {
                    field.checked = true;
                }
            }
        });
    }
    // Else if value is an object
    else if (value instanceof Object) {
        // For each subKey and subValue of value, process pair
        for (let [subKey, subValue] of Object.entries(value)) {
            processPair(el, `${key}.${subKey}`, subValue);
        }
    } else {
        // Get all fields with name = key
        const fields = el.querySelectorAll(`[name="${key}"]`);

        // Loop over fields
        fields.forEach((field) => {
            // If field type is checkbox, set checked based on value
            if (field.type === 'checkbox') {
                const previousState = field.checked;

                field.checked = value > 0; // Only positive values are considered checked

                if (field.checked !== previousState && trigger) {
                    triggerChange(field);
                }
            }
            // Else if field type is radio
            else if (field.type === 'radio') {
                // Find radio button with the same value
                const radioButton = el.querySelector(
                    `[name="${key}"][value="${value}"]`,
                );

                // If radio button exists, set checked to true
                if (radioButton) {
                    const previousState = radioButton.checked;

                    radioButton.checked = true;

                    if (radioButton.checked === previousState && trigger) {
                        triggerChange(field);
                    }
                }
            }
            // Else if numeral data attribute exists, format appropriately
            else if (field.dataset.numeral) {
                const previousState = field.value;

                field.value =
                    value == null
                        ? ''
                        : numeral(value).format(field.dataset.numeral);

                if (field.value !== previousState && trigger) {
                    triggerChange(field);
                }
            }
            // Else if number data attribute exists, format appropriately
            else if (field.dataset.number) {
                const previousState = field.value;

                field.value =
                    value == null
                        ? ''
                        : numeral(value).format(field.dataset.number);

                if (field.value !== previousState && trigger) {
                    triggerChange(field);
                }
            }
            // Else, set value
            else {
                const previousState = field.value;

                field.value = value == null ? '' : value;

                if (field.value !== previousState && trigger) {
                    triggerChange(field);
                }
            }
        });
    }
}

/**
 * Trigger change event on element
 * @param {Element} el
 */
function triggerChange(el) {
    const event = new Event('change', { bubbles: true });
    el.dispatchEvent(event);
}
