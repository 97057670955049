import { View } from 'backbone';
import PropertyBuildingModel from '../models/building';
import PropertyLocationModel from '../models/location';
import PropertyUnitModel from '../models/unit';
import template from '../templates/edit.html';
import PropertyEditStructureView from './edit-structure';
import PropertyEditUnitView from './edit-unit';
import PropertyEditUnitList from './edit-unit-list';
import PropertyLocationView from './location';

export default class PropertyEditView extends View {
    preinitialize(options) {
        this.className = 'container';

        this.events = {
            'change #field-structure_type': this.handleFieldStructureTypeChange,
            'change [data-action="mass_override"]':
                this.handleMassOverrideChange,
        };

        this.options = options || {};

        // If ls_id option specified and contains a dot
        if (this.options.ls_id && this.options.ls_id.indexOf('.') !== -1) {
            // Split into l_id and s_id
            const split_ls_id = this.options.ls_id.split('.');
            this.options.l_id = split_ls_id[0];
            this.options.s_id = split_ls_id[1];
        }

        this.locationModel = new PropertyLocationModel({
            id: this.options.l_id,
        });
        this.buildingModel = new PropertyBuildingModel({
            id: this.options.l_id,
        });
        this.unitModel = new PropertyUnitModel({
            id: this.options.id,
            building_id: this.options.l_id,
        });

        this.subviews = {
            // Location subview
            location: new PropertyLocationView({
                model: this.locationModel,
            }),
            // Structure subview
            structure: new PropertyEditStructureView({
                model: this.buildingModel,
                location: this.locationModel,
            }),
            // Unit list subview
            unit_list: new PropertyEditUnitList({
                structure: this.buildingModel,
            }),
            // Unit subview
            unit: new PropertyEditUnitView({
                model: this.unitModel,
                structure: this.buildingModel,
                location: this.locationModel,
            }),
        };
    }

    initialize() {
        this.render();
    }

    render() {
        console.debug('PropertyEditView#render');

        this.el.innerHTML = template();

        // Determine height of navbar
        const height_navbar = $('nav.navbar').height();

        // Attach subviews
        this.subviews.location.setElement(
            this.el.querySelector('#panel-location'),
        );
        this.subviews.structure.setElement(
            this.el.querySelector('#panel-structure'),
        );
        this.subviews.unit_list.setElement(
            this.el.querySelector('#panel-unit-list'),
        );
        this.subviews.unit.setElement(this.el.querySelector('#panel-unit'));

        // Fetch location
        const fetchLocation = this.locationModel.fetch({
            data: {
                include: ['station'],
            },
            silent: true, // OK to use silent when populating
        });
        fetchLocation.then(this.subviews.location.render);

        // Fetch structure
        const fetchStructure = this.buildingModel.fetch({
            data: {
                include: [
                    'agent_company',
                    'recommended_by',
                    'campaign',
                    'assigned_staff',
                ],
            },
            silent: true, // OK to use silent when populating
        });

        // When location and structure have fetched
        Promise.all([fetchLocation, fetchStructure]).then((data) => {
            const [, structure] = data;

            // Render structure subview
            this.subviews.structure.render();

            // Render unit list subview
            this.subviews.unit_list.render();

            if (structure.structure_type === 'building') {
                // Show unit list subview
                this.$el.find('#panel-unit-list').removeClass('d-none');

                // Scroll to top of structure panel
                $(window).scrollTop(
                    this.$el.find('#panel-structure').offset().top -
                        height_navbar,
                );
            } else if (structure.structure_type === 'house') {
                // If unit ID NOT specified
                if (!this.options.id) {
                    // Set structure type to "house"
                    this.unitModel.set('structure_type', 'house');

                    // Render unit subview
                    this.subviews.unit.render();

                    // Show unit subview
                    this.$el.find('#panel-unit').removeClass('d-none');
                }

                // Scroll to top of unit panel
                $(window).scrollTop(
                    this.$el.find('#panel-unit').offset().top - height_navbar,
                );
            } else {
                // Scroll to top of structure panel
                $(window).scrollTop(
                    this.$el.find('#panel-structure').offset().top -
                        height_navbar,
                );
            }
        });

        // If unit ID specified
        if (this.options.id) {
            // Fetch unit data
            const fetchUnit = this.unitModel.fetch({
                data: {
                    include: [
                        'agent_company',
                        'recommended_by',
                        'campaign',
                        'assigned_staff',
                    ],
                },
                silent: true, // OK to use silent when populating
            });

            // When location, structure, and unit have fetched
            Promise.all([fetchLocation, fetchStructure, fetchUnit]).then(() => {
                // Render unit subview
                this.subviews.unit.render();

                // Show unit subview
                this.$el.find('#panel-unit').removeClass('d-none');

                // Scroll to top of "unit" panel
                $(window).scrollTop(
                    this.$el.find('#panel-unit').offset().top - height_navbar,
                );
            });
        }

        return this;
    }

    handleFieldStructureTypeChange($e) {
        const structure_type = $e.currentTarget.value;

        if (structure_type === 'building') {
            // Show unit list subview
            this.$el.find('#panel-unit-list').removeClass('d-none');

            // If unit ID specified, show unit panel
            if (this.options.id) {
                this.$el.find('#panel-unit').removeClass('d-none');
            } else {
                this.$el.find('#panel-unit').addClass('d-none');
            }
        } else if (structure_type === 'house') {
            // Hide unit list subview
            this.$el.find('#panel-unit-list').addClass('d-none');

            // If unit ID NOT specified
            if (!this.options.id) {
                // Set structure type to "house"
                this.unitModel.set('structure_type', 'house');

                // Re-render unit subview
                this.subviews.unit.render();
            }

            // Show unit panel
            this.$el.find('#panel-unit').removeClass('d-none');
        } else {
            // Hide unit list and unit panel
            this.$el.find('#panel-unit-list, #panel-unit').addClass('d-none');
        }
    }

    handleMassOverrideChange($e) {
        if ($e.currentTarget.checked) {
            this.$el.find('div.mass_override').addClass('enabled');
        } else {
            this.$el.find('div.mass_override').removeClass('enabled');
        }
    }
}
