import * as Formatter from '@/js/app/formatter';
import { View } from 'backbone';
import template from '../templates/results.html';

export default class ReportAccountingResultsView extends View {
    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('ReportAccountingResultsView#render');

        this.el.innerHTML = template({
            results: this.collection.toJSON(),
            Formatter,
        });

        // Initialize popovers
        this.$el
            .find('a[rel="popover"]')
            .popover({
                placement: 'left',
            })
            // Disable "click"
            .click((e) => e.preventDefault());

        return this;
    }
}
