import Session from '@/js/app/session';
import StaffCollection from '@/js/app/staff/collections/staff';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import { View } from 'backbone';
import ClientAssignedModel from '../models/client-assigned';
import template from '../templates/assignment.html';
import ClientAssignmentResultsView from './assignment-results';

export default class ClientAssignmentView extends View {
    preinitialize() {
        this.className = 'container';

        this.events = {
            'change [name="assigned_staff_id"]': this.handleChangeStaff,
        };

        // Create subviews
        this.subviews = {
            assignedStaff: new UiIndividualSelectorView({
                id: 'field-assigned_staff',
                collection: new StaffCollection(),
                attributes: {
                    name: 'assigned_staff_id',
                },
                prependOptions: [
                    {
                        label: 'Unassigned',
                        value: '0',
                    },
                ],
            }),
            clientAssignmentResults: new ClientAssignmentResultsView({
                model: new ClientAssignedModel(),
            }),
        };
    }

    initialize() {
        this.subviews.assignedStaff.setSelected(Session.data.staff_id);

        this.render();
    }

    render() {
        console.debug('ClientAssignmentView#render');

        // Render template
        this.el.innerHTML = template({
            Session,
        });

        // Attach assignedStaff subview to DOM
        this.$el
            .find('#cntr-assigned_staff')
            .html(this.subviews.assignedStaff.el);

        // Fetch staff
        this.subviews.assignedStaff.collection.fetch({
            data: {
                order: 'last_name',
                division_id: [1, 2, 3, 11],
                is_virtual: 0,
            },
        });

        // TODO: Reenable after feedback
        // // if no scope in session
        // if (Session.isAllowed('phnx:people:exp')) {
        //     this.el.querySelector('[name="assigned_staff_id"]').disabled = true;
        // }

        // Attach clients results view to DOM
        this.subviews.clientAssignmentResults
            .setElement(this.$el.find('#results'))
            .render();

        return this;
    }

    handleChangeStaff(e) {
        const staffId = e.target.value;

        this.subviews.clientAssignmentResults.model.fetch(staffId);
    }
}
