module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="mt-3">\n    <div class="row align-items-center">\n        <div class="col">\n            <h1 class="h2 my-0">\n                <span data-outlet="last_name"></span> <span data-outlet="first_name"></span>\n            </h1>\n            <span data-outlet="company_name"></span>\n        </div>\n\n        <div class="col-auto">\n            ';
 if (isDeleteAllowed) { ;
__p += '\n            <button type="button" class="btn btn-sm btn-danger" title="Delete Person"\n                    data-action="delete">Delete</button>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>\n\n<hr>\n\n<div class="d-flex justify-content-end mb-3">\n    <button type="button" class="btn btn-sm btn-info ml-2" title="View subscriptions" data-action="subscriptions"><i\n        class="fa fa-envelope-o" aria-hidden="true"></i> Subscriptions</button>\n    <button type="button" class="btn btn-sm btn-info ml-2" title="View activity" data-action="activity"><i\n           class="fa fa-file-o" aria-hidden="true"></i> Activity</button>\n    <button type="button" class="btn btn-sm btn-info ml-2" title="View alerts" data-action="alerts"><i\n           class="fa fa-bell-o" aria-hidden="true"></i> Alerts</button>\n    <button type="button" class="btn btn-sm btn-info ml-2" title="View page views" data-action="page-views"><i\n            class="fa fa-eye" aria-hidden="true"></i> Page Views</button>\n    <button type="button" class="btn btn-sm btn-info ml-2" title="View enquiries" data-action="inquiries"><i\n           class="fa fa-question-circle-o" aria-hidden="true"></i> Enquiries</button>\n    <button type="button" class="btn btn-sm btn-info ml-2" title="View property lists" data-action="property-lists"><i\n           class="fa fa-list" aria-hidden="true"></i> Property Lists</button>\n    <button type="button" class="btn btn-sm btn-info ml-2" title="View deals" data-action="deals"><i\n           class="fa fa-handshake-o" aria-hidden="true"></i> Deals</button>\n</div>\n\n<div class="alert alert-danger text-center d-none" data-outlet="deleted-alert">Client has been deleted</div>\n\n<div id="cntr-edit"></div>';

}
return __p
};
