import BloodhoundStaffDataset from '@/js/app/bloodhound/datasources/staff';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasets/individual';
import { TypeaheadStaffDefaults } from '@/js/app/typeahead/defaults';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import { View } from 'backbone';
import moment from 'moment';
import ReportAccountingResultCollection from '../../accounting/collections/results';
import { getSelectInputs, setRangeInputFields } from '../../date-ranges';
import template from '../templates/generate.html';
import ReportCommissionResultsView from './results';
import ReportCommissionSummaryView from './summary';

export default class ReportCommissionCriteriaView extends View {
    preinitialize(options) {
        this.tagName = 'section';
        this.id = 'reports';
        this.className = 'container';

        // Extend default criteria with criteria from options
        this.criteria = _.extend(
            {
                time_range: 'month-current',
                date_from: moment().format('YYYY-MM-01'),
                date_to: moment().format('YYYY-MM-') + moment().daysInMonth(),
                staff_id: 0,
            },
            options.criteria,
        );

        this.events = {
            'submit #frm-report-criteria': this.generate,
            'change #time-range': this.handleTimeRangeChange,
            'change [name="date_from"],[name="date_to"]': this.handleDateChange,
            'click [data-action="reset"]': this.handleClearCriteria,
        };

        // Create subviews
        this.subviews = {
            staffTypeahead: new UiTypeaheadView({
                placeholder: 'Agent',
                options: TypeaheadStaffDefaults,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundStaffDataset),
                ],
                mode: 'edit',
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'generate');

        this.render();
    }

    render() {
        console.debug('ReportCommissionCriteriaView#render');

        // Render template
        this.el.innerHTML = template({
            criteria: this.criteria,
        });

        this.subviews.staffTypeahead
            .setElement(this.$el.find('#cntr-staff_id'))
            .render();

        if (this.criteria.staff_id) {
            // Fetch information for given staff ID
            /** @todo Ideally we could set the ID in typeahead, and have it reverse lookup the staff, but Bloodhound isn't built for this */
            OAuth2Client.fetchJSON(
                config.api.url + 'staff/' + this.criteria.staff_id,
                {},
            ).then((response) => {
                // Set datum
                this.subviews.staffTypeahead.setDatum(response);

                // Trigger click on submit
                this.el.querySelector('button[type="submit"]').click();
            });
        }

        this.timeRangeSelect = this.el.querySelector('#time-range');
        this.fromSelect = this.el.querySelector('input[name="date_from"]');
        this.toSelect = this.el.querySelector('input[name="date_to"]');

        // Get report criteria from sessionStorage
        this.commissionReportCriteria = JSON.parse(
            sessionStorage.getItem('commissionReportCriteria'),
        );

        // If search conditions exists
        if (this.commissionReportCriteria) {
            const { time_range, date_from, date_to, staff_id } =
                this.commissionReportCriteria;

            populateForm(this.el.querySelector('#frm-report-criteria'), {
                time_range: time_range,
                date_from: date_from,
                date_to: date_to,
                staff_id: staff_id || undefined,
            });

            if (staff_id) {
                // Fetch information for given staff ID
                /** @todo Ideally we could set the ID in typeahead, and have it reverse lookup the staff, but Bloodhound isn't built for this */
                OAuth2Client.fetchJSON(
                    config.api.url + 'staff/' + staff_id,
                    {},
                ).then((response) => {
                    // Set datum
                    this.subviews.staffTypeahead.setDatum(response);

                    // Trigger click on submit
                    this.el.querySelector('button[type="submit"]').click();
                });
            }
        } else {
            this.setDefaultFields();
        }

        return this;
    }

    setDefaultFields() {
        // Extend default criteria with criteria from options
        this.criteria = {
            time_range: 'month-current',
            date_from: moment().startOf('month').format('YYYY-MM-DD'),
            date_to: moment().endOf('month').format('YYYY-MM-DD'),
            staff_id: 0,
        };

        populateForm(this.el.querySelector('#frm-report-criteria'), {
            time_range: this.criteria.time_range,
            date_from: this.criteria.date_from,
            date_to: this.criteria.date_to,
            staff_id: null,
        });
    }

    handleTimeRangeChange($e) {
        const rangeType = $e.target.value;
        const inputs = getSelectInputs(this);

        setRangeInputFields(rangeType, inputs);
    }

    handleDateChange() {
        const dateRangeInput = this.el.querySelector('#time-range');

        dateRangeInput.value = 'custom';
    }

    handleClearCriteria(e) {
        console.debug('ReportCommissionCriteriaView#handleClearCriteria');

        e.preventDefault();

        sessionStorage.removeItem('commissionReportCriteria');

        this.setDefaultFields();
        this.subviews.staffTypeahead.clear();
    }

    generate($e) {
        console.debug('ReportCommissionCriteriaView#generate');

        $e.preventDefault();

        const criteria = {
            staff_id: this.subviews.staffTypeahead.datum
                ? this.subviews.staffTypeahead.datum.id
                : null,
            after_deductions: 1,
        };

        const form = this.el.querySelector('form');

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        // Store search conditions to sessionStorage
        sessionStorage.setItem(
            'commissionReportCriteria',
            JSON.stringify(criteria),
        );

        // Create new results collection
        const reportResults = new ReportAccountingResultCollection();

        // Create new summary view
        const commissionSummaryView = new ReportCommissionSummaryView({
            collection: reportResults,
        });

        // Attach commission summary view to DOM
        this.$el.find('#summary').html(commissionSummaryView.el);

        // Create new results view
        const commissionResultsView = new ReportCommissionResultsView({
            collection: reportResults,
        });

        // Attach commission results view to DOM
        this.$el.find('#results').html(commissionResultsView.el);

        // Fetch results
        reportResults.fetch({
            data: criteria,
            reset: true,
        });
    }
}
