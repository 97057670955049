import * as Formatter from '@/js/app/formatter';
import Session from '@/js/app/session';
import * as TextHelper from '@/js/app/text-helper';
import { View } from 'backbone';
import Spinner from '../../ui/views/spinner';
import template from '../templates/assignment-results.html';
import ClientAssigmentResultRowView from './assignment-results-row';

export default class ClientAssignmentResultsView extends View {
    preinitialize() {
        this.events = {
            'click th[data-col]': this.handleClickColumn,
        };

        this.criteria = {};
    }

    initialize() {
        this.spinner = new Spinner({
            top: true,
        });

        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            this.spinner.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            this.spinner.stop();
        });

        // When model syncs; render
        this.listenTo(this.model, 'sync', this.render);
    }

    render() {
        console.debug('ClientAssignmentResultsView#render');

        const data = this.model.toJSON();

        this.el.innerHTML = template({
            results_count: data.length,
            TextHelper,
            Formatter,
            Session,
        });

        this.rowCount = data.length;

        data.forEach((person) => {
            this.addRow(person);
        });

        return this;
    }

    addRow(data) {
        const rowElView = new ClientAssigmentResultRowView({
            data,
        });

        this.el
            .querySelector('#assignment-results')
            .appendChild(rowElView.render().el);

        this.listenTo(rowElView, 'unassign', this.handleRemoveRow);
    }

    handleRemoveRow(rowView) {
        this.rowCount -= 1;

        this.el.querySelector('[data-outlet="row-count"]').textContent =
            this.rowCount;

        this.stopListening(rowView);
    }

    handleClickColumn(event) {
        console.debug(event);

        // Check current sort key
        if (this.criteria.sort_key === event.currentTarget.dataset.col) {
            // If same as current sort key, swap sort order
            this.criteria.sort_order =
                this.criteria.sort_order === 'asc' ? 'desc' : 'asc';
        } else {
            // Change sort key and reset sort order
            this.criteria.sort_key = event.currentTarget.dataset.col;
            this.criteria.sort_order = 'asc';
        }

        // Sort collection
        this.model.toJSON().sort((a, b) => {
            const key = this.criteria.sort_key;
            const order = this.criteria.sort_order === 'asc' ? 1 : -1;

            if (a[key] === null) {
                return 1;
            }

            if (b[key] === null) {
                return -1;
            }

            if (a[key] > b[key]) {
                return order;
            }

            if (a[key] < b[key]) {
                return -order;
            }

            return 0;
        });

        // Re-render
        this.render();
    }
}
