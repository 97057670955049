import * as Formatter from '@/js/app/formatter';
import Session from '@/js/app/session';
import { View } from 'backbone';
import moment from 'moment';
import template from '../templates/modal_files_item.html';

export default class DealFileItemView extends View {
    preinitialize() {
        this.tagName = 'tr';

        this.events = {
            'click [data-action="download"]': this.download,
            'click [data-action="delete"]': this.delete,
        };
    }

    initialize() {
        // When model is destroyed or removed from collection: remove view
        this.listenTo(this.model, 'destroy remove', this.remove);

        // When model changes: render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('DealFileItemView#render');

        this.el.innerHTML = template({
            file: this.model.toJSON(),
            isDeleteAllowed: Session.isAllowed('phnx:deals.files:d'),
            Formatter,
            moment,
        });

        return this;
    }

    /**
     * @param {JQuery.Event} $e
     */
    download($e) {
        console.debug('DealFileItemView#download');

        $e.preventDefault();

        this.model.download();
    }

    /**
     * @param {JQuery.Event} $e
     */
    delete($e) {
        console.debug('DealFileItemView#delete');

        $e.preventDefault();
        $e.stopPropagation();

        if (
            confirm(
                'Are you sure you want to delete this file? This cannot be undone!',
            )
        ) {
            // Destroy model
            this.model.destroy({
                wait: true,
            });
        }
    }
}
