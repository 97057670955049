import { getFinancialYear } from '@/js/app/formatter';
import Session from '@/js/app/session';
import { View } from 'backbone';
import ReportDaichoResultsCollection from '../collections/results';
import template from '../templates/index.html';
import ReportDaichoResultsView from './results';

class ReportDaichoIndexView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'reports';
        this.className = 'container';

        this.events = {
            'change select[name="fy"]': this.handleChangeFinancialYear,
            'change select[name="type"]': this.handleChangeType,
        };

        this.currentFinancialYear = getFinancialYear();

        this.financialYear = this.currentFinancialYear;
        this.type = 'sales';

        this.resultsCollection = new ReportDaichoResultsCollection();
        this.resultsCollection.financialYear = this.financialYear;
        this.resultsCollection.type = this.type;

        this.criteria = {
            type: this.type,
        };

        // If allowed to access revenue (all) or revenue (company)
        if (
            Session.isAllowed('phnx:revenue/all') ||
            Session.isAllowed('phnx:revenue/company')
        ) {
            // Generate year range from 2017 to next FY (_.range end is exclusive, hence + 2)
            this.years = _.range(2017, this.currentFinancialYear + 1);
        } else {
            // Generate year range from previous FY to next FY (_.range end is exclusive, hence + 2)
            this.years = _.range(
                this.currentFinancialYear - 1,
                this.currentFinancialYear + 1,
            );
        }

        // Create subviews
        this.subviews = {
            resultsView: new ReportDaichoResultsView({
                collection: this.resultsCollection,
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        this.render();

        // Initiate fetch on results collection
        this.resultsCollection.fetch();
    }

    render() {
        console.debug('ReportDaichoIndexView#render');

        // Render template
        this.el.innerHTML = template({
            years: this.years,
        });

        this.el.querySelector('select[name="fy"]').value = this.financialYear;

        // Bind results view and render
        this.subviews.resultsView
            .setElement(this.el.querySelector('#results'))
            .render({
                type: this.criteria.type,
            });

        return this;
    }

    handleChangeFinancialYear() {
        console.debug('ReportDaichoIndexView#handleChangeFinancialYear');

        this.financialYear = this.el.querySelector('select[name="fy"]').value;

        this.resultsCollection.financialYear = this.financialYear;

        this.resultsCollection.fetch({
            data: this.criteria,
            reset: true,
        });
    }

    handleChangeType() {
        console.debug('ReportDaichoIndexView#handleChangeType');

        this.type = this.el.querySelector('select[name="type"]').value;

        this.criteria.type = this.type;

        this.resultsCollection.type = this.type;

        this.resultsCollection.fetch({
            data: this.criteria,
            reset: true,
        });
    }
}

export default ReportDaichoIndexView;
