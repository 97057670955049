import * as Formatter from '@/js/app/formatter';
import * as TextHelper from '@/js/app/text-helper';
import templateError from '@/js/app/ui/templates/error.html';
import templateSpinner from '@/js/app/ui/templates/spinner.html';
import { View } from 'backbone';
import template from '../templates/results.html';
import ReportDaichoResultRowView from './result_row';

class ReportDaichoResultsView extends View {
    preinitialize(options) {
        this.events = {
            'click th[data-col]': this.handleClickColumn,
        };

        this.criteria = options.criteria;
    }

    initialize() {
        _.bindAll(this, 'render');

        // When collection changes; render
        this.listenTo(this.collection, 'change', this.render);

        // When collection makes request, show spinner
        this.listenTo(this.collection, 'request', this.handleCollectionRequest);

        // When collection request finishes, stop spinner
        this.listenTo(this.collection, 'sync', this.handleCollectionSync);

        // If collection errors, show error message
        this.listenTo(this.collection, 'error', this.handleCollectionError);
    }

    render() {
        console.debug('ReportDaichoResultsView#render');

        if (this.loading) {
            this.el.innerHTML = templateSpinner();
        } else if (this.error) {
            this.el.innerHTML = templateError();
        } else {
            const data = this.collection.toJSON();

            this.el.innerHTML = template({
                count_deals: data.results.length,
                total_brokerage: data.total_brokerage,
                total_price: data.total_price,
                total_rent: data.total_rent,
                type: this.criteria.type,
                Formatter,
                TextHelper,
            });

            data.results.forEach((result) => {
                this.addRow(result);
            });

            if (this.criteria.sort_key) {
                // Highlight column being sorted
                this.el
                    .querySelector(
                        'th[data-col="' + this.criteria.sort_key + '"]',
                    )
                    .classList.add(this.criteria.sort_order);
            }

            // Setup lightbox
            this.$el.find('.lightbox').fancybox({
                margin: 30,
            });
        }

        return this;
    }

    addRow(data) {
        const rowEl = new ReportDaichoResultRowView({
            data,
            type: this.criteria.type,
        }).render().el;

        this.el.querySelector('#results-body').appendChild(rowEl);
    }

    handleCollectionRequest() {
        this.loading = true;

        this.render();
    }

    handleCollectionSync() {
        this.loading = false;

        this.render();
    }

    handleCollectionError() {
        this.loading = false;
        this.error = true;

        this.render();
    }

    handleClickColumn(event) {
        console.debug(event);

        // Check current sort key
        if (this.criteria.sort_key === event.currentTarget.dataset.col) {
            // If same as current sort key, swap sort order
            this.criteria.sort_order =
                this.criteria.sort_order === 'asc' ? 'desc' : 'asc';
        } else {
            // Change sort key and reset sort order
            this.criteria.sort_key = event.currentTarget.dataset.col;
            this.criteria.sort_order = 'asc';
        }

        // Sort collection
        this.collection.sort(this.criteria.sort_key, this.criteria.sort_order);

        // Re-render
        this.render();
    }
}

export default ReportDaichoResultsView;
