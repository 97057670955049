import { View } from 'backbone';
import modalTemplate from './templates/modal.html';

export default class ModalView extends View {
    preinitialize() {
        // Initialize defaults
        this.className = 'modal fade';
        this.events = {
            ...this.events,
            'hidden.bs.modal': this.teardown,
        };
    }

    initialize() {
        this.render();
    }

    render(options = { size: false, centered: false, scrollable: false }) {
        console.debug('ModalView#render');

        this.el.innerHTML = modalTemplate({
            size: false,
            centered: false,
            scrollable: false,
            ...options,
        });

        this.$el.modal({
            backdrop: true,
            keyboard: true,
            show: false,
        });

        this.modalContent = this.el.querySelector('.modal-content');

        return this;
    }

    show() {
        this.$el.modal('show');
    }

    hide() {
        this.$el.modal('hide');
    }

    teardown() {
        console.debug('ModalView#teardown');

        // Remove modal functionality from element
        this.$el.data('modal', null);
    }
}
