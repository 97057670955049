import * as Formatter from '@/js/app/formatter';
import SimpleModalView from '@/js/app/generic/modal-simple';
import propertyGetTitleService from '@/js/app/property/services/get-title';
import * as TextHelper from '@/js/app/text-helper';
import moment from 'moment';
import template from '../templates/modal_inquiries.html';

export default class ModalActivityView extends SimpleModalView {
    preinitialize() {
        super.preinitialize({
            modalTitle: 'Enquiries',
            size: 'xl',
        });

        this.events = {
            ...this.events,
            'click .modal-body a': this.handleLinkClick,
        };
    }

    initialize() {
        super.initialize();

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', this.startSpinner);

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', this.stopSpinner);

        // When collection syncs; render modal body
        this.listenTo(this.collection, 'sync', this.renderModalBody);
    }

    renderModalBody() {
        console.debug('ModalInquiriesView#renderModalBody');

        // Render parent view, then set modal content to template
        this.modalBody.innerHTML = template({
            inquiries: this.collection.toJSON(),
            propertyGetTitleService,
            Formatter,
            TextHelper,
            moment,
        });
    }

    handleLinkClick() {
        // Hide modal when link clicked
        this.hide();
    }
}
