module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 revenue.forEach ((r, i) => { ;
__p += '\n<div class="card mb-3">\n    <div class="card-header d-flex justify-content-between">\n        <div>\n            <span class="font-weight-bolder">#' +
((__t = ( i+1 )) == null ? '' : __t) +
'</span>\n        </div>\n        <div>\n            <span class="badge ' +
((__t = ( r.paid ? 'badge-success' : 'badge-warning' )) == null ? '' : __t) +
'">\n                ' +
((__t = ( Formatter.paid(r.paid) )) == null ? '' : __t) +
'\n            </span>\n\n            ';
 if (r.lock_acct) { ;
__p += '\n            <span class="badge badge-danger">\n                Locked <i class="fa fa-lock" aria-hidden="true"></i>\n            </span>\n            ';
 } ;
__p += '\n\n            <a href="' +
((__t = ( Formatter.rms_url(r.id) )) == null ? '' : __t) +
'" target="_blank" class="ml-1" title="View in RMS">\n                <i class="fa fa-external-link" aria-hidden="true"></i>\n            </a>\n        </div>\n    </div>\n    <div class="card-body">\n        <dl class="row mb-0">\n            <dt class="col-1">Type</dt>\n            <dd class="col-2">' +
((__t = ( Formatter.feeType(r.type) )) == null ? '' : __t) +
'</dd>\n\n            <dt class="col-1">Date</dt>\n            <dd class="col-2">' +
((__t = ( r.sales_date )) == null ? '' : __t) +
'</dd>\n\n            <dt class="col-1">Tax</dt>\n            <dd class="col-1">' +
((__t = ( r.tax_rate * 100 )) == null ? '' : __t) +
'%</dd>\n\n            <dt class="col-2">Amount</dt>\n            <dd class="col-2 text-right">\n                ' +
((__t = ( Formatter.amount(r.amount) )) == null ? '' : __t) +
'\n            </dd>\n        </dl>\n\n        <div class="row">\n            <dt class="col-1">Details</dt>\n            <dd class="col-7">' +
((__t = ( r.details )) == null ? '' : __t) +
'</dd>\n            <dt class="col-2">Net Amount</dt>\n            <dd class="col-2 text-right">' +
((__t = ( Formatter.amount(r.net_amount) )) == null ? '' : __t) +
'</dd>\n        </div>\n\n        ';
 if (r.costs.length > 0) { ;
__p += '\n        <h4 class="h5 mt-3">Costs</h4>\n        <table class="table table-sm mb-0">\n            <thead class="thead-light">\n                <tr>\n                    <th>Company Name</th>\n                    <th class="w-50">Details</th>\n                    <th>Date</th>\n                    <th class="text-right">Amount</th>\n                    <th>確認</th>\n                </tr>\n            </thead>\n            <tbody>\n                ';
 r.costs.forEach((c) => { ;
__p += '\n                <tr>\n                    <td>' +
((__t = ( c.company_name )) == null ? '' : __t) +
'</td>\n                    <td>' +
((__t = ( c.details )) == null ? '' : __t) +
'</td>\n                    <td class="text-nowrap">' +
((__t = ( c.date )) == null ? '' : __t) +
'</td>\n                    <td class="text-right text-nowrap">' +
((__t = ( Formatter.amount(c.amount) )) == null ? '' : __t) +
'</td>\n                    <td>\n                        <span class="badge ' +
((__t = ( c.paid ? 'badge-success' : 'badge-warning' )) == null ? '' : __t) +
'">\n                            ' +
((__t = ( Formatter.paid(c.paid) )) == null ? '' : __t) +
'\n                        </span>\n                    </td>\n                </tr>\n                ';
 }) ;
__p += '\n            </tbody>\n        </table>\n        ';
 } ;
__p += '\n\n        <div class="row tight-gutters">\n            <div class="col-3 offset-9">\n                <hr>\n            </div>\n        </div>\n\n        <dl class="row mb-0">\n            <dt class="col-2 offset-8">Net Revenue</dt>\n            <dd class="col-2 text-right">\n                ' +
((__t = ( Formatter.amount(r.net) )) == null ? '' : __t) +
'\n            </dd>\n        </dl>\n\n        ';
 if (r.splits.length > 0) { ;
__p += '\n        <h4 class="h5">Splits</h4>\n        <table class="table table-sm">\n            <thead class="thead-light">\n                <tr>\n                    <th>Division</th>\n                    <th>Staff</th>\n                    <th class="text-right">Amount</th>\n                    <th class="text-right">Deduction %</th>\n                    <th class="text-right">Post Deduction</th>\n                </tr>\n            </thead>\n            <tbody>\n                ';
 r.splits.forEach((s) => { ;
__p += '\n                <tr>\n                    <td>' +
((__t = ( s.division?.name )) == null ? '' : __t) +
'</td>\n                    <td>' +
((__t = ( s.staff ? Formatter.getName(s.staff) : '' )) == null ? '' : __t) +
'</td>\n                    <td class="text-right">' +
((__t = ( Formatter.amount(s.amount) )) == null ? '' : __t) +
'</td>\n                    <td class="text-right">' +
((__t = ( s.deduction_pct * 100 )) == null ? '' : __t) +
'</td>\n                    <td class="text-right">' +
((__t = ( Formatter.amount(s.amount_post_deduction) )) == null ? '' : __t) +
'</td>\n                </tr>\n                ';
 }) ;
__p += '\n            </tbody>\n        </table>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';
 }) ;


}
return __p
};
