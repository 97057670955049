import uploader from '@/js/app/file-uploader/uploader';
import ModalView from '@/js/app/generic/modal';
import Session from '@/js/app/session';
import Spinner from '@/js/app/ui/views/spinner';
import async from 'async';
import template from '../templates/modal_files.html';
import DealFilesItemView from './modal-files-item';

export default class ModalFilesView extends ModalView {
    preinitialize(options) {
        super.preinitialize();

        this.title = options.title || 'Files';

        this.spinner = new Spinner();

        this.events = {
            ...this.events,
            'click .btn-file-upload': this.handleUploadClick,
            'change .file-selector': this.uploadFile,
        };
    }

    initialize() {
        super.initialize();

        _.bindAll(this, 'addFileItem');

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', this.startSpinner);
        this.listenTo(this.collection, 'request', this.disableFormElements);

        // When collection finishes request; stop spinner and enable form elements
        this.listenTo(this.collection, 'sync destroy error', this.stopSpinner);
        this.listenTo(
            this.collection,
            'sync destroy error',
            this.enableFormElements,
        );

        // When model added to collection; add file item
        this.listenTo(this.collection, 'add', this.addFileItem);
    }

    render() {
        console.debug('ModalFilesView#render');

        super.render({
            size: 'xl',
        }).modalContent.innerHTML = template({
            title: this.title,
        });

        this.modalTable = this.el.querySelector('table tbody');

        // If not allowed to write deal files; disable form elements
        if (!Session.isAllowed('phnx:deals.files:w')) {
            this.disableFormElements();
        }

        return this;
    }

    startSpinner() {
        console.debug('ModalFilesView#startSpinner');

        if (this.modalTable instanceof Element) {
            this.spinner.spin(this.modalTable);
        } else {
            console.warn(
                'Unable to start spinner as modal has not yet rendered',
            );
        }
    }

    stopSpinner() {
        console.debug('ModalFilesView#stopSpinner');

        this.spinner.stop();
    }

    disableFormElements() {
        this.el
            .querySelectorAll('input,select,textarea,button')
            .forEach((el) => (el.disabled = true));
    }

    enableFormElements() {
        // If allowed to write to deal files; enable form elements
        if (Session.isAllowed('phnx:deals.files:w')) {
            this.el
                .querySelectorAll('input,select,textarea,button')
                .forEach((el) => (el.disabled = false));
        }
    }

    /**
     * @param {Backbone.Model} model
     */
    addFileItem(model) {
        console.debug('ModalFilesView#addFileItem');

        // Create file item view with model
        const fileItem = new DealFilesItemView({
            model,
        });

        // Render view and append to file list
        this.modalTable.append(fileItem.render().el);
    }

    handleUploadClick() {
        console.debug('ModalFilesView#handleUploadClick');

        // Trigger click on file upload input
        this.el.querySelector('.file-selector').click();
    }

    /**
     * @param {JQuery.Event} $e
     */
    uploadFile($e) {
        console.debug('ModalFilesView#uploadFile');

        const files = $e.currentTarget.files;
        const uploadStatusContainer = this.$el.find('.cntr-upload-status');

        // Create file upload queue with handler
        const q = async.queue((file, callback) => {
            // Upload file
            /**
             * Moving forward, file uploader could either connect to a collection, or only connect to the file upload API.
             * This would negate the need for extra parameters, like deal_id
             */
            uploader(
                file,
                {
                    method: 'PUT',
                    url:
                        this.collection.url() +
                        '/' +
                        encodeURIComponent(file.name),
                },
                {
                    uploadStatusContainer: uploadStatusContainer,
                },
            )
                .then((response) => {
                    // Add new file model to collection using response data
                    this.collection.add(response);
                })
                .then(callback)
                .catch(callback);
        }, 1);

        // Loop through the FileList
        for (const file of files) {
            // Add file to queue
            q.push(file);
        }

        // Clear file selector
        $e.currentTarget.value = '';
    }
}
