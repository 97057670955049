module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3">Results</h2>\n\n';
 if (results_count === 0) { ;
__p += '\n  <p>No people match your search query.</p>\n';
 } else { ;
__p += '\n<table class="table table-sm table-striped">\n  <caption class="text-right"><span data-outlet="row-count">' +
((__t = ( results_count )) == null ? '' : __t) +
'</span> result(s)</caption>\n  <thead>\n    <tr>\n      <th>ID</th>\n      <th>Name</th>\n      <th>Division</th>\n      <th>Type</th>\n      <th class="clickable" data-col="assigned_date">Assigned Date <i class="fa fa-sort ml-1"></i></th>\n      <th class="clickable" data-col="alert_count">Alerts <i class="fa fa-sort ml-1"></i></th>\n      <th class="clickable" data-col="inquiry_count">Inquiries <i class="fa fa-sort ml-1"></i></th>\n      <th class="clickable" data-col="last_inquiry">Last Inquiry <i class="fa fa-sort ml-1"></i></th>\n      <th class="clickable" data-col="last_page_view">Last Page View <i class="fa fa-sort ml-1"></i></th>\n      <th></th>\n    </tr>\n  </thead>\n  <tbody id="assignment-results"></tbody>\n</table>\n';
 } ;


}
return __p
};
