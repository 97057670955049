module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal-header">\n    <h5 class="modal-title">' +
((__t = ( title )) == null ? '' : __t) +
'</h5>\n\n    <button type="button" class="close" data-dismiss="modal">\n        <span aria-hidden="true">&times;</span>\n    </button>\n</div>\n\n<table class="table table-sm file-list">\n    <thead>\n        <tr>\n            <td></td>\n            <td>Name</td>\n            <td>Size</td>\n            <td>Uploaded</td>\n            <td>Uploaded By</td>\n            <td></td>\n        </tr>\n    </thead>\n    <tbody></tbody>\n</table>\n\n<form class="modal-body">\n    <fieldset>\n        <div class="form-group row">\n            <label class="col-2 col-form-label">File Upload</label>\n            <div class="col-auto">\n                <button type="button" class="btn-file-upload btn btn-info">\n                    <i class="fa fa-upload" aria-hidden="true"></i>\n                    Choose...\n                </button>\n                <input class="file-selector d-none" type="file" multiple>\n                <span class="small ml-3">Size limit: 25MB per file</span>\n            </div>\n        </div>\n    </fieldset>\n    <div class="cntr-upload-status"></div>\n</form>';

}
return __p
};
