import Spinner from '@/js/app/ui/views/spinner';
import template from './templates/modal_simple.html';
import ModalView from '@/js/app/generic/modal';

export default class SimpleModalView extends ModalView {
    preinitialize(options) {
        super.preinitialize();

        this.spinner = new Spinner();

        this.modalTitle = options.modalTitle;
        this.size = options.size;
        this.centered = options.centered || false;
        this.scrollable = options.scrollable || false;
    }

    render() {
        super.render({
            size: this.size,
            centered: this.centered,
            scrollable: this.scrollable,
        }).modalContent.innerHTML = template({
            modalTitle: this.modalTitle,
        });

        this.modalBody = this.el.querySelector('.modal-body');

        return this;
    }

    startSpinner() {
        console.debug('Modal#startSpinner');

        if (this.modalBody instanceof Element) {
            this.spinner.spin(this.modalBody);
        } else {
            console.warn(
                'Unable to start spinner as modal has not yet rendered',
            );
        }
    }

    stopSpinner() {
        console.debug('Modal#stopSpinner');

        this.spinner.stop();
    }
}
