module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<tr class="daicho-row">\n  <td>\n    <a href="' +
((__t = ( Formatter.deal_edit_url(result) )) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( result.id )) == null ? '' : __t) +
'</a>\n  </td>\n  <td class="text-center">\n    ';
 if (result.cover_photo_image) { ;
__p += '\n    <a class="lightbox" href="' +
((__t = ( result.cover_photo_image )) == null ? '' : __t) +
'?w=1140&h=641&nc&ne&f=webp">\n      <img src="' +
((__t = ( result.cover_photo_image )) == null ? '' : __t) +
'?w=100&h=77" loading="lazy" class="mb-2">\n    </a>\n    ';
 } ;
__p += '\n  </td>\n  <td>\n    <p class="mb-2">\n      <a href="' +
((__t = ( Formatter.property_edit_url(result) )) == null ? '' : __t) +
'" target="_blank">\n        ' +
((__t = ( result.property_name )) == null ? '' : __t) +
'\n\n        ';
 if (result.property_unit) { ;
__p += '\n        #' +
((__t = ( result.property_unit )) == null ? '' : __t) +
'\n        ';
 } ;
__p += '\n      </a>\n    </p>\n    <p class="mb-0">' +
((__t = ( result.property_address )) == null ? '' : __t) +
'</p>\n  </td>\n  <td>' +
((__t = ( result.property_type )) == null ? '' : __t) +
'</td>\n  ';
 if (result.agent) { ;
__p += '\n  <td style="width: fit-content;">' +
((__t = ( result.agent.last_name )) == null ? '' : __t) +
' ' +
((__t = ( result.agent.first_name )) == null ? '' : __t) +
'</td>\n  ';
 } else { ;
__p += '\n  <td>No Agent</td>\n  ';
 } ;
__p += '\n  ';
 if (type === 'sales') { ;
__p += '\n  <td>' +
((__t = ( numeral(result.property_price / 10000).format('0,0') )) == null ? '' : __t) +
'</td>\n  <td>' +
((__t = ( numeral(result.brokerage / 10000).format('0,0') )) == null ? '' : __t) +
'</td>\n  ';
 } else if (type === 'rent') { ;
__p += '\n  <td>' +
((__t = ( numeral(result.property_rent).format('0,0') )) == null ? '' : __t) +
'</td>\n  <td>' +
((__t = ( numeral(result.brokerage).format('0,0') )) == null ? '' : __t) +
'</td>\n  ';
 } ;
__p += '\n  <td class="text-nowrap">' +
((__t = ( Formatter.format_date(result.contract_date) )) == null ? '' : __t) +
'</td>\n  <td class="text-nowrap">\n    <div class="d-flex flex-column">\n      <div>\n        ';
 if (result.file_count > 0) { ;
__p += '\n        <span class="badge badge-success">' +
((__t = ( result.file_count )) == null ? '' : __t) +
' File(s)</span>\n        ';
 } else { ;
__p += '\n        <span class="badge badge-danger">No Files</span>\n        ';
 } ;
__p += '\n      </div>\n\n      <div>\n        ';
 if (result.revenue_count > 0) { ;
__p += '\n        <span class="badge badge-success">Revenue</span>\n        ';
 } else { ;
__p += '\n        <span class="badge badge-danger">No Revenue</span>\n        ';
 } ;
__p += '\n      </div>\n\n      <div>\n        ';
 if (result.approved > 0) { ;
__p += '\n        <span class="badge badge-success">Approved</span>\n        ';
 } else { ;
__p += '\n        <span class="badge badge-danger">Not Approved</span>\n        ';
 } ;
__p += '\n      </div>\n    </div>\n  </td>\n</tr>';

}
return __p
};
