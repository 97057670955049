import { Events } from 'backbone';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';

export default class ReportDaichoResultsCollection {
    constructor() {
        _.extend(this, Events);
        this.data = {
            results: [],
            total_brokerage: 0,
            total_price: 0,
            total_rent: 0,
        };
    }

    fetch() {
        this.trigger('request');

        OAuth2Client.ajax(
            _.extend({
                url: `${config.api.url}reports/daicho/${this.financialYear}?type=${this.type}`,
                dataType: 'json',
            }),
        ).then((response) => {
            this.data = response;

            // Add cover photo image URL
            this.data.results = this.data.results.map((row) => {
                row.cover_photo_image = row.property_image
                    ? `${config.image.url}b/${row.building_id}/${row.property_image}`
                    : null;
                return row;
            });

            this.trigger('change');

            this.trigger('sync');
        });
    }

    toJSON() {
        return this.data;
    }

    sort(key, order) {
        /** @todo Move into a parent class or library function? */
        this.data.results = this.data.results.sort((a, b) => {
            if (a[key] === b[key]) {
                return 0;
            } else {
                if (order === 'asc') {
                    return a[key] > b[key] ? 1 : -1;
                } else {
                    return a[key] < b[key] ? 1 : -1;
                }
            }
        });
    }
}
