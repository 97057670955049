import BloodhoundCompanyDataset from '@/js/app/bloodhound/datasets/company';
import BloodhoundStaffDataset from '@/js/app/bloodhound/datasets/staff';
import ChangelogsCollection from '@/js/app/changelog/collections/changelogs';
import ChangelogsView from '@/js/app/changelog/views/changelogs';
import config from '@/js/app/config';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import PeopleCollection from '@/js/app/people/collections/people';
import Session from '@/js/app/session';
import StaffCollection from '@/js/app/staff/collections/staff';
import typeaheadCompanyDatasource from '@/js/app/typeahead/datasources/company';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasources/individual';
import {
    TypeaheadCompanyDefaults,
    TypeaheadStaffDefaults,
} from '@/js/app/typeahead/defaults';
import UiAgentPMNameSelectorView from '@/js/app/ui/views/agent-pm-name-selector';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import populateForm from '@/js/libs/populate-form';
import { getFieldValue } from '@/js/libs/view-utils';
import { history, View } from 'backbone';
import moment from 'moment';
import UnitAutomatchCollection from '../collections/unit-automatch';
import UnitFileCollection from '../collections/unit-files';
import UnitImageCollection from '../collections/unit-images';
import template from '../templates/edit_unit.html';
import templateOverviewImage from '../templates/overview_image.html';
import PropertyEditMediaView from './edit-media';
import ModalPropertyEditAutomatchPeopleView from './modal-automatch-clients';
import ModalFilesView from './modal-files';

export default class PropertyEditUnitView extends View {
    #mode = 'read';
    #tabs = {};

    preinitialize(options) {
        this.events = {
            'typeahead:select #cntr-rec_by input[type="text"]':
                this.typeaheadRecBySelected,
            'click #cntr-rec_by [data-action="delete"]':
                this.handleRecByDeleteClick,
            'typeahead:select #cntr-agent_company_1 input[type="text"]':
                this.typeaheadAgentCompanySelected,
            'click #cntr-agent_company_1 [data-action="delete"]':
                this.handleAgentCompanyDeleteClick,
            'typeahead:select #cntr-agent_company_2 input[type="text"]':
                this.typeaheadAgentCompanySelected,
            'click #cntr-agent_company_2 [data-action="delete"]':
                this.handleAgentCompanyDeleteClick,
            'typeahead:select #cntr-agent_company_3 input[type="text"]':
                this.typeaheadAgentCompanySelected,
            'click #cntr-agent_company_3 [data-action="delete"]':
                this.handleAgentCompanyDeleteClick,
            'typeahead:select #cntr-pm_company input[type="text"]':
                this.typeaheadPMCompanySelected,
            'click #cntr-pm_company [data-action="delete"]':
                this.handlePMCompanyDeleteClick,
            'change form': this.handleChangeForm,
            'change textarea[name],select[name],input[type!="file"][name]':
                this.updateModel,
            'click button[data-action="delete"]': this.handleUnitDeleteClick,
            'click button[data-action="force-update"]':
                this.handleUnitForceUpdateClick,
            'click button[data-action="copy"]': this.handleCopyClick,
            'click button[data-action="automatch"]': this.handleAutomatchClick,
            'click button[data-action="files"]': this.handleFilesClick,
            'click button[data-action="changelog"]': this.handleChangelogClick,
            'click button[data-action="edit"]': this.handleEditClick,
            'click button[data-action="cancel"]': this.handleCancelClick,
            'click button[data-action="save"]': this.handleSaveClick,
        };

        this.query = options.query;
        this.structure = options.structure;
        this.location = options.location;

        this.subviews = {
            recBy: new UiTypeaheadView({
                options: TypeaheadStaffDefaults,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundStaffDataset),
                ],
                formatter: Formatter.getName,
            }),
            agentCompany: [],
            agentName: [],
            pmCompany: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                formatter: Formatter.getCompanyName,
                urlGenerator: Formatter.getCompanyLink,
            }),
            pmName: new UiAgentPMNameSelectorView({
                collection: new PeopleCollection(),
                name: 'pm_name_id',
            }),
            mediaTab: new PropertyEditMediaView({
                collection: new UnitImageCollection(
                    options.model.get('images'),
                ),
                model: options.model,
                structure_type: 'unit',
            }),
            filesModal: new ModalFilesView({
                title: 'Unit Files',
                collection: new UnitFileCollection(),
            }),
            automatchModal: new ModalPropertyEditAutomatchPeopleView({
                collection: new UnitAutomatchCollection(null, {
                    unit_id: options.model.get('id'),
                }),
            }),
        };

        this.subviews.agentCompany[1] = new UiTypeaheadView({
            options: TypeaheadCompanyDefaults,
            datasets: [typeaheadCompanyDatasource(BloodhoundCompanyDataset)],
            formatter: Formatter.getCompanyName,
            urlGenerator: Formatter.getCompanyLink,
        });
        this.subviews.agentCompany[2] = new UiTypeaheadView({
            options: TypeaheadCompanyDefaults,
            datasets: [typeaheadCompanyDatasource(BloodhoundCompanyDataset)],
            formatter: Formatter.getCompanyName,
            urlGenerator: Formatter.getCompanyLink,
        });
        this.subviews.agentCompany[3] = new UiTypeaheadView({
            options: TypeaheadCompanyDefaults,
            datasets: [typeaheadCompanyDatasource(BloodhoundCompanyDataset)],
            formatter: Formatter.getCompanyName,
            urlGenerator: Formatter.getCompanyLink,
        });
        this.subviews.agentName[1] = new UiAgentPMNameSelectorView({
            collection: new PeopleCollection(),
            name: 'agent_name_1_id',
        });
        this.subviews.agentName[2] = new UiAgentPMNameSelectorView({
            collection: new PeopleCollection(),
            name: 'agent_name_2_id',
        });
        this.subviews.agentName[3] = new UiAgentPMNameSelectorView({
            collection: new PeopleCollection(),
            name: 'agent_name_3_id',
        });
    }

    initialize() {
        _.bindAll(
            this,
            'render',
            'setFieldTabInvalid',
            'resetCustomValidation',
        );

        // When model changes, update fields
        this.listenTo(this.model, 'change:room_no', this.updateRoomNo);
        this.listenTo(
            this.model,
            'change:published change:published_ja',
            this.handlePublishedChange,
        );
        this.listenTo(this.model, 'change:price', this.handlePriceChange);
        this.listenTo(this.model, 'change:updated_at', this.updateLastUpdated);

        // When model changes, update outlets
        this.listenTo(this.model, 'change', this.updateOutlets);
    }

    render() {
        console.debug('PropertyEditUnitView#render');

        const property = this.model.toJSON();
        const structure = this.structure.toJSON();
        const location = this.location.toJSON();

        // Generate print and public URLs
        const urls = {
            seo: {
                en: Formatter.unit_seo_url(
                    location,
                    structure,
                    property,
                    Formatter.determineType(property),
                    'en',
                ),
                ja: Formatter.unit_seo_url(
                    location,
                    structure,
                    property,
                    Formatter.determineType(property),
                    'ja',
                ),
            },
            print: {
                en: Formatter.unit_print_url(
                    location,
                    structure,
                    property,
                    Formatter.determineType(property),
                    'en',
                ),
                ja: Formatter.unit_print_url(
                    location,
                    structure,
                    property,
                    Formatter.determineType(property),
                    'ja',
                ),
            },
        };

        // Set images in collection
        this.subviews.mediaTab.collection.reset(this.model.get('images'));

        // Render template
        this.el.innerHTML = template({
            propertyId: Formatter.propertyId(property, this.query),
            p: property,
            last_updated_at: Formatter.timestamp(property.updated_at),
            urls: urls,
            isPeopleReadAllowed: Session.isAllowed('phnx:people:r'),
            isPropertyDeleteAllowed: Session.isAllowed('phnx:properties:d'),
        });

        // Define tabs
        this.#tabs.details = this.el.querySelector('#tab-unit-basics');
        this.#tabs.fees = this.el.querySelector('#tab-unit-fees');
        this.#tabs.features = this.el.querySelector('#tab-unit-features');
        this.#tabs.campaigns = this.el.querySelector('#tab-unit-campaigns');
        this.#tabs.admin = this.el.querySelector('#tab-unit-admin');
        this.#tabs.pm = this.el.querySelector('#tab-unit-pm');

        this.toggleSiteLinks();
        this.togglePrintLinks();

        if (property.structure_type === 'unit') {
            // Render overview images
            this.el.querySelector(
                '[data-outlet="cover-photo-image"]',
            ).innerHTML = templateOverviewImage({
                baseUrl: config.image.url + 'u/' + this.model.get('id'),
                imageUrl: `${Formatter.getCoverPhotoFilename(
                    this.model.get('images'),
                )}`,
            });

            this.el.querySelector('[data-outlet="floorplan-image"]').innerHTML =
                templateOverviewImage({
                    baseUrl: config.image.url + 'u/' + this.model.get('id'),
                    imageUrl: `${Formatter.getFloorplanFilename(
                        this.model.get('images'),
                    )}`,
                });

            // Setup lightbox
            this.$el.find('a.lightbox').fancybox({
                margin: 30,
            });
        }

        // Render typeahead subviews
        this.$el.find('#cntr-rec_by').html(this.subviews.recBy.render().el);
        this.$el
            .find('#cntr-pm_company')
            .html(this.subviews.pmCompany.render().el);
        this.$el
            .find('#cntr-agent_company_1')
            .html(this.subviews.agentCompany[1].render().el);
        this.$el
            .find('#cntr-agent_company_2')
            .html(this.subviews.agentCompany[2].render().el);
        this.$el
            .find('#cntr-agent_company_3')
            .html(this.subviews.agentCompany[3].render().el);

        // Render agents and PM name selectors at specific points in DOM
        this.subviews.pmName.setValue(this.model.get('pm_name_id'));
        this.subviews.pmName
            .setElement(this.el.querySelector('#field-pm_name'))
            .render();
        this.subviews.agentName[1].setValue(this.model.get('agent_name_1_id'));
        this.subviews.agentName[1]
            .setElement(this.el.querySelector('#field-agent_name_1'))
            .render();
        this.subviews.agentName[2].setValue(this.model.get('agent_name_2_id'));
        this.subviews.agentName[2]
            .setElement(this.el.querySelector('#field-agent_name_2'))
            .render();
        this.subviews.agentName[3].setValue(this.model.get('agent_name_3_id'));
        this.subviews.agentName[3]
            .setElement(this.el.querySelector('#field-agent_name_3'))
            .render();

        // Fetch agent and PM staff names for each agent and PM company
        this.subviews.pmName.fetch(this.model.get('pm_company_id'));
        this.subviews.agentName[1].fetch(this.model.get('agent_company_1_id'));
        this.subviews.agentName[2].fetch(this.model.get('agent_company_2_id'));
        this.subviews.agentName[3].fetch(this.model.get('agent_company_3_id'));

        // Assigned staff selectors
        const staffCollection1 = new StaffCollection();
        if (this.model.get('assigned_staff_1')) {
            staffCollection1.add(this.model.get('assigned_staff_1'));
        }
        const assignedStaff1 = new UiIndividualSelectorView({
            id: 'field-assigned_staff_1',
            collection: staffCollection1,
            attributes: {
                name: 'assigned_staff_1_id',
            },
            selected: this.model.get('assigned_staff_1_id'),
        });
        this.$el.find('#cntr-assigned_staff_1').html(assignedStaff1.el);

        const staffCollection2 = new StaffCollection();
        if (this.model.get('assigned_staff_2')) {
            staffCollection2.add(this.model.get('assigned_staff_2'));
        }
        const assignedStaff2 = new UiIndividualSelectorView({
            id: 'field-assigned_staff_2',
            collection: staffCollection2,
            attributes: {
                name: 'assigned_staff_2_id',
            },
            selected: this.model.get('assigned_staff_2_id'),
        });
        this.$el.find('#cntr-assigned_staff_2').html(assignedStaff2.el);

        // Fetch staff
        staffCollection1.fetch({
            data: {
                active: 1,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });
        staffCollection2.fetch({
            data: {
                active: 1,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });

        // Render tabs
        this.subviews.mediaTab
            .setElement(this.el.querySelector('#tab-unit-media'))
            .render();

        this.#initializeReadEditMode();

        this.updateOutlets();

        return this;
    }

    updateModel($e) {
        console.debug('PropertyEditUnitView#updateModel');

        const field = $e.currentTarget;

        // If no name, return
        if (field.name === '') {
            return;
        }

        // If the field is a partial data field or is NOT an update field; return
        if (
            field.dataset.partial === 'true' ||
            field.dataset.update === 'false'
        ) {
            return;
        }

        const value = getFieldValue(this.el, field);

        this.model.set(field.name, value);
    }

    switchReadMode() {
        this.#mode = 'read';

        this.#initializeReadEditMode();

        // Remove was-validated class from form
        const form = this.el.querySelector('form');
        form.classList.remove('was-validated');

        this.#disablePromptBeforeUnload();
    }

    switchEditMode() {
        console.debug('PropertyEditUnitView#switchEditMode');

        // Snapshot model
        this.model.snapshot();

        this.#mode = 'edit';

        this.#initializeReadEditMode();

        this.#enablePromptBeforeUnload();
    }

    promptBeforeUnload(e) {
        // Prevent default (modern browsers)
        e.preventDefault();

        // Set return value (legacy browsers)
        e.returnValue = true;
    }

    #initializeReadEditMode() {
        if (this.#mode === 'read') {
            // Loop over each tab
            for (const [, tab] of Object.entries(this.#tabs)) {
                // Disable fields in tab
                tab.querySelectorAll('input,select,textarea').forEach(
                    (el) => (el.disabled = true),
                );
            }

            // Show edit button
            this.el
                .querySelector('[data-action="edit"]')
                .classList.remove('d-none');

            // Hide cancel and save buttons
            this.el
                .querySelectorAll('[data-action="cancel"],[data-action="save"]')
                .forEach((el) => el.classList.add('d-none'));

            // Switch subviews into read mode
            this.subviews.recBy.switchReadMode();
            this.subviews.pmCompany.switchReadMode();
            this.subviews.agentCompany[1].switchReadMode();
            this.subviews.agentCompany[2].switchReadMode();
            this.subviews.agentCompany[3].switchReadMode();
            this.subviews.mediaTab.switchReadMode();
        } else if (this.#mode === 'edit') {
            // Loop over each tab
            for (const [, tab] of Object.entries(this.#tabs)) {
                // Enable fields in tab
                tab.querySelectorAll('input,select,textarea').forEach(
                    (el) => (el.disabled = false),
                );
            }

            // Hide edit button
            this.el
                .querySelector('[data-action="edit"]')
                .classList.add('d-none');

            // Hide cancel and save buttons
            this.el
                .querySelectorAll('[data-action="cancel"],[data-action="save"]')
                .forEach((el) => el.classList.remove('d-none'));

            // Switch subviews into edit mode
            this.subviews.recBy.switchEditMode();
            this.subviews.pmCompany.switchEditMode();
            this.subviews.agentCompany[1].switchEditMode();
            this.subviews.agentCompany[2].switchEditMode();
            this.subviews.agentCompany[3].switchEditMode();
            this.subviews.mediaTab.switchEditMode();
        }
    }

    #disablePromptBeforeUnload() {
        // Remove prompt before unload
        window.removeEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload--;
    }

    #enablePromptBeforeUnload() {
        // Add prompt before unload
        window.addEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload++;
    }

    updateOutlets() {
        console.debug('PropertyEditUnitView#updateOutlets');

        const changes = this.model.changedAttributes();

        console.debug('PropertyEditUnitView#updateOutlets: Changes', changes);

        // Populate form fields
        populateForm(this.el, changes);

        // Populate rec by field
        if (_.has(changes, 'rec_by')) {
            this.subviews.recBy.setDatum(this.model.get('rec_by'));
        }

        // Populate agent and PM company fields
        if (_.has(changes, 'agent_company_1')) {
            this.populateAgentPMCompanyField(
                this.subviews.agentCompany[1],
                'agent_company_1',
            );
        }
        if (_.has(changes, 'agent_company_2')) {
            this.populateAgentPMCompanyField(
                this.subviews.agentCompany[2],
                'agent_company_2',
            );
        }
        if (_.has(changes, 'agent_company_3')) {
            this.populateAgentPMCompanyField(
                this.subviews.agentCompany[3],
                'agent_company_3',
            );
        }
        if (_.has(changes, 'pm_company')) {
            this.populateAgentPMCompanyField(
                this.subviews.pmCompany,
                'pm_company',
            );
        }
    }

    handleChangeForm($e) {
        console.debug('PropertyEditUnitView#handleChangeForm');

        if ($e instanceof Object) {
            this.resetCustomValidation($e.target);
        }
    }

    resetCustomValidation(fieldEl) {
        console.debug('PropertyEditUnitView#resetValidation');

        // Reset invalid feedback slot
        const errorFeedback =
            fieldEl.parentElement.querySelector('.invalid-feedback');
        if (errorFeedback) {
            errorFeedback.innerText = '';
        }

        // Reset custom validity
        fieldEl.setCustomValidity('');

        // If field's tab-pane has no more invalid valids inside
        const invalidFields = this.el.querySelectorAll('.tab-pane :invalid');
        if (invalidFields.length === 0) {
            this.setFieldTabValid(fieldEl);
        }
    }

    save() {
        const form = this.el.querySelector('form');

        // Add was-validated class to form
        form.classList.add('was-validated');

        // Get invalid fields
        const invalidFields = this.el.querySelectorAll('form :invalid');

        // Reset custom validation on all invalid fields (otherwise won't attempt to perform server side validation)
        invalidFields.forEach(this.resetCustomValidation);

        this.#hideFormValidationMessage();

        if (form.checkValidity() === false) {
            this.#showFormValidationMessage();

            // Set field's tab to invalid
            invalidFields.forEach(this.setFieldTabInvalid);

            return;
        }

        // Set images on model
        this.model.set('images', this.subviews.mediaTab.collection.toJSON());

        let payload;
        if (this.model.isNew()) {
            // Use entire model contents
            payload = this.model.toJSON();
        } else {
            // Get model changes since last snapshot
            payload = this.model.changedAttributesSinceSnapshot();
        }

        // If no changes; immediately switch to read mode
        if (_.isEmpty(payload)) {
            this.switchReadMode();
        } else {
            // Save new value
            this.model
                .save(payload, { patch: true, wait: true })
                .then((response) => {
                    if (response.id) {
                        this.#disablePromptBeforeUnload();

                        // Navigate to edit property
                        history.navigate(
                            'property/edit/' +
                                response.building_id +
                                '.0/' +
                                response.id,
                            { trigger: true },
                        );
                    } else {
                        this.switchReadMode();
                    }
                })
                .catch(({ responseJSON }) => {
                    // If type of error is App\Exceptions\Validation
                    if (responseJSON.type === 'App\\Exceptions\\Validation') {
                        for (const field of responseJSON.fields) {
                            const fieldEl = this.el.querySelector(
                                `[name="${field.name}"]`,
                            );

                            // Set validation error in invalid feedback slot
                            const errorFeedback =
                                fieldEl.parentElement.querySelector(
                                    '.invalid-feedback',
                                );
                            if (errorFeedback) {
                                errorFeedback.innerText = field.message;
                            }

                            // Set validation error on fields
                            fieldEl.setCustomValidity(field.message);

                            this.setFieldTabInvalid(fieldEl);
                        }

                        this.#showFormValidationMessage();
                    }
                });
        }
    }

    setFieldTabValid(fieldEl) {
        // Get tab pane
        const tabPaneEl = fieldEl.closest('.tab-pane');

        // Get tab
        const tabEl = this.el.querySelector(`[href="#${tabPaneEl.id}"]`);

        // Remove class to represent invalid
        tabEl.classList.remove('text-danger');
    }

    setFieldTabInvalid(fieldEl) {
        // Get tab pane
        const tabPaneEl = fieldEl.closest('.tab-pane');

        // Get tab
        const tabEl = this.el.querySelector(`[href="#${tabPaneEl.id}"]`);

        // Add class to represent invalid
        tabEl.classList.add('text-danger');
    }

    #showFormValidationMessage() {
        this.el
            .querySelector('[data-outlet="form-invalid"]')
            .classList.remove('d-none');
    }

    #hideFormValidationMessage() {
        this.el
            .querySelector('[data-outlet="form-invalid"]')
            .classList.add('d-none');
    }

    updateRoomNo(model) {
        // Update room no
        this.el.querySelector('[data-outlet="room_no"]').innerText =
            model.get('room_no');
    }

    updateLastUpdated(model) {
        // Update timestamp
        this.el.querySelector(
            '#update-info strong[data-outlet="updated_at"]',
        ).innerText = Formatter.timestamp(model.get('updated_at'));
    }

    handlePublishedChange() {
        this.toggleSiteLinks();
    }

    handlePriceChange() {
        this.toggleSiteLinks();
        this.togglePrintLinks();
    }

    toggleSiteLinks() {
        // If structure has a price
        if (Number(this.model.get('price')) > 0) {
            const siteLinksEn = this.el.querySelectorAll(
                '[data-site-links="en"]',
            );
            const siteLinksJa = this.el.querySelectorAll(
                '[data-site-links="ja"]',
            );

            // Toggle English site link
            if (Number(this.model.get('published')) === 1) {
                siteLinksEn.forEach((el) => el.classList.remove('d-none'));
            } else {
                siteLinksEn.forEach((el) => el.classList.add('d-none'));
            }

            // Toggle Japanese site link
            if (Number(this.model.get('published_ja')) === 1) {
                siteLinksJa.forEach((el) => el.classList.remove('d-none'));
            } else {
                siteLinksJa.forEach((el) => el.classList.add('d-none'));
            }
        } else {
            // Hide all site links
            this.el
                .querySelectorAll('[data-site-links]')
                .forEach((el) => el.classList.add('d-none'));
        }
    }

    togglePrintLinks() {
        // If structure has a price
        if (Number(this.model.get('price')) > 0) {
            // Show all print links
            this.el
                .querySelectorAll('[data-print-links]')
                .forEach((el) => el.classList.remove('d-none'));
        } else {
            // Hide all print links
            this.el
                .querySelectorAll('[data-print-links]')
                .forEach((el) => el.classList.add('d-none'));
        }
    }

    populateAgentPMCompanyField(typeaheadSubview, name) {
        const company = this.model.get(name);

        // If model contains object for given field name
        if (company) {
            // Set value of field to formatted company name
            typeaheadSubview.setDatum(company);

            // Show company phone number
            this.el.querySelector(`#outlet-${name}_telephone`).innerText =
                Formatter.getCompanyTel(company);
        } else {
            // Set field value to empty string (different from "clear", which will also enable)
            typeaheadSubview.setValue('');

            // Clear company phone number
            this.el.querySelector(`#outlet-${name}_telephone`).innerText = '';
        }
    }

    typeaheadRecBySelected($e, datum) {
        // Set staff ID in model
        this.model.set('rec_by_id', datum.id);
    }

    handleRecByDeleteClick($e) {
        $e.preventDefault();

        // Remove staff ID in model
        this.model.set('rec_by_id', null);
    }

    typeaheadAgentCompanySelected($e, datum) {
        // Get container index
        const index = Number(
            $($e.currentTarget).closest('[data-cntr-index]').data('cntrIndex'),
        );

        // Set company ID in model
        this.model.set(`agent_company_${index}_id`, datum.id);

        // Display company phone number
        this.el.querySelector(
            `#outlet-agent_company_${index}_telephone`,
        ).innerText = Formatter.getCompanyTel(datum);

        // Fetch agent names
        this.subviews.agentName[index].fetch(datum.id);
    }

    handleAgentCompanyDeleteClick($e) {
        $e.preventDefault();

        // Get container index
        const index = Number(
            $($e.currentTarget).closest('[data-cntr-index]').data('cntrIndex'),
        );

        // Remove agent company and name IDs in model
        this.model.set({
            [`agent_company_${index}_id`]: null,
            [`agent_name_${index}_id`]: null,
        });

        // Hide company phone number
        this.el.querySelector(
            `#outlet-agent_company_${index}_telephone`,
        ).innerText = '';

        // Reset agent names
        this.subviews.agentName[index].clear();
    }

    typeaheadPMCompanySelected($e, datum) {
        // Set company ID in model
        this.model.set(`pm_company_id`, datum.id);

        // Display company phone number
        this.el.querySelector('#outlet-pm_company_telephone').innerText =
            Formatter.getCompanyTel(datum);

        // Fetch agent names
        this.subviews.pmName.fetch(datum.id);
    }

    handlePMCompanyDeleteClick($e) {
        $e.preventDefault();

        // Remove company ID in model
        this.model.set('pm_company_id', null);

        // Hide company phone number
        this.el.querySelector('#outlet-pm_company_telephone').innerText = '';

        // Re-render PM names subview
        this.subviews.pmName.clear();
    }

    handleUnitDeleteClick() {
        if (
            confirm(
                'Are you sure you want to delete this unit? This cannot be undone!',
            )
        ) {
            this.model
                .destroy()
                .then(() => {
                    history.navigate('property/search', { trigger: true });
                })
                .catch((model, response) => {
                    alert(response.responseText);
                });
        }
    }

    handleUnitForceUpdateClick() {
        console.debug('PropertyEditUnitView#handleUnitForceUpdateClick');

        // Save updated_at as current time
        this.model.save(
            {
                updated_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            },
            {
                patch: true,
                wait: true,
            },
        );
    }

    handleFilesClick() {
        console.debug('PropertyEditUnitView#handleFilesClick');

        this.subviews.filesModal.render();

        // Set unit_id
        this.subviews.filesModal.collection.unit_id = this.model.id;

        this.subviews.filesModal.collection.fetch({
            data: {
                order: 'last_modified_at',
                include: ['last_modified_by'],
            },
        });
    }

    handleCopyClick($e) {
        console.debug('PropertyEditUnitView#handleCopyClick');

        $e.stopPropagation();

        if (confirm('Are you sure you want to copy this unit?')) {
            // Send COPY request for unit ID
            OAuth2Client.fetchJSON(`${config.api.url}units/${this.model.id}`, {
                method: 'COPY',
            })
                .then((response) => {
                    // Redirect to edit copied unit
                    history.navigate(
                        `property/edit/${response.building_id}.0/${response.id}`,
                        { trigger: true },
                    );
                })
                .catch((model, response) => {
                    alert(response.responseText);
                });
        }
    }

    handleAutomatchClick($e) {
        console.debug('PropertyEditUnitView#handleAutomatchClick');

        $e.stopPropagation();

        this.subviews.automatchModal.render();
    }

    handleChangelogClick(e) {
        e.stopPropagation();

        // Create modal view, with collection
        const changelogView = new ChangelogsView({
            collection: new ChangelogsCollection(),
        });

        // Fetch collection
        changelogView.collection.fetch({
            data: {
                resource_type: 'property',
                resource_id: this.model.get('id'),
            },
        });
    }

    handleEditClick(e) {
        console.debug('PropertyEditUnitView#handleEditClick');

        e.stopPropagation();

        this.switchEditMode();
    }

    handleCancelClick(e) {
        console.debug('PropertyEditUnitView#handleCancelClick');

        e.stopPropagation();

        this.model.restoreFromSnapshot();

        // Reset images
        this.subviews.mediaTab.collection.reset(this.model.get('images'));

        // Reset typeaheads
        this.subviews.recBy.setDatum(this.model.get('rec_by'));
        this.populateAgentPMCompanyField(
            this.subviews.agentCompany[1],
            'agent_company_1',
        );
        this.populateAgentPMCompanyField(
            this.subviews.agentCompany[2],
            'agent_company_2',
        );
        this.populateAgentPMCompanyField(
            this.subviews.agentCompany[3],
            'agent_company_3',
        );
        this.populateAgentPMCompanyField(this.subviews.pmCompany, 'pm_company');

        this.#hideFormValidationMessage();

        this.switchReadMode();
    }

    handleSaveClick(e) {
        console.debug('PropertyEditUnitView#handleSaveClick');

        e.stopPropagation();

        this.save();
    }
}
