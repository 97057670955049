module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<h2 class="h3 mt-0">Report Totals</h2>\n\n<table class="table table-sm">\n    <tbody>\n        <tr>\n            <td>Agent Split General Fees Total</td>\n            <td class="text-right">' +
((__t = ( numeral(general_total).format('0,0') )) == null ? '' : __t) +
'</td>\n        </tr>\n\n        <tr>\n            <td>Agent Split Buyers Fee Total</td>\n            <td class="text-right">' +
((__t = ( numeral(bf_total).format('0,0') )) == null ? '' : __t) +
'</td>\n        </tr>\n\n        <tr>\n            <td>Agent Split Sellers Fee Total</td>\n            <td class="text-right">' +
((__t = ( numeral(sf_total).format('0,0') )) == null ? '' : __t) +
'</td>\n        </tr>\n    </tbody>\n\n    <tfoot>\n        <tr class="total">\n            <th>Net</th>\n            <th class="text-right">' +
((__t = ( numeral(general_total + bf_total + sf_total).format('0,0') )) == null ? '' : __t) +
'</th>\n        </tr>\n    </tfoot>\n</table>\n';

}
return __p
};
