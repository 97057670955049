module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (_.isEmpty(changelogs)) { ;
__p += '\n<p class="text-center">No changelogs found.</p>\n';
 } else { ;
__p += '\n<table class="table table-sm table-responsive d-table">\n    <thead>\n        <tr>\n            <th>Timestamp</th>\n            <th>User</th>\n            <th>Field</th>\n            <th>Value</th>\n        </tr>\n    </thead>\n    <tbody>\n        ';
 changelogs.forEach((change) => { ;
__p += '\n        <tr>\n            <td>' +
((__t = ( change.timestamp.format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( change.author )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( change.field )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( change.value )) == null ? '' : __t) +
'</td>\n        </tr>\n        ';
 }) ;
__p += '\n    </tbody>\n</table>\n';
 } ;


}
return __p
};
