import SimpleModalView from '../../generic/modal-simple';
import template from '../templates/alert-criteria.html';

export default class PropertyAlertCriteriaModalView extends SimpleModalView {
    preinitialize() {
        super.preinitialize({
            modalTitle: 'Alert Criteria',
        });
    }

    render() {
        console.debug('PropertyAlertCriteriaModalView#render');

        super.render().modalBody.innerHTML = template({
            search_text: this.search_text,
        });
    }
}
