import ActivityCollection from '@/js/app/activity/collections/activities';
import DealCollection from '@/js/app/deal/collections/deals';
import { getCompanyName } from '@/js/app/formatter';
import InquiryCollection from '@/js/app/inquiry/collections/inquiries';
import PropertyAlertCollection from '@/js/app/property-alert/collections/property-alerts';
import PropertyListCollection from '@/js/app/property-list/collections/property-lists';
import PageViewsCollection from '@/js/app/property-page-views/collections/page-views';
import Session from '@/js/app/session';
import { View } from 'backbone';
import PeopleModel from '../models/people';
import PeopleSubscriptionsModel from '../models/subscriptions';
import template from '../templates/page-edit.html';
import PeopleEditView from '../views/edit';
import ModalActivityView from '../views/modal-activity';
import ModalPropertyAlertsView from '../views/modal-alerts';
import ModalDealsView from '../views/modal-deals';
import ModalInquiriesView from '../views/modal-inquiries';
import ModalPageViewsView from '../views/modal-page-views';
import ModalPropertyListsView from '../views/modal-property-lists';
import ModalSubscriptionsView from '../views/modal-subscriptions';

export default class PeopleEditPageView extends View {
    preinitialize(options) {
        this.className = 'container';

        this.events = {
            'click button[data-action="delete"]': this.handleDeleteClick,
            'click button[data-action="subscriptions"]':
                this.handleSubscriptionsClick,
            'click button[data-action="activity"]': this.handleActivityClick,
            'click button[data-action="alerts"]': this.handleAlertsClick,
            'click button[data-action="page-views"]': this.handlePageViewsClick,
            'click button[data-action="inquiries"]': this.handleInquiriesClick,
            'click button[data-action="property-lists"]':
                this.handlePropertyListsClick,
            'click button[data-action="deals"]': this.handleDealsClick,
        };

        this.model = new PeopleModel({
            id: options.personId,
        });

        this.subviews = {
            subscriptionsModal: new ModalSubscriptionsView({
                model: new PeopleSubscriptionsModel({
                    id: options.personId,
                }),
                peopleModel: this.model,
            }),
            activityModal: new ModalActivityView({
                collection: new ActivityCollection(),
            }),
            propertyAlertsModal: new ModalPropertyAlertsView({
                collection: new PropertyAlertCollection(),
            }),
            pageViewsModal: new ModalPageViewsView({
                collection: new PageViewsCollection({
                    addressbook_id: options.personId,
                }),
            }),
            inquiriesModal: new ModalInquiriesView({
                collection: new InquiryCollection(),
            }),
            propertyListsModal: new ModalPropertyListsView({
                collection: new PropertyListCollection(),
            }),
            dealsModal: new ModalDealsView({
                collection: new DealCollection(),
            }),
            edit: new PeopleEditView({
                model: this.model,
            }),
        };
    }

    initialize() {
        this.render();

        this.listenTo(
            this.model,
            'change:first_name change:last_name',
            this.handleNameChange,
        );
        this.listenTo(this.model, 'change:company', this.handleCompanyChange);
        this.listenTo(
            this.model,
            'change:is_tenant change:is_buyer change:is_seller change:is_owner',
            this.toggleButtons,
        );
        this.listenTo(
            this.model,
            'change:deleted_at',
            this.handleChangeDeleteAt,
        );
    }

    render() {
        console.debug('PeopleEditPageView#render');

        this.el.innerHTML = template({
            isDeleteAllowed: Session.isAllowed('phnx:people:d'),
        });

        this.$el.find('#cntr-edit').html(this.subviews.edit.render().el);

        return this;
    }

    fetchModels() {
        // Trigger fetches
        this.model.fetch({
            data: {
                include: ['company', 'assigned_staff'],
            },
            initialLoad: true,
        });
        // this.subviews.subscriptionsModal.model.fetch();
    }

    /**
     * @param {Backbone.Model} model
     */
    handleNameChange(model) {
        console.debug('PeopleEditPageView#handleNameChange');

        this.#changeName(model.get('last_name'), model.get('first_name'));
    }

    #changeName(lastName, firstName) {
        console.debug('PeopleEditPageView#changeName');

        this.el.querySelector('[data-outlet="last_name"]').innerText =
            lastName || '';
        this.el.querySelector('[data-outlet="first_name"]').innerText =
            firstName || '';
    }

    /**
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleCompanyChange(model, value) {
        console.debug('PeopleEditPageView#handleCompanyChange');

        this.changeCompany(value);
    }

    changeCompany(value) {
        console.debug('PeopleEditPageView#changeCompanyName');

        // Set company name
        this.el.querySelector('[data-outlet="company_name"]').innerText = value
            ? getCompanyName(value)
            : '';
    }

    /**
     * @param {Backbone.Model} model
     */
    toggleButtons(model) {
        console.debug('PeopleEditPageView#toggleButtons');

        // Show/hide tenant or buyer related buttons
        if (model.get('is_tenant') || model.get('is_buyer')) {
            this.el.querySelector('[data-action="property-lists"]').disabled =
                false;
        } else {
            this.el.querySelector('[data-action="property-lists"]').disabled =
                true;
        }
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleDeleteClick($e) {
        console.debug('PeopleEditPageView#handleDeleteClick');

        $e.stopPropagation();

        if (
            confirm(
                'Are you sure you want to delete this person? This cannot be undone!',
            )
        ) {
            this.model.destroy({
                success() {
                    history.navigate('clients/search', { trigger: true });
                },
                error(model, response) {
                    alert(response.responseText);
                },
            });
        }
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleSubscriptionsClick($e) {
        console.debug('PeopleEditPageView#handleSubscriptionsClick');

        $e.stopPropagation();

        // Show modal
        this.subviews.subscriptionsModal.show();

        // Trigger fetch on model
        this.subviews.subscriptionsModal.model.fetch();
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleActivityClick($e) {
        console.debug('PeopleEditPageView#handleActivityClick');

        $e.stopPropagation();

        // Show modal
        this.subviews.activityModal.show();

        // Trigger fetch on collection
        this.subviews.activityModal.collection.fetch({
            data: {
                addressbook_id: this.model.id,
                order: 'timestamp DESC',
                limit: 100,
                include: ['person'],
            },
        });
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleAlertsClick($e) {
        console.debug('PeopleEditPageView#handleAlertsClick');

        $e.stopPropagation();

        // Show modal
        this.subviews.propertyAlertsModal.show();

        // Trigger fetch on collection
        this.subviews.propertyAlertsModal.collection.fetch({
            data: {
                email_address: this.model.get('email'),
                include: ['search_text'],
            },
        });
    }

    /**
     * @param {JQuery.Event} $e
     */
    handlePageViewsClick($e) {
        console.debug('PeopleEditPageView#handlePageViewsClick');

        $e.stopPropagation();

        // Show modal
        this.subviews.pageViewsModal.show();

        // Trigger fetch on collection
        this.subviews.pageViewsModal.collection.fetch();
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleInquiriesClick($e) {
        console.debug('PeopleEditPageView#handleInquiriesClick');

        $e.stopPropagation();

        // Show modal
        this.subviews.inquiriesModal.show();

        // Trigger fetch on collection
        this.subviews.inquiriesModal.collection.fetch({
            data: {
                addressbook_id: this.model.id,
                include: ['property'],
            },
        });
    }

    /**
     * @param {JQuery.Event} $e
     */
    handlePropertyListsClick($e) {
        console.debug('PeopleEditPageView#handlePropertyListsClick');

        $e.stopPropagation();

        // Show modal
        this.subviews.propertyListsModal.show();

        // Trigger fetch on collection
        this.subviews.propertyListsModal.collection.fetch({
            data: {
                addressbook_id: this.model.id,
                include: ['created_by'],
            },
        });
    }

    /**
     * @param {JQuery.Event} $e
     */
    handleDealsClick($e) {
        console.debug('PeopleEditPageView#handleDealsClick');

        $e.stopPropagation();

        // Show modal
        this.subviews.dealsModal.show();

        // Trigger fetch on collection
        this.subviews.dealsModal.collection.fetch({
            data: {
                addressbook_id: this.model.id,
            },
        });
    }

    /**
     * @param {Backbone.Model} model
     * @param {any} value
     */
    handleChangeDeleteAt(model, value) {
        console.debug('PeopleEditPageView#handleChangeDeleteAt');

        const deletedAlert = this.el.querySelector(
            '[data-outlet="deleted-alert"]',
        );

        if (value === null) {
            deletedAlert.classList.add('d-none');
        } else {
            deletedAlert.classList.remove('d-none');
        }
    }
}
