module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal-header">\n    <h5 class="modal-title">' +
((__t = ( modalTitle )) == null ? '' : __t) +
'</h5>\n\n    <button type="button" class="close" data-dismiss="modal">\n        <span aria-hidden="true">&times;</span>\n    </button>\n</div>\n\n<div class="modal-body" style="min-height:80px"></div>';

}
return __p
};
