import { View } from 'backbone';
import moment from 'moment';
import template from '../templates/edit_alerts.html';

export default class PeopleEditAlertsView extends View {
    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('PeopleEditAlertsView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            alerts: this.collection.toJSON(),
            moment,
        });

        return this;
    }
}
