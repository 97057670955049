import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import { View } from 'backbone';
import moment from 'moment';
import template from '../templates/modal_files_item.html';

export default class PropertyEditFileItemView extends View {
    preinitialize() {
        this.tagName = 'tr';

        this.events = {
            'change .field-type': this.changeType,
            'click [data-action="download"]': this.download,
            'click [data-action="delete"]': this.delete,
        };
    }

    initialize() {
        // When model is destroyed or removed from collection: remove view
        this.listenTo(this.model, 'destroy remove', this.remove);

        // When model changes: render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('PropertyEditFileItemView#render');

        this.el.innerHTML = template({
            file: this.model.toJSON(),
            Formatter,
            moment,
        });

        return this;
    }

    download($e) {
        console.debug('PropertyEditFileItemView#download');

        $e.preventDefault();

        OAuth2Client.download(
            _.result(this.model, 'url'),
            {
                method: 'DOWNLOAD',
            },
            {
                filename: this.model.get('filename'),
            },
        );
    }

    delete($e) {
        console.debug('PropertyEditFileItemView#delete');

        $e.preventDefault();
        $e.stopPropagation();

        if (
            confirm(
                'Are you sure you want to delete this file? This cannot be undone!',
            )
        ) {
            // Destroy model
            this.model.destroy({
                wait: true,
            });
        }
    }
}
