import * as Formatter from '@/js/app/formatter';
import SimpleModalView from '@/js/app/generic/modal-simple';
import Preferences from '@/js/app/preferences';
import template from '../templates/modal_deals.html';

export default class ModalDealsView extends SimpleModalView {
    preinitialize() {
        super.preinitialize({
            modalTitle: 'Deals',
            size: 'xl',
        });

        this.events = {
            ...this.events,
            'click .modal-body a': this.handleLinkClick,
        };
    }

    initialize() {
        super.initialize();

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', this.startSpinner);

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', this.stopSpinner);

        // When collection syncs; render modal body
        this.listenTo(this.collection, 'sync', this.renderModalBody);
    }

    renderModalBody() {
        console.debug('ModalDealsView#renderModalBody');

        this.modalBody.innerHTML = template({
            deals: this.collection.toJSON(),
            Formatter,
            Preferences,
        });
    }

    handleLinkClick() {
        // Hide modal when link clicked
        this.hide();
    }
}
