module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (_.isEmpty(page_views)) { ;
__p += '\n<p class="text-center">Client has not viewed any pages.</p>\n';
 } else { ;
__p += '\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>Page Title</th>\n            <th>Country</th>\n            <th>Date Viewed</th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 page_views.forEach((p) => { ;
__p += '\n        <tr>\n            <td>\n                <a href="' +
((__t = ( p.page_url )) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( p.page_title ? p.page_title : 'Test' )) == null ? '' : __t) +
'</a>\n            </td>\n            <td>\n                <abbr title="' +
((__t = ( Formatter.countryName(p.country_code) )) == null ? '' : __t) +
'">' +
((__t = ( p.country_code || '-' )) == null ? '' : __t) +
'</abbr>\n            </td>\n            <td>\n                ' +
((__t = ( moment.utc(p.timestamp).local().format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'\n            </td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>\n';
 } ;


}
return __p
};
