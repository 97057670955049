module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="table table-sm">\n  <caption class="text-right">Total revenue in millions of yen</caption>\n  <thead>\n    <tr>\n      <th>ID</th>\n      <th></th>\n      <th>\n        物件名<br>\n        住所\n      </th>\n      <th class="clickable" data-col="property_type">建物の種類</th>\n      <th>担当</th>\n      <th class="clickable" data-col="contract_date">契約日</th>\n      ';
 if (type === 'sales' || type === 'investment') { ;
__p += '\n      <th class="clickable" data-col="settlement_date">決済日</th>\n      ';
 } ;
__p += '\n      ';
 if (type === 'sales' || type === 'investment') { ;
__p += '\n      <th class="clickable" data-col="property_price">金額 (円)</th>\n      ';
 } else if (type === 'rent') { ;
__p += '\n      <th class="clickable" data-col="property_rent">家賃 (円)</th>\n      ';
 } ;
__p += '\n      <th class="clickable" data-col="brokerage">Brokerage (円)</th>\n      <th></th>\n    </tr>\n  </thead>\n  <tbody id="results-body"></tbody>\n  <tfoot>\n    <tr class="daicho-row">\n      <td class="font-weight-bolder">Total</td>\n      <td class="text-right text-nowrap font-weight-bolder">' +
((__t = ( count_deals )) == null ? '' : __t) +
'</td>\n      ';
 if (type === 'sales' || type === 'investment') { ;
__p += '\n      <td colspan="5"></td>\n      <td class="text-right text-nowrap font-weight-bolder">' +
((__t = ( numeral(total_price / 1000000).format('0,0.0') )) == null ? '' : __t) +
'</td>\n      ';
 } else if (type === 'rent') { ;
__p += '\n      <td colspan="4"></td>\n      <td class="text-right text-nowrap font-weight-bolder">' +
((__t = ( numeral(total_rent / 1000000).format('0,0.0') )) == null ? '' : __t) +
'</td>\n      ';
 } ;
__p += '\n      <td class="text-right text-nowrap font-weight-bolder">' +
((__t = ( numeral(total_brokerage / 1000000).format('0,0.0') )) == null ? '' : __t) +
'\n      </td>\n      <td></td>\n    </tr>\n  </tfoot>\n</table>';

}
return __p
};
