module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<td class="icon">\n    <img src="/images/filetypes/' +
((__t = ( Formatter.mimeTypeToIcon(file.mime_type) )) == null ? '' : __t) +
'.svg" width="32" height="32">\n</td>\n<td><a href="#" data-action="download">' +
((__t = ( file.filename )) == null ? '' : __t) +
'</a></td>\n<td>' +
((__t = ( Formatter.filesize(file.size) )) == null ? '' : __t) +
'</td>\n<td>' +
((__t = ( moment.utc(file.last_modified_at).local().format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'</td>\n<td>' +
((__t = ( _.isObject(file.last_modified_by) ? Formatter.getName(file.last_modified_by) : '' )) == null ? '' : __t) +
'</td>\n<td>\n    ';
 if (isDeleteAllowed) {;
__p += '\n    <a href="#" data-action="delete">\n        <i class="fa fa-times fa-lg" style="color:#000000" aria-hidden="true"></i>\n    </a>\n    ';
 } ;
__p += '\n</td>\n';

}
return __p
};
