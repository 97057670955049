module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="table table-sm">\n    <thead>\n        <tr>\n            <th>Fees / Costs</th>\n            <th>Rev ID</th>\n            <th>Rev Date</th>\n            <th>Deal ID</th>\n            <th>契約日</th>\n            <th>Seller / Lessor</th>\n            <th>Buyer / Lessee</th>\n            <th>担当者</th>\n            <th>物件名</th>\n            <th>Type</th>\n            <th>Amount</th>\n            <th>Memo</th>\n        </tr>\n    </thead>\n\n    <tbody>\n    ';
 results.forEach((r) => { ;
__p += '\n        <tr>\n            <td>\n                <span class="fa-stack">\n                ';
 if (r.fee_paid) { ;
__p += '\n                    <i class="fa fa-circle fa-stack-2x" style="color: #333333;" aria-hidden="true"></i><i class="fa fa-check fa-stack-1x fa-inverse" aria-hidden="true"></i>\n                ';
 } else { ;
__p += '\n                    <i class="fa fa-circle fa-stack-2x" style="color: #a94442;" aria-hidden="true"></i><i class="fa fa-yen fa-stack-1x fa-inverse" aria-hidden="true"></i>\n                ';
 } ;
__p += '\n                </span>\n                <span class="fa-stack">\n                ';
 if (r.costs_paid) { ;
__p += '\n                    <i class="fa fa-circle fa-stack-2x" style="color: #333333;" aria-hidden="true"></i><i class="fa fa-check fa-stack-1x fa-inverse" aria-hidden="true"></i>\n                ';
 } else { ;
__p += '\n                    <i class="fa fa-circle fa-stack-2x" style="color: #a94442;" aria-hidden="true"></i><i class="fa fa-yen fa-stack-1x fa-inverse" aria-hidden="true"></i>\n                ';
 } ;
__p += '\n                </span>\n            </td>\n            <td class="text-nowrap">\n                <a href="' +
((__t = ( Formatter.rms_url(r.id) )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.revenueId(r.id) )) == null ? '' : __t) +
'</a>\n            </td>\n            <td class="text-nowrap">' +
((__t = ( r.date )) == null ? '' : __t) +
'</td>\n            <td class="text-nowrap">\n                ';
 if (r.deal) { ;
__p += '\n                <a href="#deals/edit/' +
((__t = ( r.deal.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.dealId(r.deal.id, r.deal.custom_id) )) == null ? '' : __t) +
'</a>\n                ';
 } ;
__p += '\n            </td>\n            <td class="text-nowrap">\n                ';
 if (r.deal) { ;
__p += '\n                    ' +
((__t = ( r.deal.contract_date )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n            </td>\n            <td>\n                ';
 if (r.deal) { ;
__p += '\n                    ';
 if (r.deal.buyer_type === 'individual') { ;
__p += '\n                        <a href="#people/edit/' +
((__t = ( r.deal.buyer_id )) == null ? '' : __t) +
'">' +
((__t = ( r.deal.buyer_name )) == null ? '' : __t) +
'</a>\n                    ';
 } else if (r.deal.buyer_type === 'company') { ;
__p += '\n                        <a href="#company/edit/' +
((__t = ( r.deal.buyer_id )) == null ? '' : __t) +
'">' +
((__t = ( r.deal_buyer_name )) == null ? '' : __t) +
'</a>\n                    ';
 } ;
__p += '\n                ';
 } ;
__p += '\n            </td>\n            <td>\n                ';
 if (r.deal) { ;
__p += '\n                    ';
 if (r.deal.seller_type === 'individual') { ;
__p += '\n                        <a href="#people/edit/' +
((__t = ( r.deal.seller_id )) == null ? '' : __t) +
'">' +
((__t = ( r.deal.seller_name )) == null ? '' : __t) +
'</a>\n                    ';
 } else if (r.deal.seller_type === 'company') { ;
__p += '\n                        <a href="#company/edit/' +
((__t = ( r.deal.seller_id )) == null ? '' : __t) +
'">' +
((__t = ( r.deal.seller_name )) == null ? '' : __t) +
'</a>\n                    ';
 } ;
__p += '\n                ';
 } ;
__p += '\n            </td>\n            <td>' +
((__t = ( r.assigned_to_name )) == null ? '' : __t) +
'</td>\n            <td>\n                ';
 if (r.deal) { ;
__p += '\n                    ' +
((__t = ( r.deal.property_name )) == null ? '' : __t) +
' #' +
((__t = ( r.deal.room_no )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n            </td>\n            <td>' +
((__t = ( Formatter.feeType(r.fee_type) )) == null ? '' : __t) +
'</td>\n            <td class="text-right">' +
((__t = ( numeral(r.amount).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td>\n                ';
 if (r.deal && !_.isEmpty(r.deal.memo)) { ;
__p += '\n                    <a href="#" rel="popover" title="Memo" data-content="' +
((__t = ( r.deal.memo )) == null ? '' : __t) +
'"><i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i></a>\n                ';
 } ;
__p += '\n            </td>\n        </tr>\n    ';
 }); ;
__p += '\n    </tbody>\n</table>\n';

}
return __p
};
