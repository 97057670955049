import { View } from 'backbone';
import DealEditView from '../views/edit';
import DealModel from '../models/deal';
import template from '../templates/page-edit.html';

export default class DealEditPageView extends View {
    preinitialize(options) {
        this.className = 'container';

        this.subviews = {
            edit: new DealEditView({
                model: new DealModel({
                    id: options.dealId,
                }),
            }),
        };
    }

    initialize() {
        this.render();

        this.subviews.edit.fetch();
    }

    render() {
        console.debug('DealEditPageView#render');

        this.el.innerHTML = template();

        this.$el.find('#container-edit').append(this.subviews.edit.el);
    }
}
