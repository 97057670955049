module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 search_text.forEach((criteria) => { ;
__p += '\n<p>' +
((__t = ( criteria )) == null ? '' : __t) +
'</p>\n';
 }) ;


}
return __p
};
