import SimpleModalView from '@/js/app/generic/modal-simple';
import PropertyAutomatchClientsView from './automatch-clients';

export default class ModalAutomatchClientsView extends SimpleModalView {
    preinitialize() {
        super.preinitialize({
            modalTitle: 'Automatch Clients',
            size: 'xl',
        });
    }

    initialize() {
        super.initialize();

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', this.startSpinner);

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', this.stopSpinner);

        // When collection syncs; render modal body
        this.listenTo(this.collection, 'sync', this.renderModalBody);
    }

    renderModalBody() {
        console.debug('ModalAutomatchClientsView#render');

        const view = new PropertyAutomatchClientsView({
            collection: this.collection,
        });

        // Render view and attach to modal body
        this.$el.find('.modal-body').html(view.render().el);
    }
}
