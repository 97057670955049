module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (_.isEmpty(inquiries)) { ;
__p += '\n<p class="text-center">Client has not made any enquiries.</p>\n';
 } else { ;
__p += '\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>Timestamp</th>\n            <th>Type</th>\n            <th>Property</th>\n            <th>Page / Message</th>\n            <th>Country</th>\n            <th></th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 inquiries.forEach((inquiry) => { ;
__p += '\n        <tr>\n            <td>\n                ' +
((__t = ( moment.utc(inquiry.created_at).local().format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'\n            </td>\n            <td>\n                ' +
((__t = ( TextHelper._(inquiry.inquiry_type, 'inquiry_type') )) == null ? '' : __t) +
'\n            </td>\n            <td>\n                ';
 if (inquiry.property) { ;
__p += '\n                <a\n                   href="' +
((__t = ( Formatter.property_edit_url(inquiry.property) )) == null ? '' : __t) +
'">' +
((__t = ( inquiry.property.structure_name_en || '' )) == null ? '' : __t) +
'</a>\n                ';
 } else { ;
__p += '\n                -\n                ';
 } ;
__p += '\n            </td>\n            <td>\n                ';
 if (inquiry.search) { ;
__p += '\n                <p>\n                    <a\n                       href="#property/search/' +
((__t = ( inquiry.search.id )) == null ? '' : __t) +
'">' +
((__t = ( propertyGetTitleService(inquiry.search) )) == null ? '' : __t) +
'</a>\n                </p>\n                ';
 } else if (inquiry.meta.page_title && inquiry.meta.page_url) { ;
__p += '\n                <p>\n                    <a href="' +
((__t = ( inquiry.meta.page_url )) == null ? '' : __t) +
'" target="_new">' +
((__t = ( inquiry.meta.page_title )) == null ? '' : __t) +
'</a>\n                </p>\n                ';
 } ;
__p += '\n\n                <p class="text-emphasis my-0">\n                    ' +
((__t = ( Formatter.nl2br(_.escape(inquiry.message)) )) == null ? '' : __t) +
'\n                </p>\n            </td>\n            <td>\n                <abbr\n                      title="' +
((__t = ( Formatter.countryName(inquiry.country_code) )) == null ? '' : __t) +
'">' +
((__t = ( inquiry.country_code || '-' )) == null ? '' : __t) +
'</abbr>\n            </td>\n            <td>\n                <a href="#inquiries/' +
((__t = ( inquiry.id )) == null ? '' : __t) +
'" class="btn btn-secondary">View</a>\n            </td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>\n';
 } ;


}
return __p
};
