import * as Formatter from '@/js/app/formatter';
import { View } from 'backbone';
import moment from 'moment';
import template from '../templates/my_clients_activity.html';

export default class DashboardClientsActivityView extends View {
    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('DashboardClientsActivityView#render');

        const activities = this.processActivities(this.collection.toJSON());

        this.el.innerHTML = template({
            activities,
        });

        return this;
    }

    /** @todo Move into reusable function (same as people/views/edit-activities.js) */
    processActivities(activities) {
        return activities.map((activity) => {
            // Convert UTC timestamp to local time
            activity.log.timestamp = moment.utc(activity.log.timestamp).local();

            // Set activity icon and message
            switch (activity.log.type) {
                case 'created':
                    activity.icon = `<i class="fa fa-lg fa-user-plus" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageCreated(activity);
                    break;
                case 'login':
                    activity.icon = `<i class="fa fa-lg fa-sign-in" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageLogin(activity);
                    break;
                case 'inquiry':
                    activity.icon = `<i class="fa fa-lg fa-send-o" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageInquiry(activity);
                    break;
                case 'property_alert_create':
                    activity.icon = `<i class="fa fa-lg fa-plus-square-o" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessagePropertyAlertCreate(
                            activity,
                        );
                    break;
                case 'property_alert_delete':
                    activity.icon = `<i class="fa fa-lg fa-minus-square-o" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessagePropertyAlertDelete(
                            activity,
                        );
                    break;
                case 'property_alert_open':
                    activity.icon = `<i class="fa fa-lg fa-envelope-open-o" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessagePropertyAlertOpen(activity);
                    break;
                case 'property_alert_click':
                    activity.icon = `<i class="fa fa-lg fa-hand-pointer-o" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessagePropertyAlertClick(
                            activity,
                        );
                    break;
                case 'client_assigned':
                    activity.icon = `<i class="fa fa-lg fa-info-circle" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageClientAssigned(activity);
                    break;
                case 'client_unassigned':
                    activity.icon = `<i class="fa fa-lg fa-info-circle" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageClientUnassigned(activity);
                    break;
                case 'client_relinked':
                    activity.icon = `<i class="fa fa-link" aria-hidden="true"></i>`;
                    activity.message =
                        Formatter.getActivityMessageClientRelinked(activity);
                    break;
                default:
                    activity.icon = `<i class="fa fa-lg fa-info-circle" aria-hidden="true"></i>`;
                    activity.message = activity.type;
            }

            return activity;
        });
    }
}
