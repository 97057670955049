module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<tr data-id="' +
((__t = ( result.id )) == null ? '' : __t) +
'">\n  <td><a href="#people/edit/' +
((__t = ( result.id )) == null ? '' : __t) +
'">' +
((__t = ( result.id )) == null ? '' : __t) +
'</a></td>\n  <td><a href="#people/edit/' +
((__t = ( result.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getName(result) )) == null ? '' : __t) +
'</a></td>\n  <td>' +
((__t = ( result.division_name )) == null ? '' : __t) +
'</td>\n  <td>\n    ';
 if (result.types.length > 0) { ;
__p += '\n    ';
 result.types.forEach((type, i) => { ;
__p += '\n    ' +
((__t = ( type.capitalize() )) == null ? '' : __t) +
'' +
((__t = ( i !== result.types.length - 1 ? ',': '' )) == null ? '' : __t) +
'\n    ';
 }); ;
__p += '\n    ';
 } else { ;
__p += '\n    <span class="badge badge-info">0 Type(s)</span>\n    ';
 }; ;
__p += '\n  </td>\n  <td>\n    <span class="mr-2">' +
((__t = ( Formatter.format_date(result.assigned_date, 'YYYY/MM/DD') )) == null ? '' : __t) +
'</span>\n    ' +
((__t = ( Formatter.days_from_today(result.assigned_date) )) == null ? '' : __t) +
'\n  </td>\n  <td>\n    ';
 if (result.alert_count ) { ;
__p += '\n    ' +
((__t = ( result.alert_count )) == null ? '' : __t) +
'\n    ';
 } else { ;
__p += '\n    0\n    ';
 } ;
__p += '\n  </td>\n  <td>\n    ';
 if (result.inquiry_count ) { ;
__p += '\n    ' +
((__t = ( result.inquiry_count )) == null ? '' : __t) +
'\n    ';
 } else { ;
__p += '\n    0\n    ';
 } ;
__p += '\n  </td>\n  <td>\n    ';
 if (result.last_inquiry ) { ;
__p += '\n    <span class="mr-2">' +
((__t = ( Formatter.format_date(result.last_inquiry, 'YYYY/MM/DD') )) == null ? '' : __t) +
'</span>\n    ' +
((__t = ( Formatter.days_from_today(result.last_inquiry) )) == null ? '' : __t) +
'\n    ';
 } else { ;
__p += '\n    -\n    ';
 } ;
__p += '\n  </td>\n  <td>\n    ';
 if (result.last_page_view ) { ;
__p += '\n    <span class="mr-2">' +
((__t = ( Formatter.format_date(result.last_page_view, 'YYYY/MM/DD') )) == null ? '' : __t) +
'</span>\n    ' +
((__t = ( Formatter.days_from_today(result.last_page_view) )) == null ? '' : __t) +
'\n    ';
 } else { ;
__p += '\n    -\n    ';
 } ;
__p += '\n  </td>\n  <td>\n    <a class="btn btn-sm btn-danger" title="unassign" data-unlink><i class="fa fa-unlink"></i></a>\n  </td>\n</tr>\n</tbody>';

}
return __p
};
