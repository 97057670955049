module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


let amountCol = '';
switch (query.property_type) {
    case 'rent':
        amountCol = 'rent';
        break;

    case 'sales':
        amountCol = 'price';
        break;

    case 'monthly':
        amountCol = 'monthly_rate';
        break;

    case 'weekly':
        amountCol = 'weekly_rate';
        break;
}
;
__p += '\n';
 if (_.isArray(buildings) && buildings.length > 0) { ;
__p += '\n<div class="card-body">\n    ' + require("../../property/templates/search_results_actions.html").apply(null,arguments) + '\n\n    ' + require("../../generic/templates/pagination.html").apply(null,arguments) + '\n</div>\n\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>\n                <div class="custom-control custom-checkbox">\n                    <input id="property_selection_all" class="custom-control-input" type="checkbox" data-update="false" name="property_selection_all">\n                    <label for="property_selection_all" class="custom-control-label"></label>\n                </div>\n            </th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'id') )) == null ? '' : __t) +
'" data-sort-column="id">ID ' +
((__t = ( Formatter.sc(query, 'id') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'updated_at') )) == null ? '' : __t) +
'" data-sort-column="updated_at">Updated ' +
((__t = ( Formatter.sc(query, 'updated_at') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'structure_type') )) == null ? '' : __t) +
'" data-sort-column="structure_type" title="Structure Type">T ' +
((__t = ( Formatter.sc(query, 'structure_type') )) == null ? '' : __t) +
'</th>\n            <th></th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'neighborhood') )) == null ? '' : __t) +
'" data-sort-column="neighborhood">Area ' +
((__t = ( Formatter.sc(query, 'neighborhood') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'structure_name_en') )) == null ? '' : __t) +
'" data-sort-column="structure_name_en">Name ' +
((__t = ( Formatter.sc(query, 'structure_name_en') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'total_floors') )) == null ? '' : __t) +
'" data-sort-column="total_floors" title="Floor">Floors ' +
((__t = ( Formatter.sc(query, 'total_floors') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, amountCol) )) == null ? '' : __t) +
'" data-sort-column="' +
((__t = ( amountCol )) == null ? '' : __t) +
'">Amount ' +
((__t = ( Formatter.sc(query, amountCol) )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'structure_size') )) == null ? '' : __t) +
'" data-sort-column="structure_size">Structure Size ' +
((__t = ( Formatter.sc(query, 'structure_size') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'land_size') )) == null ? '' : __t) +
'" data-sort-column="land_size">Land Size ' +
((__t = ( Formatter.sc(query, 'land_size') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'completion_year') )) == null ? '' : __t) +
'" data-sort-column="completion_year">Built ' +
((__t = ( Formatter.sc(query, 'completion_year') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'pets') )) == null ? '' : __t) +
'" data-sort-column="pets">Pets ' +
((__t = ( Formatter.sc(query, 'pets') )) == null ? '' : __t) +
'</th>\n            <th>Agent</th>\n            <th>PM/Owner</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(query, 'availability_status') )) == null ? '' : __t) +
'" data-sort-column="availability_status">Status ' +
((__t = ( Formatter.sc(query, 'availability_status') )) == null ? '' : __t) +
'</th>\n            <th class="header"></th>\n        </tr>\n    </thead>\n    <tbody>\n    ';
 buildings.forEach((b) => { ;
__p += '\n        <tr data-id="' +
((__t = ( b.id )) == null ? '' : __t) +
'" class="' +
((__t = ( 1 === b.published ? '' : 'unpublished' )) == null ? '' : __t) +
'">\n            <td class="property_selection">\n                <div class="custom-control custom-checkbox">\n                    <input id="property-' +
((__t = ( b.id )) == null ? '' : __t) +
'" class="custom-control-input" type="checkbox" data-update="false" name="property_selection" value="' +
((__t = ( b.id )) == null ? '' : __t) +
'" ' +
((__t = ( selected.indexOf(b.id) !== -1 ? 'checked' : '' )) == null ? '' : __t) +
'>\n                    <label for="property-' +
((__t = ( b.id )) == null ? '' : __t) +
'" class="custom-control-label"></label>\n                </div>\n            </td>\n            <td>\n                <a href="' +
((__t = ( Formatter.building_edit_url(b) )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.propertyId(b, query) )) == null ? '' : __t) +
'</a>\n            </td>\n            <td class="timestamp">' +
((__t = ( Formatter.timestamp(b.updated_at, false, 'date') )) == null ? '' : __t) +
'</td>\n            <td>\n            ';
 if (b.structure_type) { ;
__p += '\n                <img src="/images/icons/16x16/' +
((__t = ( b.structure_type )) == null ? '' : __t) +
'.png" alt="' +
((__t = ( b.structure_type )) == null ? '' : __t) +
'" title="' +
((__t = ( b.structure_type )) == null ? '' : __t) +
'" width="16" height="16">\n            ';
 } ;
__p += '\n            </td>\n            <td>\n                ' +
((__t = ( Formatter.hasFloorplan(b) ? '<img src="/images/icons/16x16/blueprint-horizontal.png" alt="This property has a floorplan" title="This property has a floorplan" width="16" height="16">' : '' )) == null ? '' : __t) +
'\n                <br>\n                ' +
((__t = ( Formatter.hasImages(b) ? '<img src="/images/icons/16x16/pictures.png" alt="This property has photos" title="This property has photos" width="16" height="16">' : '' )) == null ? '' : __t) +
'\n            </td>\n            <td>' +
((__t = ( b.location.city )) == null ? '' : __t) +
'<br>' +
((__t = ( b.location.neighborhood )) == null ? '' : __t) +
'</td>\n            <td>\n                <a href="' +
((__t = ( Formatter.building_edit_url(b) )) == null ? '' : __t) +
'">' +
((__t = ( b.structure_name_en || '' )) == null ? '' : __t) +
'</a>\n            </td>\n            <td>' +
((__t = ( b.total_floors )) == null ? '' : __t) +
'</td>\n            <td>\n                ' +
((__t = ( Formatter.currency() + Formatter.price(b) )) == null ? '' : __t) +
'\n            </td>\n            <td>' +
((__t = ( Formatter.structureSize(b) )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( Formatter.landSize(b) )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( b.completion_year )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( TextHelper._(b.pets, 'pets') )) == null ? '' : __t) +
'</td>\n            <td>\n                ';

                const agents = _.filter([b.agent_company_1, b.agent_company_2, b.agent_company_3], function(a) {
                    return !!a;
                });
                let _c = 0;
                agents.forEach((a) => {
                    const tel = Formatter.getCompanyTel(a);
                    _c++;
                ;
__p += '\n                    <a href="#company/edit/' +
((__t = ( a.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getCompanyName(a) )) == null ? '' : __t) +
'</a>\n                    ' +
((__t = ( tel ? '(' + tel + ')' : '' )) == null ? '' : __t) +
'\n                    ' +
((__t = ( _c < agents.length ? ', ' : '' )) == null ? '' : __t) +
'\n                ';
 }); ;
__p += '\n            </td>\n            <td>\n                ';

                if (b.bm_company) {
                    const tel = Formatter.getCompanyTel(b.bm_company);
                    ;
__p += '\n                    <a href="#company/edit/' +
((__t = ( b.bm_company.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getCompanyName(b.bm_company) )) == null ? '' : __t) +
'</a>\n                    ' +
((__t = ( tel ? '(' + tel + ')' : '' )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n            </td>\n            <td>\n                ' +
((__t = ( TextHelper._(b.availability_status, 'availability_status') )) == null ? '' : __t) +
'\n                ' +
((__t = ( 'unavailable' !== b.availability_status ? '<br>' + Formatter.datestamp(b.available_on, 'human') : '' )) == null ? '' : __t) +
'\n                <br><small><i>' +
((__t = ( Formatter.timestamp(b.availability_status_updated_at, false, 'date') )) == null ? '' : __t) +
'</i></small>\n            </td>\n            <td>\n                <a href="' +
((__t = ( Formatter.building_print_url(b.location, b, Formatter.determineType(b), 'en') )) == null ? '' : __t) +
'" target="' +
((__t = ( _.uniqueId('print_zumen') )) == null ? '' : __t) +
'"><i class="fa fa-print fa-lg" aria-hidden="true"></i></a>\n            </td>\n        </tr>\n    ';
 }); ;
__p += '\n    </tbody>\n</table>\n\n<div class="card-body">\n    ' + require("../../generic/templates/pagination.html").apply(null,arguments) + '\n\n    ' + require("../../property/templates/search_results_actions.html").apply(null,arguments) + '\n</div>\n';
 } else { ;
__p += '\n    <div class="card-body text-center">\n        No buildings/land found\n    </div>\n';
 } ;


}
return __p
};
