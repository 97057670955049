import DivisionsCollection from '@/js/app/division/collections/divisions';
import Session from '@/js/app/session';
import * as TextHelper from '@/js/app/text-helper';
import UiDynamicSelectView from '@/js/app/ui/views/dynamic-select';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import { View } from 'backbone';
import moment from 'moment';
import {
    getMonthYearSelectInputs,
    setDateInputFields,
} from '../../date-ranges';
import ReportRevenueResultModel from '../models/result';
import template from '../templates/generate.html';
import ReportRevenueCompanySummaryView from './company-summary';
import ReportRevenueDivisionSummaryView from './division-summary';
import ReportRevenueResultsView from './results';

export default class ReportRevenueCriteriaView extends View {
    preinitialize(options) {
        this.tagName = 'section';
        this.id = 'reports';
        this.className = 'container';

        this.events = {
            'submit #frm-report-criteria': this.generate,
            'change #time-range': this.handleTimeRangeChange,
            'change #date-inputs>select': this.handleDateChange,
            'click [data-action="reset"]': this.handleClearCriteria,
        };

        // Create subviews
        this.subviews = {
            divisionSelect: new UiDynamicSelectView({
                id: 'field-division',
                collection: new DivisionsCollection(),
                mapper: function (model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
                attributes: {
                    name: 'division_id',
                },
                prependOptions: [{ label: 'All', value: '' }],
            }),
        };

        // Get current moment
        const now = moment();

        // If allowed to access company revenue
        if (Session.isAllowed('phnx:revenue/company')) {
            // Get year range from 2014 to next FY (_.range end is exclusive, hence + 2)
            this.years = _.range(2014, now.year() + 2);
        } else {
            // Get year range from previous FY to next FY (_.range end is exclusive, hence + 2)
            this.years = _.range(now.year() - 1, now.year() + 2);
        }

        // Override default options
        options = _.extend(
            {
                date_from: now.format('YYYYMM'),
                date_to: now.format('YYYYMM'),
                division_id: '',
            },
            options || {},
        );

        // Build field defaults
        this.timeRange = 'month-current';
        this.fromYear = options.date_from.slice(0, 4);
        this.fromMonth = options.date_from.slice(4, 6);
        this.toYear = options.date_to.slice(0, 4);
        this.toMonth = options.date_to.slice(4, 6);

        // If allowed to access company revenue, use provided division_id, otherwise force division_id from session
        this.divisionId = Session.isAllowed('phnx:revenue/company')
            ? options.division_id
            : Session.data.division_id;
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'generate');

        this.render();

        // Fetch divisions
        this.subviews.divisionSelect.collection.fetch({
            data: {
                company_id: 1,
                active: 1,
                order: 'order',
            },
        });
    }

    render() {
        console.debug('ReportRevenueCriteriaView#render');

        // Render template
        this.el.innerHTML = template({
            years: this.years,
            months: TextHelper.listMonths('MMM'),
        });

        this.$el.find('#cntr-division').html(this.subviews.divisionSelect.el);

        if (!Session.isAllowed('phnx:revenue/company')) {
            this.el.querySelector('#cntr-division').disabled = true;
        }

        this.timeRangeSelect = this.el.querySelector('#time-range');
        this.fromMonthSelect = this.el.querySelector(
            'select[name="from_month"]',
        );
        this.fromYearSelect = this.el.querySelector('select[name="from_year"]');
        this.toMonthSelect = this.el.querySelector('select[name="to_month"]');
        this.toYearSelect = this.el.querySelector('select[name="to_year"]');

        // Get report criteria from sessionStorage
        this.revenueReportCriteria = JSON.parse(
            sessionStorage.getItem('revenueReportCriteria'),
        );

        // If search conditions exists
        if (this.revenueReportCriteria) {
            const {
                time_range,
                from_year,
                from_month,
                to_year,
                to_month,
                division_id,
            } = this.revenueReportCriteria;

            populateForm(this.el.querySelector('#frm-report-criteria'), {
                time_range: time_range,
                from_year: from_year,
                from_month: from_month,
                to_year: to_year,
                to_month: to_month,
                division_id: division_id || this.divisionId,
            });

            this.subviews.divisionSelect.setSelected(division_id);
        } else {
            this.setDefaultFields();
        }

        return this;
    }

    setDefaultFields() {
        populateForm(this.el.querySelector('#frm-report-criteria'), {
            time_range: this.timeRange,
            from_year: this.fromYear,
            from_month: this.fromMonth,
            to_year: this.toYear,
            to_month: this.toMonth,
            division_id: this.divisionId,
        });
    }

    handleTimeRangeChange($e) {
        console.debug('ReportRevenueCriteriaView#handleTimeRangeChange');

        const rangeType = $e.target.value;
        const inputs = getMonthYearSelectInputs(this);

        setDateInputFields(rangeType, inputs);
    }

    handleDateChange() {
        console.debug('ReportRevenueCriteriaView#handleDateChange');

        const dateRangeInput = this.el.querySelector('#time-range');

        dateRangeInput.value = 'custom';
    }

    handleClearCriteria(e) {
        console.debug('ReportRevenueCriteriaView#handleClearCriteria');

        e.preventDefault();

        sessionStorage.removeItem('revenueReportCriteria');

        this.setDefaultFields();
    }

    generate($e) {
        console.debug('ReportRevenueCriteriaView#generate');

        $e.preventDefault();

        const criteria = {};

        const form = this.el.querySelector('form');

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        // Store search conditions to sessionStorage
        sessionStorage.setItem(
            'revenueReportCriteria',
            JSON.stringify(criteria),
        );

        const reportResults = new ReportRevenueResultModel();
        let revenueSummaryView;

        // If division_id not specified, and allowed to access revenue for company
        if (
            criteria.division_id === undefined &&
            Session.isAllowed('phnx:revenue/company')
        ) {
            // Create new company summary view
            revenueSummaryView = new ReportRevenueCompanySummaryView({
                model: reportResults,
            });
        } else {
            // Create new division summary view
            revenueSummaryView = new ReportRevenueDivisionSummaryView({
                model: reportResults,
            });
        }

        // Attach revenue summary view to DOM
        this.$el.find('#summary').html(revenueSummaryView.el);

        // Create new results view
        const revenueResultsView = new ReportRevenueResultsView({
            criteria: criteria,
            model: reportResults,
        });

        // Attach results view to DOM
        this.$el.find('#results').html(revenueResultsView.el);

        // Fetch results
        reportResults.fetch({
            data: criteria,
        });
    }
}
