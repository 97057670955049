import BloodhoundCompanyDataset from '@/js/app/bloodhound/datasources/company';
import config from '@/js/app/config';
import { getCompanyLink } from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import PeopleCollection from '@/js/app/people/collections/people';
import Session from '@/js/app/session';
import StaffCollection from '@/js/app/staff/collections/staff';
import * as TextHelper from '@/js/app/text-helper';
import typeaheadCompanyDatasource from '@/js/app/typeahead/datasets/company';
import { TypeaheadCompanyDefaults } from '@/js/app/typeahead/defaults';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import { View } from 'backbone';
import ClientCriteria from '../criterias/client';
import template from '../templates/search.html';
import ClientResultsView from './results';

export default class ClientSearchView extends View {
    preinitialize() {
        this.events = {
            'submit form': this.handleSubmit,
            'click [data-action="reset"]': this.handleReset,
        };

        // Create criteria model
        this.criteria = new ClientCriteria();

        // Create subviews
        this.subviews = {
            assignedStaff: new UiIndividualSelectorView({
                id: 'field-assigned_staff',
                collection: new StaffCollection(),
                attributes: {
                    name: 'assigned_staff_id',
                },
                prependOptions: [
                    {
                        label: 'Unassigned',
                        value: '0',
                    },
                ],
            }),
            companyTypeahead: new UiTypeaheadView({
                id: 'field-company',
                placeholder: 'Company',
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset),
                ],
                urlGenerator: getCompanyLink,
                mode: 'edit',
            }),
            clientsResults: new ClientResultsView({
                collection: new PeopleCollection(),
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        this.render();

        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'handleSubmit');
    }

    render() {
        console.debug('ClientSearchView#render');

        let isClient = 'any';
        switch (Session.data.division_id) {
            case 1:
                isClient = 'leasing';
                break;
            case 3:
                isClient = 'pm';
                break;
            case 2:
                isClient = 'sales';
                break;
            case 12:
                isClient = 'rh';
                break;
        }

        // Render template
        this.el.innerHTML = template({
            country: TextHelper._source.country,
            nationality: TextHelper._source.country,
            months: TextHelper.listMonths('MMMM'),
            isClient,
        });

        // Attach assignedStaff subview to DOM
        this.$el
            .find('#cntr-assigned_staff')
            .html(this.subviews.assignedStaff.el);

        // Fetch staff
        this.subviews.assignedStaff.collection.fetch({
            data: {
                order: 'last_name',
            },
        });

        // Attach companyTypeahead subview to DOM
        // this.$el.find('#cntr-company_id').html(this.subviews.companyTypeahead.render().el);
        this.subviews.companyTypeahead
            .setElement(this.el.querySelector('#cntr-company_id'))
            .render();

        // Attach clientsResults subview to DOM
        this.subviews.clientsResults.setElement(
            this.el.querySelector('#results'),
        );

        // Get date filter conditions from sessionStorage
        this.clientSearchCriteria = JSON.parse(
            sessionStorage.getItem('clientSearchCriteria'),
        );

        const promises = [];

        // If search conditions exists
        if (this.clientSearchCriteria) {
            const { company_id, assigned_staff_id } = this.clientSearchCriteria;

            populateForm(
                this.el.querySelector('#frm-clients-search'),
                this.clientSearchCriteria,
            );

            this.subviews.assignedStaff.setSelected(assigned_staff_id);

            if (company_id) {
                // Fetch information for given staff ID
                const fetchCompanies = OAuth2Client.fetchJSON(
                    config.api.url + 'companies/' + company_id,
                    {},
                ).then((response) => {
                    // Set datum
                    this.subviews.companyTypeahead.setDatum(response);
                });

                promises.push(fetchCompanies);
            }
        }

        Promise.all(promises).then(() => {
            // Trigger submit
            this.$el.find('#frm-clients-search').trigger('submit');
        });

        return this;
    }

    handleSubmit($e) {
        console.debug('ClientSearchView#handleSubmit');

        $e.preventDefault();

        // Get defaults, and merge with criteria from form
        const criteria = this.criteria.defaults();

        const form = $e.currentTarget;

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        // If company selected, add datum id
        if (this.subviews.companyTypeahead.datum) {
            criteria.company_id = this.subviews.companyTypeahead.datum.id;
        }

        sessionStorage.setItem(
            'clientSearchCriteria',
            JSON.stringify(criteria),
        );

        // Clear existing criteria, and set new criteria
        this.criteria.clear({ silent: true }).set(criteria);
    }

    handleReset(e) {
        e.preventDefault();

        this.el.querySelector('form').reset();

        this.subviews.companyTypeahead.clear();

        sessionStorage.removeItem('clientSearchCriteria');
    }
}
