import * as Formatter from '@/js/app/formatter';
import SimpleModalView from '@/js/app/generic/modal-simple';
import moment from 'moment';
import template from '../templates/modal_property_lists.html';

export default class ModalPropertyListsView extends SimpleModalView {
    preinitialize() {
        super.preinitialize({
            modalTitle: 'Property Lists',
            size: 'xl',
        });

        this.events = {
            ...this.events,
            'click .modal-body a': this.handleLinkClick,
        };
    }

    initialize() {
        super.initialize();

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', this.startSpinner);

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', this.stopSpinner);

        // When collection syncs; render modal body
        this.listenTo(this.collection, 'sync', this.renderModalBody);
    }

    renderModalBody() {
        console.debug('ModalPropertyListsView#renderModalBody');

        this.modalBody.innerHTML = template({
            property_lists: this.collection.toJSON(),
            Formatter,
            moment,
        });
    }

    handleLinkClick() {
        // Hide modal when link clicked
        this.hide();
    }
}
